import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProfilesLayoutState, ProfilesPageHeaderMenuButtons} from "./Profiles";
import {LayoutMode} from "../../../Config/Reducers/LayoutReducer";

const initialState: ProfilesLayoutState = {
    mode: LayoutMode.Desktop,
    contentMarginLeft: 400,
    contentPagePaddingTop: 150,
    header: {
        open: true,
        buttons: {
            newRecord: true
        },
        marginLeft: 20
    },
    subHeader: {
        open: false
    }
};

// Slice / Reducer
const ProfilesLayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {

        // Actions
        showProfilesHeaderMenu :(state) => {
            state.header.open = true;
            state.contentPagePaddingTop = 150;
        },
        hideProfilesHeaderMenu :(state) => {
            state.header.open = false;
            state.contentPagePaddingTop = 85;
        },
        showProfilesHeaderSubMenu :(state) => {
            state.subHeader.open = true;
            state.contentPagePaddingTop = 210;
        },
        hideProfilesHeaderSubMenu :(state) => {
            state.subHeader.open = false;
            state.contentPagePaddingTop = 150;
        },

        setProfilesHeaderMenuButtons :(state, action: PayloadAction<ProfilesPageHeaderMenuButtons>) => {
            Object.assign(
                state.header,
                {
                    buttons: {
                        newRecord: action.payload.newRecord
                    }
                }
            );
        },

        setProfilesHeaderSubMenuButtonsState :(state) => {
            Object.assign(state.subHeader, {open: true});
        },

        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    }
})

export const {
    reset,
    showProfilesHeaderMenu,
    showProfilesHeaderSubMenu,
    setProfilesHeaderMenuButtons,
    hideProfilesHeaderMenu,
    hideProfilesHeaderSubMenu
} = ProfilesLayoutSlice.actions;

// Reducer
export default ProfilesLayoutSlice.reducer;
