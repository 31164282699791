import {MenuItemStatus} from '../types/MenuItemStatus';

export const getIconColor = (status: MenuItemStatus): string => {
    const statusColors = {
        ONLINE: '#0FE812',
        OFFLINE: '#000000',
        Alarmado: '#FF0000',
        Configurado: 'white',
        'Não configurado': '#e3ca24',
        Crítico: '#000',
        'Não crítico': '#a4f657',
        'Não identificado': 'blue',
        'Não responde': '#f0a12b',
        Inibído: '#6e6767',
        Monitorando: '#e8e8e8',
        Normalizado: '#a4f657',
        Ignored: 'gray',

    }

    return statusColors[status];
};
