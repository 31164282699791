import {Box, Button, Paper, Typography} from "@mui/material";

export default function DashboardOverviewLayoutPage(){
    return(
        <Paper elevation={3}>
            <Box p={2} style={{height: 600}}>
                <Typography variant="h5" component="h1">Dashboard Overview Layout Page</Typography>
            </Box>
        </Paper>
    );
}
