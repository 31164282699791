import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";

const apiUrl = "api/graficElements";

const fetchGraficElement = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(apiUrl + "/search", {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error("Error fetching data: " + error.message));
    }
};

export const GraficElementReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchGraficElement(pagedSearchParams);
        }
    });
};

const saveGraficElement = async (data: any) => {
    try {
        if(data.id){
            return await axios.put(`${apiUrl}/${data.id}`, data).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data).then((res) => res.data);
        }
    } catch (error: unknown) {
        console.error("Error saving data:", error);
        if (error instanceof Error) {
            return Promise.reject(new Error("Error saving data: " + error.message));
        } else {
            return Promise.reject(new Error("Error saving data: Unknown error"));
        }
    }
};

export const SaveGraficElementReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveGraficElement(data);
        },
        onSuccess: () => {
            enqueueSnackbar("GraficElement salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar GraficElement: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteGraficElement = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`).then((res) => res.data);
    } catch (error: unknown) {
        console.error("Error deleting data:", error);
        if (error instanceof Error) {
            return Promise.reject(new Error("Error deleting data: " + error.message));
        } else {
            return Promise.reject(new Error("Error deleting data: Unknown error"));
        }
    }
};

export const DeleteGraficElementReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteGraficElement(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Elemento gráfico excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir elemento gráfico: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchGraficElementById = async (id: number) => {
    return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
};

export const FindGraficElementByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchGraficElementById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um GraficElement: ${error.message}`, { variant: "error" });
        }
    })
};