import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHardDrive} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";

const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDSettingsMenu() {
    const location = useLocation();
    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', height: '100%', overflowY: 'auto' }}>
            <nav aria-label="main mailbox folders">
                <List>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_ALARM_SEVERITY}>
                        <ListItem disablePadding selected={location.pathname.includes("alarm-severity")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Severidade do Alarme" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_BROKER}>
                        <ListItem disablePadding selected={location.pathname.includes("broker")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Broker" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_EQUIPMENT}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment") && !location.pathname.includes("class")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Equipmento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-class")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Classe de Equipamento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_EQUIPMENT_TYPE}>
                        <ListItem disablePadding selected={location.pathname.includes("equipment-type")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipo de Equipamento" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_GRAPH_ELEMENT}>
                        <ListItem disablePadding selected={location.pathname.includes("graph-element")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Mapa de Elementos Gráficos" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_GRAFIC_ELEMENT}>
                        <ListItem disablePadding selected={location.pathname.includes("grafic_element")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Elemento Gráfico" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_POWER_STATION_EXCEL}>
                        <ListItem disablePadding selected={location.pathname.includes("power-station-excel")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usina via Excel" />
                            </ListItemButton>
                        </ListItem>
                    </Link>


                    <Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.SETTINGS_TELE_OBJECT}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object") && !location.pathname.includes("Specification")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                          <Link style={style.linkItem} to={SystemRoutes.SETTINGS_TELE_OBJECT_SPECIFICATION_TYPE}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object-specification-type")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Especificação de Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_TELE_OBJECT_TYPE}>
                        <ListItem disablePadding selected={location.pathname.includes("tele-object-type")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipo de Teleobjeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES}>
                        <ListItem disablePadding selected={location.pathname.includes("mod-bus-data-types")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipos de Dado" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                          <Link style={style.linkItem} to={SystemRoutes.SETTINGS_MOD_BUS_OBJECTS_TYPES}>
                        <ListItem disablePadding selected={location.pathname.includes("mod-bus-objects-types")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Tipos de Objeto" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.SETTINGS_SKID}>
                        <ListItem disablePadding selected={location.pathname.includes("skid")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Skid" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_POWER_PLANT}>
                        <ListItem disablePadding selected={location.pathname.includes("power-plant")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usina" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_COUNTRY}>
                        <ListItem disablePadding selected={location.pathname.includes("country")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="País" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.SETTINGS_MACRO_REGION} >
                        <ListItem disablePadding selected={location.pathname.includes("macro-region")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Macro Região" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_REGION} >
                        <ListItem disablePadding selected={location.pathname.includes("region") && !location.pathname.includes("macro")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Região" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_PROFILE}>
                        <ListItem disablePadding selected={location.pathname.includes("profiles")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Perfis" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.SETTINGS_USERS}>
                        <ListItem disablePadding selected={location.pathname.includes("users")}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.SETTINGS_AUDIT}>
                        <ListItem disablePadding selected={location.pathname.includes("audit")}>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHardDrive} />
                                </ListItemIcon>
                                <ListItemText primary="Auditoria" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>

    );
}
