import {useNavigate} from 'react-router-dom';
import {RootState} from '../../Config/Store';
import {useAppDispatch, useAppSelector} from '../../Config/Hooks';
import {SystemRoutes} from '../../Utils/RouteUtils';
import {setEquipment} from '../../Modules/Dashboard/DashboardReducer';
import {useEffect, useState} from 'react';
import {DashboardNavigationMenu} from "../Components/Layout/menu/types/DashboardNavigationMenu";

export const useEquipmentWithoutSkidNavigation = (): (equipmentId: number) => { isSuccess: boolean } => {
    const [equipmentId, setEquipmentId] = useState<number | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { selectedEquipment } = useAppSelector((state: RootState) => state.dashboard);
    const { entities } = useAppSelector((state: RootState) => state.dashboardNavigationMenu);

    useEffect(() => {
        if (equipmentId) {
            navigate(SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS);
            setEquipmentId(null)
        }
    }, [selectedEquipment, equipmentId]);

    function navigateToEquipment(equipmentId: number): { isSuccess: boolean } {
        if (!equipmentId) {
            return { isSuccess: false };
        }

        if (equipmentExists(entities, equipmentId)) {
            dispatch(setEquipment(equipmentInfo(entities, equipmentId)));
            setEquipmentId(equipmentId);
            return { isSuccess: true };
        }
        return { isSuccess: false };
    }

    return navigateToEquipment;
}

const equipmentExists = (entities: DashboardNavigationMenu[], equipmentId: number): boolean => {
    for (const powerStation of entities) {
        for(const equipment of powerStation.equipmentList){
            if(equipmentId === equipment.id) return true;
        }
    }
    return false;
}

const equipmentInfo = (entities: DashboardNavigationMenu[], equipmentId: number) => {
    for (const powerStation of entities) {
        for(const equipment of powerStation.equipmentList){
            if(equipmentId === equipment.id){
                return equipment;
            }
        }
    }

    return null;
}