import React, {ReactElement, useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {GenerationEntity, getEntities} from "./GenerationYieldComparisonReducer.reducer";
import {format} from "date-fns";
import {truncateNumber} from "../../../Utils/NumberUtil";
import {
    AChartX,
    Period,
    XAxis,
    YAxis,
    Skeleton,
    PeriodSelectorGroupProps
} from "@atiautomacao/ati-ui-library";
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../Shared/Hooks/useInterval";

interface GenerationYieldComparisonProps {
    period?: Period;
    periodIntervalGroup? : PeriodSelectorGroupProps;
}
const GenerationYieldComparison = (props: GenerationYieldComparisonProps): ReactElement => {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const generation: Array<GenerationEntity> = useAppSelector((state: RootState) => state.generationYieldComparisonChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationYieldComparisonChart);
    const dispatch = useAppDispatch();


    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const callback = (args:any) => {
        let date= dateTime.date

        let result : any =   args[0].name + '<br />  ' + (date[args[0].dataIndex]? date[args[0].dataIndex] + '<br /> ' : '')
        for(const element of args) {
            result += '<br/>' + element.marker + element.seriesName + ': ' + element.data + ' '+ 'kWh/kWp' + '<br />'
        }
        return result;
    }

    const [period, setPeriod] = useState<Period>(props.period ? props.period : initialPeriod);
    const [dateTime, setDateTime] = useState<any>(); //dateTime option
    const [yAxis, setYAxis] = useState<YAxis>();
    const [xAxis, setXAxis] = useState<XAxis>();
    const [generatedYield, setGeneratedYield] = useState<Array<number>>(new Array<number>());
    const [expectedYield, setExpectedYield] = useState<Array<number>>(new Array<number>());

    useEffect(() => {
        if(props.period){
            setPeriod(props.period);
        }
    }, [props.period]);
    const max = () => {
        const maxYield = Array.isArray(generation) ? Math.max(...generation.map(item => item.generatedYield) ) : 0
        const maxExpectedYield = Array.isArray(generation) ? Math.max(...generation.map(item => item.expectedYield)) : 0
        return  maxYield > maxExpectedYield ? truncateNumber(maxYield) : truncateNumber(maxExpectedYield);
    }
    function ordination(newData: Array<GenerationEntity>){
        if(newData.length > 0){
            newData.sort(function compare (a, b){
                if(a.generatedYield > b.generatedYield){ return 1; }
                if(a.generatedYield < b.generatedYield){ return -1; }
                else{ return  0; }
            })
        }
        return newData;
    }

    function formatCaption(fromDateTime: Date, toDateTime: Date): string{

        let caption;

        if (period.groupBy.valueOf() === 'year') {
            caption = format(fromDateTime, 'yyyy') + ' à ' + format(toDateTime, 'yyyy')
        } else if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month") {
            caption = format(fromDateTime, 'dd/MM') + ' à ' + format(toDateTime, 'dd/MM')
        } else {
            caption = format(fromDateTime, 'HH:mm') + ' à ' + format(toDateTime, 'HH:mm')
        }

        return caption
    }

    function dateByPeriod(): any{

        let fromDateTime = new Date();
        let toDateTime = new Date();
        let captionData : {date: Array<string>}  = {date: []};

        if(period.fromDateTime) {
            fromDateTime = period.fromDateTime
        }
        if(period.toDateTime) {
            toDateTime = period.toDateTime
        }
        if(generation){
            generation.forEach(()=>{
                captionData.date.push(formatCaption(fromDateTime, toDateTime))
            })
        }
        setDateTime(captionData);
    }
    function findGeneratedYieldComparisonDataByPeriod(){
        let aux = [...generation]
        let receiveData =  ordination(aux);

        const maxInterval = max();
        setXAxis({
            max: maxInterval,
            interval: truncateNumber(maxInterval / 3),
            type: 'value',
            axisLabel:{
                show: true
            }
        });
        setYAxis(
            {
                type: 'category',
                data: Array.isArray(receiveData) ? receiveData.map(item => item.powerStationName) : [],
                axisLabel: {
                    align: 'right',
                    show: true
                },
                axisTick: { show: false },
                axisLine: { show: false },

            });
        setGeneratedYield(Array.isArray(receiveData) ? receiveData.map(item => item.generatedYield ? truncateNumber(item.generatedYield, 2) : 0) : []);
        setExpectedYield(Array.isArray(receiveData) ? receiveData.map(item => item.expectedYield ? truncateNumber(item.expectedYield,2) :0 ) : []);
    }
    useEffect(() => {
        dispatch(getEntities(period));
    },[period]);

    useInterval(() => {
        dispatch(getEntities(period));
    }, 60000) // 5 minutes

    useEffect(() => {
        dateByPeriod();
        if(Array.isArray(generation)) {
            findGeneratedYieldComparisonDataByPeriod();
        }
    },[generation]);

    const generationLength = generation === null || generation.length <= 0 ? 1 : generation.length;
// @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['#97d9e3', 'rgb(234, 162, 40)'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        toolbox: {
            feature: {
                dataView: { show: false, readOnly: false },
                magicType: { show: false, type: ['bar'] },
                restore: { show: false },
                saveAsImage: { show: false }
            }
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 25,
            top: 40,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ["Atual", "Esperada"]
        },
        grid: {
            containLabel: true,
            left: '3%',
            right: '4%',
            bottom: '3%'
        },

        dataZoom :[
            {
                type: 'slider',
                yAxisIndex: generationLength <= 5 ? 1 : 0 ,
                filterMode: 'filter',
                zoomLock: true,
                width: 10,
                right: 10,
                top: '10%',
                bottom: '10%',
                start: (100-(100*5)/generationLength) ,
                brushSelect: false,
                handleSize: '60%',
                showDetail: false,
                opacity: 1,
                brushStyle: false,
            },
            {
                type: 'inside',
                id: 'insideY',
                yAxisIndex: 0,
                zoomOnMouseWheel: false,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
                backgroundColor: 'transparent'
            }
        ] ,
        xAxis: xAxis,
        yAxis: yAxis,
        series: [
            {
                name: 'Atual',
                data: generatedYield,
                type: 'bar',
                unity: 'kWh/kWp',
            },
            {
                name: 'Esperada',
                data: expectedYield,
                type: 'bar',
                unity: 'kWh/kWp',
            }
        ],
    };
    option.tooltip.formatter = callback
    return(
        <>
            {
                loading ?
                    <Skeleton animation="wave" height={290} variant="rounded" width={'100%'} />
                    :
                    generation && generation.length > 0 && yAxis && xAxis ?
                        <AChartX
                            key={`card-overview-energy-comparison-${openSubMenu}`}
                            option={option}
                            height={289}
                            loading={false} />
                        :
                        <DataNotFound boxStyle={{height: 290, width: '100%'}}/>
            }
        </>
    );
}

export default GenerationYieldComparison;