import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {Period} from "@atiautomacao/ati-ui-library";
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../Config/Types"
import {format} from "date-fns";

export class GenerationEntity{
    name: string;
    yieldField: number;
    expectedYield: number;
    productivityGoal: number;

    constructor(name: string, yieldField: number, expectedYield: number, productivityGoal: number) {
        this.name = name;
        this.yieldField =  yieldField;
        this.expectedYield = expectedYield;
        this.productivityGoal = productivityGoal;
    }
}

const initialState: ChartState ={
    loading: false,
    errorMessage: null,
    entities: new Array<GenerationEntity>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/generation-energy/yield';

export const getEntities = createAsyncThunk(
    'generation-yield-total/fetch_entities',
    async (period: Period) => {
        return axios.get<GenerationEntity>( `${apiUrl}?startDateTime=${format(period.fromDateTime ? period.fromDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')}&endDateTime=${format(period.toDateTime ? period.toDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-yield-total/fetch_entity',
    async () => {
        return axios.get<GenerationEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slice / Reducer
const generationYieldChartReducer = createSlice({
    name: 'generationYieldChartReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {station:"0", yieldField: 0, expectedYield: 0, productivityGoal: 0 };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isPending(getEntity, getEntities), state => {
                state.loading = true;
                state.updateSuccess = false;
                state.errorMessage= null;
                state.entities= [];
            })
            .addMatcher(isFulfilled(getEntity, getEntities), (state, action) => {
                return{
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isRejected(getEntities, getEntity), (state: ChartState, action) => {
                state.loading = false;
                state.errorMessage = action.error.message;
            });

    },
});

export const { reset, clear } = generationYieldChartReducer.actions;
export const selectGeneration = (state: RootState) => state.generationYieldChart;

// Reducer
export default generationYieldChartReducer.reducer;