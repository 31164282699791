import Divider from "@mui/material/Divider";
import {Drawer, ListItemButton, ListItemText, Tooltip} from "@mui/material";
import * as React from "react";
import {MAIN_MENU_WIDTH} from "../../../../Config/MenuUtil";
import DrawerHeader from "./DrawerHeader";
import {Link, useLocation} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faFileLines, faGear, faHome} from "@fortawesome/free-solid-svg-icons";
import List from "@mui/material/List";
import {toggleMainMenu, toggleSubMenu} from "../../../../Config/Reducers/LayoutReducer";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {SystemRoutes} from "../../../../Utils/RouteUtils";
import config from "../../../../Config/Constants";
import {useEffect, useState} from "react";

const style = {
    logo: {
        marginLeft: 17,
        height: 40,
        width: 'auto'
    },
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
    listItem: {
        padding: 0,
        backgroundColor: "none",
    },
    listItemText: {
        textAlign: "center",
        color: "#747474",
        fontSize: 22
    },
    iconSize: {
        fontSize: 22
    }
};

export default function SGDMainMenu () {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [configValue, setConfigValue] = useState("");

    useEffect(() => {
        if(config.VERSION) {
            let configVersion = config.VERSION.split("-");
            if(configVersion.length > 1) {
                setConfigValue(configVersion[1]);
            } else {
                setConfigValue(config.VERSION);
            }
        }
    }, []);

    const toggle = () => {
        dispatch(toggleMainMenu());
        dispatch(toggleSubMenu());
    }

    if(isAuthenticated )return (
        <Drawer
            variant="persistent"
            sx={{
                // display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: MAIN_MENU_WIDTH },
            }}
            open={openMainMenu}
            onClose={toggle}
        >
            <DrawerHeader />
            <List>
                <Link style={style.linkItem} to="/dashboard/overview">
                    <ListItem style={style.listItem} selected={location.pathname.includes("dashboard")}>
                        <Tooltip title="Dashboard" placement="left" arrow>
                            <ListItemButton sx={style.listItemText}>
                                <ListItemText>
                                    <FontAwesomeIcon color={'#051F36'} icon={faHome} style={style.iconSize}/>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </Link>
                <Divider component="li" />
                <Link style={style.linkItem} to={SystemRoutes.ANALYSIS}>
                    <ListItem style={style.listItem} selected={location.pathname.includes("analysis")}>
                        <Tooltip title="Analysis" placement="left" arrow>
                            <ListItemButton sx={style.listItemText}>
                                <ListItemText>
                                    <FontAwesomeIcon color={'#051F36'} icon="chart-line" style={style.iconSize}/>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </Link>
                <Divider component="li" />
                <Link style={style.linkItem} to="/settings/alarm-severity" >
                    <ListItem style={style.listItem} selected={location.pathname.includes("settings")}>
                        <Tooltip title="Settings" placement="left" arrow>
                            <ListItemButton sx={style.listItemText}>
                                <ListItemText>
                                    <FontAwesomeIcon color={'#051F36'} icon={faGear} style={style.iconSize}/>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </Link>
                <Divider component="li" />
                <Link style={style.linkItem} to="/reports">
                    <ListItem style={style.listItem} selected={location.pathname.includes("reports")}>
                        <Tooltip title="Reports" placement="left" arrow>
                            <ListItemButton sx={style.listItemText}>
                                <ListItemText>
                                    <FontAwesomeIcon color={'#051F36'} icon={faFileLines} style={style.iconSize}/>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </Link>
                <Divider component="li" />
                {/*<Link style={style.linkItem} to="/help">*/}
                <Link aria-disabled={true} style={style.linkItem} to="">
                    <ListItem style={style.listItem} selected={location.pathname.includes("help")}>
                        <Tooltip title="Help" placement="left" arrow>
                            <ListItemButton disabled sx={style.listItemText}>
                                <ListItemText>
                                    <FontAwesomeIcon color={'#051F36'} icon={faCircleQuestion} style={style.iconSize}/>
                                </ListItemText>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                </Link>
            </List>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                padding: '5px 18px',
                bottom: 0,
                right: 0,
                left: 0,
                color: 'gray',
                fontSize: '14px',
            }}>
                {configValue}
            </div>
        </Drawer>
    );

    return null;
};
