import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../../Config/Types";
import {TeleObject} from "../../Skid/EquipmentDetailsSkid/EquipmentDetailsSkidReducer"

export interface SerializedTeleObjectType {
    id: number;
    telesinalList: TeleObject[];
    utrTemperature: TeleObject;
    utrName: string;
}

export class TeleObjectType {
    id: number;
    telesinalList: TeleObject[];
    utrTemperature: TeleObject;
    utrName: string;

    constructor(id:number, telesinalList: TeleObject[], utrTemperature: TeleObject, utrName: string) {
        this.id = id;
        this.telesinalList = telesinalList;
        this.utrTemperature = utrTemperature;
        this.utrName = utrName;
    }

    toSerializableObject(): SerializedTeleObjectType {
        return {
            id: this.id,
            telesinalList: this.telesinalList,
            utrTemperature: this.utrTemperature,
            utrName: this.utrName
        };
    }
}

interface UTRDetailState extends DefaultState {
    entity: SerializedTeleObjectType | null;
}


const initialState: UTRDetailState= {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<TeleObjectType>(),
    entity: null,
    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};
const apiUrl = 'api/equipment/utr/last';

export const getEntities = createAsyncThunk<any, any>(
    'utr_data/fetch_entity_list',
    async (powerStationId) => {
        return axios.get<TeleObjectType>(`${apiUrl}?powerStationId=${powerStationId}`);
    },
    { serializeError: serializeAxiosError }
);
export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'utr_data/fetch_entity',
    async (powerStationId) => {
        return axios.get<TeleObjectType>(`${apiUrl}?powerStationId=${powerStationId}`);
    },
    { serializeError: serializeAxiosError }
);

const UTRDataPlantReducer = createSlice({
    name: 'UTRDataPlantReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                id: 0,
                telesinalList: [],
                utrTemperature: {
                    id: 0,
                    name: "",
                    unit: "",
                    status: "",
                    value: 0,
                },
                utrName: ""
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isPending(getEntity), state => {
                state.loading = true;
                state.errorMessage = undefined;
            })
    },
})

export const { reset,clear } = UTRDataPlantReducer.actions;
export const selectUTRStationPlant = (state: RootState) => state.UTRDataPlant;
export default UTRDataPlantReducer.reducer;