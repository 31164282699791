import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";

const apiUrl = "api/equipment-classes";

const fetchEquipmentClass = async (pagedSearchParams: PagedSearchParams) => {
  try {
    return await axios
        .get(apiUrl, {
          params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
          timeout: 30000
        })
        .then((res) => res.data);
  } catch (error) {
    console.error("Error fetching echart data:", error);
    return Promise.reject(error);
  }
};

export const EquipmentClassReactQuery = (pagedSearchParams: PagedSearchParams) => {
  return useQuery({
    queryKey: ["data", pagedSearchParams],
    queryFn: () => {
      return fetchEquipmentClass(pagedSearchParams);
    }
  });
};

const fetchPrepareNewEquipmentClassForm = async () => {
    try {
        return await axios.get(apiUrl + "/prepare-form-new",{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const PrepareNewEquipmentClassFormReactQuery = () => {
    return useQuery({
        queryKey: ["dataNewForm"],
        queryFn: () => {
            return fetchPrepareNewEquipmentClassForm();
        }
    });
};

const fetchAllEquipmentClassNotPage = async () => {
    try {
        return await axios.get(apiUrl + "/find-all",{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching equipments data:", error);
        return Promise.reject(error);
    }
};

export const FindAllEquipmentClassNotPageReactQuery = () => {
    return useQuery({
        queryKey: ["find-all"],
        queryFn: () => {
            return fetchAllEquipmentClassNotPage();
        }
    });
};

const saveEquipmentClass = async (data: any) => {
    try {
        if(data.id){
            return await axios.put(`${apiUrl}/${data.id}`, data,{timeout:30000}).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const SaveEquipmentClassReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveEquipmentClass(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Classe de equipamento salva com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar classe de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteEquipmentClass = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const DeleteEquipmentClassReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteEquipmentClass(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Classe de equipamento excluída com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]).then(r => r);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir classe de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchEquipmentClassById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const FindEquipmentClassByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchEquipmentClassById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar uma classe de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};
