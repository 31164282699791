import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import axios from 'axios';

import {AlarmTotalizer} from './types/alarmTotalizer';
import {AlarmTotalizerState} from './types/alarmTotalizerState';
import {serializeAxiosError} from '../../../Config/Reducers/ReducerUtils';

const initialState: AlarmTotalizerState = {
    isLoading: false,
    errorMessage: null,
    totalizers: {
        active: 0,
        activeCritical: 0,
        notAcknowledged: 0,
        powerStationOff: 0,
        equipmentsOff: 0,
        equipmentsAlarmed: 0,
    },
};

const apiUrl = 'api/alarm/totalizers';
//TODO Improve performance of totalizers
export const getTotalizers = createAsyncThunk<any, void>(
'alarm/fetch_totalizers',
async () => {
    return axios.get<AlarmTotalizer>(`${apiUrl}`, {
        timeout: 0
    });
},
{ serializeError: serializeAxiosError },
);

// Slices
const alarmTotalizersReducer = createSlice({
    name: 'alarmTotalizersReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(isFulfilled(getTotalizers), (state, action) => {
            return {
                ...state,
                isLoading: false,
                totalizers: action.payload.data.data,
            }
        })
        .addMatcher(isPending(getTotalizers), state => {
            state.errorMessage = null;
            state.isLoading = true;
        })
    },
})

export const { reset } = alarmTotalizersReducer.actions;

// Reducer
export default alarmTotalizersReducer.reducer;