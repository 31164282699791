import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";

interface SynopticState {
    loading: boolean;
    errorMessage: string | null | undefined,
    entities: Array<any>,
    entity: any,
    updating: boolean,
    updateSuccess: boolean,
    periodSynoptic: any | null
}
export class SynopticData {
    id: number;
    powerStationName: string;
    etsStatus: string;
    activePower: number;
    generationRate: number;
    irradiance: number;
    expectedIrradiance: number;
    generatedEnergy: number;
    expectedInjectedEnergy: number;
    loading?: boolean;

    constructor(id: number, powerStationName: string, etsStatus: string, activePower: number, generationRate: number, irradiance: number, generatedEnergy: number, expectedIrradiance: number, expectedInjectedEnergy: number ) {
        this.id = id;
        this.powerStationName = powerStationName;
        this.etsStatus = etsStatus;
        this.activePower = activePower;
        this.generationRate = generationRate;
        this.irradiance = irradiance;
        this.generatedEnergy = generatedEnergy;
        this.expectedIrradiance = expectedIrradiance;
        this.expectedInjectedEnergy = expectedInjectedEnergy;
    }
}

const initialState: SynopticState = {
    loading: false,
    errorMessage: null,
    entities: new Array<SynopticData>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
    periodSynoptic: null
};

const apiUrl = 'api/power-station/synoptic';


// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk<any, any>(
    'synoptic data/fetch_entity_list',
    async({period, powerStationId}) => {
        return axios.get<Array<SynopticData>>(`${apiUrl}/${powerStationId}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'synoptic/fetch_entity',
    async ({period, powerStationId}) => {
        return axios.get<SynopticData>(`${apiUrl}/${powerStationId}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const synopticChartReducer = createSlice({
    name: 'synopticChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                powerStationName: "",
                expectedInjectedEnergy: 0,
                irradiance:0,
                expectedIrradiance: 0,
                generationRate: 0,
                generatedEnergy:0,
                activePower: 0,
                etsStatus: ""
            };
        },
        clearEntity: (state) => {
            state.entity = undefined;
        },
        setPeriodSynoptic: (state, action) => {
            state.loading = false;
            state.periodSynoptic = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addCase(getEntity.pending, (state) => {
                state.loading = true;
                state.updateSuccess = false;
                state.errorMessage= null;
                state.entities= [];
            })
            .addCase(getEntity.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = "Erro ao carregar os dados do gráfico sinóptico."
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities), state => {
                state.loading = true;
                state.updateSuccess = false;
                state.errorMessage= null;
                state.entities= [];
            })
    },
})

export const { reset, clear, clearEntity, setPeriodSynoptic } = synopticChartReducer.actions;
export const selectGenerationEnergyChart = (state: RootState) => state.synopticChart;

// Reducer
export default synopticChartReducer.reducer;