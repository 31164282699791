import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected,} from '@reduxjs/toolkit';
import axios from 'axios';

import {NodesElement} from '../../../../Shared/Types/element';

interface State {
    isLoading: boolean;
    errorMessage: string | null;
    entities: NodesElement | undefined | null;
}

const initialState: State = {
    isLoading: false,
    errorMessage: null,
    entities: undefined,
}

const apiUrl = 'api/nodes';

export const getEntities = createAsyncThunk<any, any>(
    'nodes/skid_view/fetch_entity_list',
    async ({skidId , powerPlantId}) => {
        return axios.get<NodesElement>(`${apiUrl}/skid?skidId=${skidId}&powerStationId=${powerPlantId}`, {
            timeout: 20000
        })
    },
);

const SkidNodesReducer = createSlice({
    name: 'SkidNodesReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities), (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                }
            })
            .addMatcher(isRejected(getEntities), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    entities: null,
                }
            })
    },
});

export const { reset } = SkidNodesReducer.actions;
export default SkidNodesReducer.reducer;
