import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'leaflet/dist/leaflet.css'
import App from './App';
import reportWebVitals from './reportWebVitals';

import {bindActionCreators} from "redux";
import {Provider as ReduxProvider} from "react-redux";
import {clearAuthentication} from "./Config/Reducers/AuthenticationReducer";
import {store} from "./Config/Store";
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from "redux-persist";
import {loadIcons} from './Config/IconLoader';
import {SnackbarProvider} from 'notistack';
import {createTheme, ThemeProvider, Zoom} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {setupInterceptorsTo} from "./Config/AxiosInterceptor";
import {lightThemeConfig} from "./Shared/Theme";
// import {  } from "@atiautomacao/ati-ui-library";
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupInterceptorsTo(() => actions.clearAuthentication('login.error.unauthorized'));
loadIcons();
let persistor = persistStore(store);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    preventDuplicate={true}
                    TransitionComponent={Zoom}
                >
                    <BrowserRouter>
                        <ThemeProvider theme = {createTheme(lightThemeConfig)} >
                            <App />
                        </ThemeProvider>
                    </BrowserRouter>
                </SnackbarProvider>
            </PersistGate>
        </ReduxProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
