import React, {useEffect, useState} from "react";
import {ACard} from "@atiautomacao/ati-ui-library";
import CardHeader from "@mui/material/CardHeader";
import {Button, Checkbox, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
    return [...a, ...not(a, b)];
}

interface GridProps {
    teleObjectsAvailable: string[];
    setLeftValues: (left: string[]) => void;
    setRightValues: (right: string[]) => void;
    leftValuesToEdit: string[];
    rightValuesToEdt: string[];
}

export default function GridLeftRightSelect({teleObjectsAvailable, setLeftValues, setRightValues, leftValuesToEdit, rightValuesToEdt}: Readonly<GridProps>) {
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<string[]>([]);
    const [right, setRight] = React.useState<string[]>([]);
    const [previousLength, setPreviousLength] = useState(0);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        if(rightValuesToEdt.length > 0 || leftValuesToEdit.length > 0) {
            setLeft(leftValuesToEdit);
            setRight(rightValuesToEdt);
        }
    }, [leftValuesToEdit, rightValuesToEdt]);

    useEffect(() => {
        if (teleObjectsAvailable.length < previousLength) {
            const updateLists = () => {
                const filteredLeft = intersection(teleObjectsAvailable, left);

                const filteredRight = intersection(teleObjectsAvailable, right);

                setLeft(filteredLeft);
                setRight(filteredRight);
            };

            updateLists();
        } else {
            const newObjects = teleObjectsAvailable.filter(
                (item) => !left.includes(item) && !right.includes(item)
            );
            setLeft([...left, ...newObjects]);
        }

        setPreviousLength(teleObjectsAvailable.length);

        if(teleObjectsAvailable.length === 0){
            setLeft([]);
            setRight([]);
        }
    }, [teleObjectsAvailable]);

    useEffect(() => {
        setLeftValues(left);
        setRightValues(right);
    }, [left, right]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly string[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: readonly string[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title: React.ReactNode, items: readonly string[]) => (
        <ACard cardStyle={{width: '100%'}}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${items.length} selecionados`}
            />
            <Divider />
            <List
                sx={{
                    width: 300,
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
            >
                {items.map((value: string) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItemButton
                            key={value}
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItemButton>
                    );
                })}
            </List>
        </ACard>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList('Esquerda', left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Direita', right)}</Grid>
        </Grid>
    )
}