import {Box, Paper, Typography} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";

export default function DashboardPowerPlantSkidEquipmentsComparePage(){
    const {selectedPowerPlant, selectedSkid, selectedEquipment} = useAppSelector((state) => state.dashboard);
    return(
        <Paper elevation={3}>
            <Box p={2} style={{height: 600}}>
                <Typography variant="h5" component="h1">Dashboard Power Plant Skid Equipments Compare Page</Typography>
                {selectedPowerPlant ?
                    <>
                        <br/>
                        <Typography>Power Plant Id: {selectedPowerPlant.id}</Typography>
                    </> : null}
                {selectedSkid ?
                    <>
                        <br/>
                        <Typography>Power Plant Skid Id: {selectedSkid.id}</Typography>
                    </> : null}
                {selectedEquipment ?
                    <>
                        <br/>
                        <Typography>Power Plant Skid Equipment Id: {selectedEquipment.id}</Typography>
                    </> : null}
            </Box>
        </Paper>
    );
}
