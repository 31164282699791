import {format, getYear} from "date-fns";

export const LocalDateTimeFormatISO = (date: any) => {
    return format(new Date(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
}

export const DateFormat = (date: any) => {
    return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
}

export const validateDateFormatISO = (date: string): string => {
    let dateParts = date.split(" ");
    let day = dateParts[0].split("/")[0];
    let month = dateParts[0].split("/")[1];
    let year = dateParts[0].split("/")[2];
    let hour = "";
    let minute = "";
    let second = "";
    if(dateParts.length > 1){
        hour = dateParts[1].split(":")[0];
        if (dateParts[1].includes(":")){
            minute = dateParts[1].split(":")[1];
            second = dateParts[1].split(":")[2];
        }
    }


    // If the date is complete, return it.
    if (day && day.length === 2 && month && month.length === 2 && year && year.length === 4 &&
        hour && hour.length === 2 && minute && minute.length === 2 && second && second.length === 2) {
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    }

    // If the date is incomplete, complete with default values.
    const monthNumber = parseInt(month, 10);
    const hourNumber = parseInt(hour, 10);
    const minuteNumber = parseInt(minute, 10);
    const secondNumber = parseInt(second, 10);

    // Complete the day.
    if (day.length === 1) {
        day = "0" + day;
    }

    // Complete the month.
    if (!monthNumber || monthNumber < 1 || monthNumber > 12) {
        month = "01";
    } else if(monthNumber < 10 && month.length < 2){
        month = "0" + month;
    }

    if (!year || year.length !== 3) {
        year = getYear(new Date()).toString();
    }

    // Complete the hour.
    if (!hourNumber || hourNumber < 0 || hourNumber > 23) {
        hour = "00";
    } else if (hourNumber < 10) {
        hour = "0" + hour;
    }
    // Complete the minute.
    if (!minuteNumber || minuteNumber < 0 || minuteNumber > 59) {
        minute = "00";
    } else if (minuteNumber < 10 && minute.length < 2) {
        minute = "0" + minute;
    }

    // Complete the second.
    if (!secondNumber || secondNumber < 0 || secondNumber > 59) {
        second = "00";
    } else if (secondNumber < 10 && second.length < 2) {
        second = "0" + second;
    }

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`
}