import {createTheme, Theme, ThemeOptions} from "@mui/material";
// Dark Theme
const theme: Theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            backgroundColor: '#202020',
                            color: '#fff',
                        }),
                }),
                contained:{backgroundColor: 'green'}
            },
        },
    },
} as ThemeOptions);

export default theme;
