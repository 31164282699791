import React, {FunctionComponent, useEffect, useState} from 'react';
import {addMinutes, format, isValid, parseISO, setHours, startOfDay} from 'date-fns';

import {useAppDispatch, useAppSelector} from '../../../../Config/Hooks';
import {RootState} from '../../../../Config/Store';
import {clear, getEntities, PowerPU} from './PowerPUForInvertersReducer'
import {isArray} from "lodash";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import {AChartX, Skeleton} from "@atiautomacao/ati-ui-library";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";

interface HeatmapSeries {
    name: string;
    data: (number | string)[];
}
// AXIS-X WITH 24H INTERVAL
let intervalDate: Array<string> = [];
const ChartHeatmap: FunctionComponent<{ skid: any, date: Date | null }> = ({skid, date}) => {

    const dispatch = useAppDispatch();
    const entitiesPU: Array<PowerPU> = useAppSelector((state: RootState) => state.powerPUForInvertersChart.entities);
    const { loading} = useAppSelector((state: RootState) => state.powerPUForInvertersChart);

    const [series, setSeries] = useState<Array<HeatmapSeries>>([]);

    useEffect(() => {
        dispatch(clear)
        dispatch(getEntities({
            skids: skid.id,
            date: date
        }));
    }, [skid, date])

    useInterval(() => {
        dispatch(getEntities({
            skids: skid.id,
            date: date
        }));
    }, 60000) // 5 minutes

    const ordinalAxes = () => {
        let matrix:any = []
        for(let line = 0; line < series.length; line ++) {
            for (let column = 0; column < series[line].data.length; column++) {
                matrix.push([column,line, series[line].data[column]]);
            }
        }
        return matrix;
    }

    const callback = (args:any) => {
        const {name, data, value} = args;
        let names = series.map(el => el.name);
        return  'Horário' + ': ' + '<strong> '  +  name + '</strong>' + '<br />' + args.marker + ' ' + names[value[1]] + ' - ' + 'Gerado: ' + '<strong> '  +  data[2] + '</strong>'
    }

    function differenceUpToTwentyHours(date: any) {
        const HOUR_TWENTY = 20;
        date = new Date(date);
        if (isValid(date)) {
            const dayAndHour = setHours(startOfDay(date), HOUR_TWENTY);
            const interval = 5 * 60 * 1000; // 5 Minutes in milliseconds
            const dates = [];
            while (date <= dayAndHour) {
                dates.push(new Date(date));
                date.setTime(date.getTime() + interval);
            }
            dates.map(value => intervalDate.push(format(new Date(value), 'HH:mm')))
        }
    }

    const adjustDataPoints = (dataPoints: any[]): any[] => {
        if (dataPoints.length === 0) {
            return dataPoints;
        }

        // Parseia o primeiro e último dateTime para objetos Date
        const firstDateTime = parseISO(dataPoints[0].dateTime);
        const lastDateTime = parseISO(dataPoints[dataPoints.length - 1].dateTime);
        const skidName = dataPoints[0].skidName
        const equipmentId = dataPoints[0].equipmentId
        const equipmentName = dataPoints[0].equipmentName

        // Horários limite
        const startTime = new Date(firstDateTime);
        startTime.setHours(4, 0, 0, 0); // 4:00
        const endTime = new Date(lastDateTime);
        endTime.setHours(20, 0, 0, 0); // 20:00

        let newData = [...dataPoints]
        while (firstDateTime > startTime) {
            const newDateTime = addMinutes(firstDateTime, -5);
            newData.unshift({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                skidName: skidName
            });
            firstDateTime.setTime(newDateTime.getTime());
        }

        while (lastDateTime < endTime) {
            const newDateTime = addMinutes(lastDateTime, 5);
            newData.push({
                dateTime: format(newDateTime, 'yyyy-MM-dd HH:mm:ss.S'),
                activePower: null,
                reactivePower: null,
                powerPerUnit: null,
                equipmentId: equipmentId,
                equipmentName: equipmentName,
                skidName: skidName
            });
            lastDateTime.setTime(newDateTime.getTime());
        }

        return newData;
    }

    useEffect(() => {

        const newData: Array<HeatmapSeries> = [];
        if (isArray(entitiesPU) && entitiesPU && entitiesPU.length > 0) {
            let datePeriod: any[];
            let newDate = adjustDataPoints(entitiesPU)
            datePeriod = Array.from(new Set(
                newDate.map(
                    item => item.dateTime
                )
            ));
            intervalDate = datePeriod.map(
                item => format(new Date(item), 'HH:mm')
            );

            // SORT BY NAME EQUIPMENT DESC
            const equipmentEntitySort = [...entitiesPU].sort((a, b) => {
                return b.equipmentName.localeCompare(a.equipmentName);
            });

            // IDENTIFY INVERTER DISTINCT BY IDs
            const uniqueEquipmentIds = Array.from(new Set(equipmentEntitySort.map(item => item.equipmentId)));
            // INSERT THE DATA BY INVERTER ID
            uniqueEquipmentIds.forEach(element => {
                let filter = equipmentEntitySort.filter(data => data.equipmentId === element);
                let equipmentData = adjustDataPoints(filter)
                newData.push(
                    {
                        name: filter[0].equipmentName,
                        data: equipmentData.map(element => element.powerPerUnit != null ? truncateNumber(element.powerPerUnit, 2) : "-"),
                    },
                )
            })
            setSeries(newData);
        }else {
            setSeries([])
        }

    }, [entitiesPU]);

    // @ts-ignore
    const option: AChartXProps['option'] = {
        tooltip: {
            trigger: 'item',
            //formatter: callback,
            textStyle: {
                fontSize: 16
            },
        },
        grid: {
            show: true,
            containLabel: true,
            height: '80%',
            top: '10%',
            left: '5%',
        },
        xAxis: {
            type: 'category',
            data: intervalDate,
            name: "Horário",
            min: '04:00',
            max: '20:00',
            nameLocation: 'middle',
            nameGap: 30,
            splitArea: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            data: series.map(el => el.name),
        },
        visualMap: {
            min: 0,
            max: 1.5,
            left: 'right',
            top: 'center',

        },
        animationDuration: 2000,
        series: [
            {
                type: "heatmap",
                id: 'heatmap',
                data: ordinalAxes(),
            }
        ],
    };
    option.tooltip.formatter = callback;

    return (
        <>
            {
                loading ?
                    <Skeleton animation="wave" height={289} variant="rounded" width={"100%"} />
                    :
                    series.length > 0 ?
                        <AChartX
                            option={option}
                            width={'100%'}
                            height={289}
                            loading={false}
                        />
                        :
                        <DataNotFound boxStyle={{height: 289, width: '100%'}}/>
            }
        </>

    )
}

export default ChartHeatmap;
