import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {ChartConfigType} from "../../../../Types/chartConfigType";


export interface AnalysisEntity {
    id: number,
    name: string,
    selected:boolean,
    analysisEquipmentsList: [
        {
            equipment: {
                "id": number,
                "name": string,
            }
        }
    ],
    chartConfigList: ChartConfigType[]
}

interface Response {
    message: string;
    data: Array<AnalysisEntity>
}

const apiUrl = 'api/analysis';

const fetch = async (): Promise<Response> =>{
    return await axios
        .get<Response>(`${apiUrl}/home`,{timeout:30000})
        .then((res) => {
            // Render the first element
            if(res.data.data.length > 0){
                res.data.data[0].selected=true
            }
            return res.data
        })
}

export const AnalysisHomeReactQuery = () => {
    return useQuery({
        queryKey: ['data'],
        queryFn: () => fetch()
    })
}

const deleteAnalysisById = async (analysisId: any): Promise<any> =>{
    try {
        return await axios
            .delete(`${apiUrl}/${analysisId}`,{timeout:30000})
            .then((res) => res.data)
    }catch (error) {
        console.error("Error delete analysis by id:", error);
        return Promise.reject(error);
    }
}

export const DeleteAnalysisByIdReactQuery = () => {
    return useMutation({
        mutationFn: (id) => {
            console.log("DELETANDO ANÁLISE...")
            return deleteAnalysisById(id)
        }
    })

}