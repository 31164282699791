import {Card, Grid} from "@mui/material";
import PerformanceAnalysisPage from "../PerformanceAnalysis/PerformanceAnalysisPage";
import {GenerationEnergy} from "../GenerationEnergyChart/GenerationEnergy";
import React from "react";
import YeldChartComparison from "../YieldChartComparison/YeldChartComparison";
import PRComparison from "../PRComparison/PRComparison";

export default function DashboardOverviewChartsPage(){

    return(
        <React.Fragment>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <PerformanceAnalysisPage />
                </Grid>
                <Grid item xs={12} lg={6} xl={4} >
                    <GenerationEnergy/>
                </Grid>
                <Grid item xs={12} lg={6} xl={4} >
                    <YeldChartComparison />
                </Grid>
                <Grid item xs={12} lg={6} xl={4} >
                    <PRComparison />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}