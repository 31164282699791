import {ADataGridColors, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import axios, {AxiosRequestConfig} from "axios";

export const checkGroupingType = (alarm: any): Array<ADataGridFilter> => {
    if (alarm.equipmentId !== null && alarm.teleObjectId == null) {
        console.log("Equipment off-air");
        return [
            {name: 'equipmentId', condition: 'equals', value: alarm.equipmentId?.toString(), sort: 'none'},
            {name: 'teleObjectId', condition: 'equals', value: "null", sort: 'none'},
            {name: 'severityId', condition: 'equals', value: alarm.severityId?.toString(), sort: 'none'},
        ];
    }else if (alarm.equipmentId == null) {
        console.log("Power station off-air");
        return [
            {name: 'equipmentId', condition: 'equals', value: "null", sort: 'none'},
            {name: 'powerStationId', condition: 'equals', value: alarm.powerStationId?.toString(), sort: 'none'},
            {name: 'severityId', condition: 'equals', value: alarm.severityId?.toString(), sort: 'none'},
        ];
    }else if ( alarm.teleObjectId !== null && alarm.description && alarm.description.includes('Valor')) {
        console.log("TeleMeasure")
        return [
            {name: 'teleObjectId', condition: 'equals', value: alarm.teleObjectId?.toString(), sort: 'none'},
            {name: 'equipmentId', condition: 'equals', value: alarm.equipmentId?.toString(), sort: 'none'},
            {name: 'powerStationId', condition: 'equals', value: alarm.powerStationId?.toString(), sort: 'none'},
            {name: 'severityId', condition: 'equals', value: alarm.severityId?.toString(), sort: 'none'},
            {name: 'description', condition: 'contains', value: 'Valor', sort: 'none'},
        ];
    } else{
        console.log("TeleObject");
        return [
            {name: 'teleObjectId', condition: 'equals', value: alarm.teleObjectId?.toString(), sort: 'none'},
            {name: 'equipmentId', condition: 'equals', value: alarm.equipmentId?.toString(), sort: 'none'},
            {name: 'powerStationId', condition: 'equals', value: alarm.powerStationId?.toString(), sort: 'none'},
            {name: 'severityId', condition: 'equals', value: alarm.severityId?.toString(), sort: 'none'},
        ];
    }
};

export async function alarmSearchGroup(params: PagedSearchParams) {
    const config: AxiosRequestConfig = {
        url: 'api/alarm/grouping/search',
        params: params.toURLSearchParams(),
        method: 'GET',
    };
    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                resolve(response.data.data.length > 1);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const isValidNumber = (data:any, number:number): boolean => {
    return typeof data === 'object' && data !== null && typeof data.severityId === 'number' && data.severityId === number
}

export const handleOnColor = (data: any, columnItem: any): ADataGridColors => {
    const severityColor = (data as { severityColor: string }).severityColor

    let dataGridColors: ADataGridColors = {
        color: '#000000',
        backgroundColor: '',
    }
    if(isValidNumber(data, 5)){
        return dataGridColors
    }
    if(data?.clearDate === "" || data?.clearDate === null) {
        dataGridColors = {
            color: isValidNumber(data, 6) ? '#FFFFFF' : '#000000',
            backgroundColor: '#' + severityColor
        }
        return dataGridColors
    }

    if(typeof columnItem.name === 'string' && columnItem.name.toLowerCase().includes('severityname')){
        dataGridColors = {
            color: isValidNumber(data, 6) ? '#FFFFFF' : '#000000',
            backgroundColor: '#' + severityColor
        }
        return dataGridColors
    }

    return dataGridColors
}