import {Action, configureStore, PreloadedState, ThunkAction} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from "./Reducers/RootReducer";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};
let persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware(
//         {
//             serializableCheck: {
//                 ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//                 // Ignore these field paths in all actions
//                 ignoredActionPaths: ['payload.config', 'payload.request', 'error', 'meta.arg'],
//             }
//         }),
//         // .concat(logger),
//     devTools: process.env.NODE_ENV !== 'production',
//     }
// );

export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(
            {
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ['payload.config', 'payload.request', 'error', 'meta.arg'],
                }
            }),
        // .concat(logger),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    })
}

export const store : AppStore = setupStore();

export type RootState = ReturnType<typeof persistedReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,RootState,unknown,Action<string>>;
