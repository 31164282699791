import {Navigate, useLocation} from 'react-router-dom';
import {useSnackbar} from "notistack";
import {hasPermission} from "./AuthenticationUtil";
import {useAppSelector} from "../../Config/Hooks";

const ProtectedRoute = ({
                            hasAnyAuthorities,
                            redirectPath = '/',
                            children,
                        }) => {
    const location = useLocation();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const account = useAppSelector(state => state.authentication.account);
    const isAuthorized = hasPermission(account?.authoritySet, hasAnyAuthorities);
    const { enqueueSnackbar } = useSnackbar();
    if (isAuthorized) {
        return children;
    } else if(isAuthenticated && location.pathname !== '/dashboard') {
        enqueueSnackbar("You are not authorized to access this page", {
            variant: 'warning',
        });
        return null;
    } else {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedRoute;
