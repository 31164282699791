import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from "@mui/material";

const ConfirmDialog = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClick = () => {
        props.handleConfirm();
    };
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">
                {props.title ? props.title : 'Confirm'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.description ? props.description : 'Are you sure ?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleClick} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;
