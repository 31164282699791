import React, {useCallback, useEffect, useState} from "react"
import {AppBar, Box, Button, CardContent, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CancelOutlined} from "@mui/icons-material";
import { clearProfilesMessages, createEntity, updateEntity} from './ProfilesReducer';
import {useSnackbar} from "notistack";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {ACard, ADataGrid, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import Toolbar from "@mui/material/Toolbar";
import {searchEntitiesData} from "./AuthorityReducer";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {columnsData} from "./columnsData";

export interface RowAuthority {
    id: number;
    domain: string;
    name: string;
    displayName: string;
    selected?: boolean;
}

const ProfilesSaveSettingsPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectedId = location.state?.id as string;
    const navigate = useNavigate();
    const params = useParams();
    const [isNew] = useState(params.id === undefined);
    const [entity] = useState<{id: number, name: string, authorityGroupItemDTOList: any[]}>({id: 0, name: "", authorityGroupItemDTOList: []});
    const [inputName, setInputName] = useState<string>("");
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const authorityEntities = useAppSelector((state : RootState) => state.authority.entities);
    const totalOfRecords = useAppSelector((state : RootState) => state.profile.totalOfRecords);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(columnsData), 0, 100));
    const entities = useAppSelector((state : RootState) => state.profile.entities);
    console.log("entities: ", entities)

    const [rows, setRows] = useState<Array<RowAuthority>>([]);

    const updateRows = () => {
        if(selectedId) {
            const selectedEntity = entities.find((profile) => profile.id === selectedId);

            if (selectedEntity) {
                setInputName(selectedEntity.name);
                let newRows = authorityEntities.map(authority => {
                    return {
                        id: authority.id,
                        domain: authority.domain,
                        name: authority.name,
                        displayName: authority.displayName,
                        selected: selectedEntity.authorityGroupItemDTOList.some((item: { authority: { id: number; } }) => item.authority.id === authority.id)
                    }
                });
                setRows(newRows);
            }
        } else {
            // This is a new profile, so none of the authorityEntities are selected
            let newRows = authorityEntities.map(et => {
                return {
                    id: et.id,
                    domain: et.domain,
                    name: et.name,
                    displayName: et.displayName,
                    selected: false
                }
            });
            setRows(newRows);
        }
    }

    useEffect(() => {
        updateRows();
    }, [selectedId, entities, authorityEntities]);



    const isSaving = useAppSelector((state : RootState) => state.profile.saving);
    const errorMessage = useAppSelector((state : RootState) => state.profile.errorMessage);


    const saveEntity = () => {
        if (selectedId) { // Check if selectedId is defined
            dispatch(updateEntity(
                {
                    id: Number(selectedId),
                    name: inputName,
                    authorityGroupItemDTOList: selectedRows.map(authority => ({
                        id: null,
                        authorityGroup: null,
                        authority: {
                            id: authority.id,
                            name: authority.name,
                            displayName: authority.displayName,
                            domain: authority.domain
                        }
                    }))
                }
            )).then(success => {
                return navigate("/settings/profiles");
            }).catch(error => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
                dispatch(clearProfilesMessages());
            });
        } else { // If selectedId is not defined, create a new entity
            dispatch(createEntity(
                {
                    id: null,
                    name: inputName,
                    authorityGroupItemDTOList: selectedRows.map(authority => ({
                        id: null,
                        authorityGroup: null,
                        authority: {
                            id: authority.id,
                            name: authority.name,
                            displayName: authority.displayName,
                            domain: authority.domain
                        }
                    }))
                }
            )).then(success => {
                return navigate("/settings/profiles");
            }).catch(error => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
                dispatch(clearProfilesMessages());
            });
        }
    };


    useEffect(() => {
        if (!isSaving) {
            dispatch(searchEntitiesData(pagedSearchParams));
        }
    }, [isSaving, dispatch, pagedSearchParams]);

    const handleBack = () => {
        dispatch(clearProfilesMessages());
        return navigate("/settings/profiles");
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams :PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    },[]);

    const handleOnRowSelect = useCallback((rows : any) => {
        console.log("rows", rows);
        setSelectedRows(rows.map((row: { id: number; domain: string; name: string; displayName: string; }) => ({
            id: row.id,
            domain: row.domain,
            name: row.name,
            displayName: row.displayName
        })));
    },[]);

    return (
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <Button
                        variant="contained"
                        size={"small"}
                        color={"primary"}
                        endIcon={<SaveIcon/>}
                        style={{marginLeft: '22%'}}
                        onClick={saveEntity}
                    >
                        Salvar
                    </Button>
                    <Button
                        variant="outlined"
                        size={"small"}
                        color={"primary"}
                        endIcon={<CancelOutlined />}
                        style={{marginLeft: '1%'}}
                        onClick={handleBack}
                    >
                        Cancelar
                    </Button>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>
                <ACard>
                    <CardContent>
                        <React.Fragment>
                            <TextField
                                label="PERFIL"
                                variant="outlined"
                                fullWidth
                                size="small"
                                margin="normal"
                                value={inputName}
                                onChange={(e) => setInputName(e.target.value)}
                                placeholder={isNew ? "Insira o nome do perfil" : ""}
                            />
                            <ADataGrid
                                columns={columnsData}
                                rows={rows}
                                loading={false}
                                totalOfRecords={totalOfRecords}
                                page={pagedSearchParams.page}
                                rowsPerPage={pagedSearchParams.size}
                                onFiltersChange={handleOnFiltersChange}
                                onRowSelect={handleOnRowSelect}
                            />
                        </React.Fragment>
                    </CardContent>
                </ACard>
            </Box>
        </>
    );

}

export default ProfilesSaveSettingsPage;
