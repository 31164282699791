import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";

interface DataNotFoundProps {
    boxStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
}
export default function DataNotFound(props: DataNotFoundProps){
    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: "inherit",
            height: 'inherit',
            ...props.boxStyle
        }}>
            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: '#929fb3', fontSize: 25, ...props.iconStyle}}/>
            <span style={{color: '#929fb3', fontWeight: 200, fontSize: 14, ...props.titleStyle}}>Dados não encontrados!</span>
        </Box>
    )
}