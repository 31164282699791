import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, Paper, Stack} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import {AnalysisEntity, AnalysisHomeReactQuery, DeleteAnalysisByIdReactQuery} from "./AnalysisHomeReactQuery";
import DashboardMenuSkeleton from "../components/DashboardMenuSkeleton/DashboardMenuSkeleton";
import {QueryClient, QueryClientProvider} from 'react-query';
import {useLocation, useNavigate} from "react-router-dom";
import {SystemRoutes} from "../../../../../Utils/RouteUtils";
import {isArray} from 'lodash';
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const AnalysisComponent = () => {

    const navigate = useNavigate();
    const {state}= useLocation();

    const [analysisList, setAnalysisList] = useState<Array<AnalysisEntity>>([]);
    const {data, isSuccess, isLoading, isFetching, refetch} = AnalysisHomeReactQuery();
    const mutation = DeleteAnalysisByIdReactQuery();

    const handleListItemClick = (item:AnalysisEntity) => {
        if(item?.id != undefined) {
            let chartConfigIdList: number[] = [];
            item.chartConfigList.map(element => {
                if(element?.id){
                    chartConfigIdList.push(element.id);
                }
            })
            navigate(`${SystemRoutes.ANALYSIS}/${item.id}/${chartConfigIdList}`);

            const updatedAnalysisList = analysisList.map((analysis) => {
                if (analysis === item) {
                    return { ...analysis, selected: !analysis.selected };
                } else {
                    return { ...analysis, selected: false };
                }
            });
            setAnalysisList(updatedAnalysisList);
        }
    }

    useEffect(() => {
        if(isSuccess){
            setAnalysisList(data?.data)
        }
    }, [data?.data]);

    useEffect(() => {

        if(state?.reload){
            mutation.mutate(state?.reload)
        }
    }, [state]);


    useEffect(() => {
        if (mutation.isSuccess) {
            refetch().then(r => r.data);
        }
    }, [mutation.isSuccess]);


    // @ts-ignore
    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                <nav aria-label="main mailbox folders">
                    <Stack spacing={2}>
                        <InputLabel id="analysis-label">Análises</InputLabel>
                        <Divider/>
                        {isFetching || isLoading  || !isArray(analysisList) ? (
                            <DashboardMenuSkeleton/>
                        ) : (
                            <List
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 800,
                                    '& ul': {padding: 0},
                                }}
                                subheader={<li/>}
                            >
                                {
                                    isArray(analysisList) && analysisList.length > 0 && analysisList.map((analysis) => (
                                        <>
                                            {analysis.selected && handleListItemClick(analysis)}
                                            <ListItem disablePadding style={{borderRadius: 10}} key={analysis.id}>
                                                <ListItemButton
                                                    selected={analysis.selected}
                                                    onClick={(event) => handleListItemClick(analysis)}
                                                    style={{borderRadius: 10}}
                                                >
                                                    <ListItemIcon>
                                                        <FontAwesomeIcon icon={faChartSimple} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={analysis.name}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </>

                                    ))
                                }
                            </List>
                        )}
                    </Stack>
                </nav>
            </Box>
    );
}

export default function SGDAnalysisMenu() {
    const queryClient = new QueryClient();
    return(
        <QueryClientProvider client={queryClient}>
            <AnalysisComponent/>
        </QueryClientProvider>
    )
}