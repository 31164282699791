import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import {AlarmTotalizerState} from "../../../../Shared/Components/AlarmTotalizers/types/alarmTotalizerState";
import {AlarmTotalizer} from "../../../../Shared/Components/AlarmTotalizers/types/alarmTotalizer";

const initialState: AlarmTotalizerState = {
    isLoading: false,
    errorMessage: null,
    totalizers: {
        active: 0,
        activeCritical: 0,
        notAcknowledged: 0,
        powerStationOff: 0,
        equipmentsOff: 0,
        equipmentsAlarmed: 0,
    },
};

const apiUrl = 'api/alarm/totalizers';


export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'power_plant/totalizer_alarms-by-powerStationId/fetch_entity',
    async ({powerStationId}) => {
        return axios.get<AlarmTotalizer>(`${apiUrl}/${powerStationId}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const TotalizerPowerStationAlarmReducer = createSlice({
    name: 'TotalizerAlarmsReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalizers = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntity), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    totalizers: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntity), state => {
                state.errorMessage = null;
                state.isLoading = true;
            })
    },
})

export const { reset } = TotalizerPowerStationAlarmReducer.actions;
export const selectTotalizerAlarms = (state: RootState) => state.totalizerPowerPlantAlarms;

// Reducer
export default TotalizerPowerStationAlarmReducer.reducer;