// Light Theme
// Refs: https://mui.com/material-ui/customization/theming/
// Refs: https://zenoo.github.io/mui-theme-creator/
// Refs: https://m2.material.io/inline-tools/color/
import { createTheme, Theme, ThemeOptions } from '@mui/material';

import themeDefaults from './ThemeDefaults';

export const lightThemeConfig: ThemeOptions = {
  typography: {
    // fontSize: 40
  },
  // #043C9C Smalt
  // #5A7FBB Steel Blue
  // #7C9CCC Polo Blue
  // #CDD8EB Periwinkle Gray
  palette: {
    mode: 'light',
    primary: {
      main: '#2959EB',
    },
    secondary: {
      main: '#6687FF',
    },
    // background: '#051F36'
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        // disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },

    MuiButton: {
      styleOverrides: {
        // Name of the slot
        // root: {
        //     // Some CSS
        //     borderRadius: '30px',
        //     backgroundColor: 'red',
        //     fontSize: '1rem',
        // },
        root: ({ ownerState }) => ({
          // DEFAULT COLORS CONFIGURATION
          // backgroundColor: '#ffffff',
          // fontSize: '1rem',
          // color: 'red',
          // textTransform: 'uppercase',
          borderRadius: themeDefaults.borderRadius,
          // marginLeft: 5,
          // marginRight: 5,
          '&:hover': {
            // backgroundColor: '#86a305',
            // borderColor: '#0062cc',
            // boxShadow: 'none',
            // color: '#ffffff'
          },
          '&:active': {
            // boxShadow: 'none',
            // backgroundColor: 'blue',
            // borderColor: '#005cbf',
          },
          '&:focus': {
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            // backgroundColor: 'green',
          },

          // APPLY CSS ACCORDING TO COMPONENTE PROPS AND STATES
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' &&
            {
              // backgroundColor: 'rgba(220,231,250,0.48)',
              // color: 'black',
            }),
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // backgroundColor: 'red',
          '&:hover': {
            // backgroundColor: '#88a6cd',
            // borderColor: '#0062cc',
            // boxShadow: 'none',
            // color: '#ffffff'
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // borderRadius: '0px',
          backgroundColor: '#051F36',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: themeDefaults.borderRadius,
          // backgroundColor: 'red',
          // fontSize: '1rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: themeDefaults.borderRadius,
          backgroundColor: '#fff',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          // padding: 0,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          // backgroundColor: "#d2ff00",
          borderRadius: themeDefaults.borderRadius,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: themeDefaults.borderRadius,
        },
      },
    },
    // MuiTableHead: {
    //     styleOverrides: {
    //         root: {
    //             // borderRadius: 7,
    //             // backgroundColor: 'red',
    //             // boxShadow: '1px 2px 9px #F4AAB9',
    //         },
    //     },
    // },
    // MuiTableRow: {
    //     styleOverrides: {
    //         root: {
    //             // borderRadius: '20px',
    //             // padding: 5
    //         },
    //     },
    // },
    // MuiTableCell: {
    //     styleOverrides: {
    //         root: {
    //             // borderRadius: '20px',
    //             // padding: 15
    //         },
    //     },
    // }
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          // height: 10
        },
      },
    },
  },
};
export const LightTheme: Theme = createTheme(lightThemeConfig);
