import {Box, Tooltip} from "@mui/material";
import {ACard, ADataGrid, ADataGridColumn, ADataGridFilter, PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import React, {useCallback, useEffect, useState} from "react";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {useNavigate} from "react-router-dom";
import {DeleteRegionReactQuery, RegionReactQuery} from "./RegionReactQuery";
import {QueryClient, QueryClientProvider} from "react-query";
import {HeaderMenu} from "../HeaderMenu";
import {RegionProps} from "../../../Shared/Types/Region";

const initColumns = [
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'name', condition: 'contains', value: '', sort: 'asc'}

    },
    {
        name: 'macroRegionName',
        label: 'Macro Região',
        align: 'left',
        visibility: true,
        minWidth: 100,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {name: 'macroRegionName', condition: 'contains', value: '', sort: ''}

    },
] as ADataGridColumn [];

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowRegion {
    id: number;
    name: string;
    macroRegionName: string;
    actionsConfig?: Array<ActionConfig>
}

export function RegionSettingsPage(){
    const navigate = useNavigate();
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);

    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50));
    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const mutation = DeleteRegionReactQuery();

    const { data, isSuccess } = RegionReactQuery(pagedSearchParams);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [rows, setRows] = useState<Array<RowRegion>>([]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(isSuccess){
            setTotalOfRecords(data?.totalOfRecords);
            if(data?.data){
                let rowsRegions = data.data.map((entity: RegionProps) => {
                    return {
                        id: entity.id,
                        name: entity.name,
                        latitude: entity.latitude,
                        longitude: entity.longitude,
                        macroRegionName: entity.macroRegion.name,
                        actionsConfig: [
                            {actionName: "edit", disabled: false},
                            {actionName: "delete", disabled: false},
                        ]
                    }
                });
                setRows(rowsRegions);
            }
        }
    }, [data]);

    const handleEditRegion = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_REGION_FORM, { state: { id: data.id } });
    },[navigate]);

    const handleDeleteRegion = useCallback((data: any) => {
        setSelectedToDelete(data.id);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        mutation.mutate({ id: selectedToDelete });
        setSelectedToDelete(null);
        handleDialogToggle();
        setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
    }

    useEffect(() => {
        if(mutation.isSuccess) {
            setPagedSearchParams(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 50))
        }

    }, [mutation.isSuccess]);

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    return(
       <>
           <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_REGION_FORM}/>
           <Box style={{paddingTop: 64}}>
               <ACard>
                   <ADataGrid
                       hideFilters={false}
                       showFilterOptions={false}
                       hideSelection={false}
                       columns={initColumns}
                       rows={rows}
                       page={pagedSearchParams.page}
                       rowsPerPage={pagedSearchParams.size}
                       loading={false}
                       totalOfRecords={totalOfRecords}
                       onFiltersChange={handleOnFiltersChange}
                       actions={
                           <>
                               <IconButton name="edit" size={"small"} aria-label="Edit" onClick={(value) => handleEditRegion(value)}>
                                   <Tooltip title="Editar">
                                       <Edit/>
                                   </Tooltip>
                               </IconButton>
                               <IconButton name="delete" size={"small"} aria-label="Delete" onClick={(value) => handleDeleteRegion(value)}>
                                   <Tooltip title="Excluir">
                                       <Delete />
                                   </Tooltip>
                               </IconButton>
                           </>
                       }
                   />
               </ACard>
           </Box>
           <ConfirmDialog
               title={'Deletar'}
               description={'Deseja deletar essa região?'}
               open={openDialog}
               handleConfirm={handleDialogConfirm}
               handleClose={handleDialogClose}
           />
       </>
    );

}

export function RegionHomePage() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <RegionSettingsPage />
        </QueryClientProvider>
    )
}
