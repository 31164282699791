import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../Config/Types";
import {Period} from "@atiautomacao/ati-ui-library";
import {format} from "date-fns";

export class GenerationEntity {

    powerStationName: string;
    value: number;
    group: string;


    constructor(powerStationName: string, value: number, group: string ) {
        this.powerStationName = powerStationName;
        this.value = value;
        this.group = group;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<GenerationEntity>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/generation-energy/performance-ratio/total/';


// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk(
    'generation-performace-ratio/entity_list',
    async (period: Period) => {
        return axios.get<GenerationEntity>(`${apiUrl}?startDateTime=${format(period.fromDateTime ? period.fromDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')}&endDateTime=${format(period.toDateTime ? period.toDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-performace-ratio/entity',
    async () => {
        return axios.get<GenerationEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const generationPRChartReducer = createSlice({
    name: 'generationPRChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {powerStationName:'', value:0, group:''};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), (state: ChartState, action) => {
                state.loading = false;
                state.errorMessage = action.error.message;
            })
    },
})

export const { reset, clear } = generationPRChartReducer.actions;
export const selectGenerationPRChart = (state: RootState) => state.generationPRChart;
// Reducer
export default generationPRChartReducer.reducer;