import {Grid} from "@mui/material";
import TotalizerAlarms from "../TotalizerAlarms/TotalizerAlarms";
import AlarmPage from "../Alarms/AlarmPage";

export default function DashboardOverviewAlarmsPage() {
    return (
            <Grid container>
                <Grid item xs={12}>
                    <TotalizerAlarms />
                    <AlarmPage />
                </Grid>
            </Grid>
    )
}
