import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export enum LayoutMode {
    Desktop,
    Tablet,
    Mobile
}

export interface HeaderMenuButtons {
    layoutButton: boolean;
    chartsButton: boolean;
    mapButton: boolean;
    alarmsButton: boolean;
    equipmentsButton: boolean;
    synopticButton: boolean;
    activePowerButton: boolean;
}

export interface HeaderMenuSettings {
    open: boolean;
    buttons: HeaderMenuButtons
    marginLeft: number;
}

interface HeaderSubMenuSettings {
    open: boolean;
}

export interface LayoutState {
    mode: LayoutMode;
    openMainMenu: boolean;
    openSubMenu: boolean;
    contentPagePaddingTop: number;
    contentMarginLeft: number;
    header: HeaderMenuSettings;
    subHeader: HeaderSubMenuSettings;
}

const initialState: LayoutState = {
    mode: LayoutMode.Desktop,
    contentMarginLeft: 400,
    contentPagePaddingTop: 150,
    openMainMenu: true,
    openSubMenu: false,
    header: {
        open: true,
        buttons: {
            layoutButton: true,
            chartsButton: true,
            mapButton: false,
            alarmsButton: true,
            equipmentsButton: false,
            synopticButton: true,
            activePowerButton: true
        },
        marginLeft: 20
    },
    subHeader: {
        open: false
    }
};

// Slice / Reducer
const LayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {

        // Actions
        setContentPagePaddingTop: (state, action: PayloadAction<number>) => {
            state.contentPagePaddingTop = action.payload
        },
        toggleMainMenu :(state) => {
            state.openMainMenu ? state.openMainMenu = false : state.openMainMenu = true;
        },
        toggleSubMenu :(state) => {
            state.openSubMenu ? state.openSubMenu = false : state.openSubMenu = true;
        },
        showFullMenu :(state) => {
            state.openMainMenu = true;
            state.openSubMenu = true;
        },
        hideFullMenu :(state) => {
            state.openMainMenu = false;
            state.openSubMenu = false;
        },
        showMainMenu :(state) => {
            state.openMainMenu = true;
        },
        hideMainMenu :(state) => {
            state.openMainMenu = false;
        },
        showSubMenu :(state) => {
            state.openSubMenu = true;
        },
        hideSubMenu :(state) => {
            state.openSubMenu = false;
        },

        showPageHeaderMenu :(state) => {
            state.header.open = true;
            state.contentPagePaddingTop = 150;
        },
        hidePageHeaderMenu :(state) => {
            state.header.open = false;
            state.contentPagePaddingTop = 85;
        },
        showPageHeaderSubMenu :(state) => {
            state.subHeader.open = true;
            state.contentPagePaddingTop = 210;
        },
        hidePageHeaderSubMenu :(state) => {
            state.subHeader.open = false;
            state.contentPagePaddingTop = 150;
        },

        setLayoutHeaderMenuOpen :(state) => {
            state.contentPagePaddingTop = 150;
        },
        setLayoutHeaderMenuClosed :(state) => {
            state.contentPagePaddingTop = 85;
        },
        setLayoutSubHeaderMenuOpen :(state) => {
            state.contentPagePaddingTop = 210;
        },
        setLayoutSubHeaderMenuClosed :(state) => {
            state.contentPagePaddingTop = 150;
        },

        setPageHeaderMenuButtons :(state, action: PayloadAction<HeaderMenuButtons>) => {
            Object.assign(
                state.header,
                {
                    buttons: {
                        layoutButton: action.payload.layoutButton,
                        chartsButton: action.payload.chartsButton,
                        mapButton: action.payload.mapButton,
                        alarmsButton: action.payload.alarmsButton,
                        equipmentsButton: action.payload.equipmentsButton
                    }
                }
            );
        },
        setPageHeaderSubMenuButtonsState :(state) => {
            Object.assign(state.subHeader, {open: true});
        },
        // App Mode
        setDesktopMode: (state) => {
            state.mode = LayoutMode.Desktop
        },
        setMobileMode: (state) => {
            state.mode = LayoutMode.Mobile
        },
        setTabletMode: (state) => {
            state.mode = LayoutMode.Tablet
        },
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    }
})

export const {
    reset,
    setPageHeaderSubMenuButtonsState,
    toggleMainMenu,
    toggleSubMenu,
    setContentPagePaddingTop,
    setDesktopMode,
    setMobileMode,
    setTabletMode,
    showMainMenu,
    showSubMenu,
    showFullMenu,
    hideMainMenu,
    hideSubMenu,
    hideFullMenu,
    showPageHeaderMenu,
    showPageHeaderSubMenu,
    setPageHeaderMenuButtons,
    hidePageHeaderMenu,
    hidePageHeaderSubMenu,

    setLayoutHeaderMenuOpen,
    setLayoutHeaderMenuClosed,
    setLayoutSubHeaderMenuOpen,
    setLayoutSubHeaderMenuClosed
} = LayoutSlice.actions;

// Reducer
export default LayoutSlice.reducer;
