import {ReactElement, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import {
    isDashboardOverviewActivePowerPage,
    isDashboardOverviewAlarmsPage,
    isDashboardOverviewChartsPage,
    isDashboardOverviewLayoutPage,
    isDashboardOverviewMapPage,
    isDashboardOverviewSynopticPage,
    isDashboardPowerPlantAlarmsPage,
    isDashboardPowerPlantChartsPage, isDashboardPowerPlantInvertersPage,
    isDashboardPowerPlantLayoutPage, isDashboardPowerPlantOverviewPage,
    isDashboardPowerPlantPage,
    isDashboardPowerPlantSkidAlarmsPage,
    isDashboardPowerPlantSkidChartsPage,
    isDashboardPowerPlantSkidLayoutPage,
    isDashboardPowerPlantSKidPage,
    isDashboardPowerPlantTrackersPage, SystemRoutes
} from "../../../Utils/RouteUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {useAppSelector} from "../../../Config/Hooks";
import {Period, PeriodSelector} from "@atiautomacao/ati-ui-library";
import {useDispatch} from "react-redux";
import {setPeriodSynoptic} from "../Synoptic/SynopticChartReducer";
import {setPeriodTrackers} from "../Powerplant/Trackers/TrackersReducer";
import {checkDiffDays} from "../../../Utils/DataUitils";

const getLayoutUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_LAYOUT;
}

const getChartUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_CHARTS;
}

const getAlarmsUrl = (): string => {
    if(isDashboardPowerPlantSKidPage()) {
        return SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS
    }else if(isDashboardPowerPlantPage()){
        return SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS;
    }
    return SystemRoutes.DASHBOARD_OVERVIEW_ALARMS;
}

const getTrackersUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_TRACKERS;
}
const getInvertersUrl = (): string => {
    return SystemRoutes.DASHBOARD_POWER_PLANT_INVERTER;
}

const style = {
    linkItem: {
        marginLeft: 5,
        marginRight: 5,
        color: "#051F36",
        fontWeight: "500"
    },
    selectedLinkItem: {
        textDecoration: 'none',
    }
}

const DashboardHeaderMenuMediumLargeScreens = (): ReactElement => {

    const location = useLocation();
    const headerMenu = useAppSelector((state: any) => state.dashboardMenu.header);
    const dispatch = useDispatch();
    const { selectedPowerPlant } = useAppSelector((state) => state.dashboard);

    const [layoutUrl, setLayoutUrl] = useState(getLayoutUrl());
    const [chartUrl, setChartUrl] = useState(getChartUrl());
    const [alarmsUrl,setAlarmsUrl] = useState(getAlarmsUrl());
    const [trackersUrl, setTrackersUrl] = useState(getTrackersUrl());

    const [isTrackersDisabled, setIsTrackerDisabled] = useState(true);
    const [isInvertersDisabled, setIsInvertersDisabled] = useState(true);

    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const initialPeriodTrackers: Period = {
        groupBy: "day",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [periodDataTrackers, setPeriodDataTrackers] = useState<Period>(initialPeriodTrackers);

    useEffect(() => {
        let hasTrackers = false;
        let hasInverters = false;
        if(selectedPowerPlant){
            selectedPowerPlant.skidList.forEach((skid: any) => {
                hasTrackers = skid.equipmentList.some((eqp: any) => eqp.equipmentType && eqp.equipmentType?.id === 8) ||
                    selectedPowerPlant.equipmentList.some((eqpPs: any) => eqpPs.equipmentType && eqpPs.equipmentType?.id === 8);
                hasInverters = skid.equipmentList.some((eqp: any) => eqp.equipmentType && eqp.equipmentType?.id === 2) ||
                    selectedPowerPlant.equipmentList.some((eqpPs: any) => eqpPs.equipmentType && eqpPs.equipmentType?.id === 2);
            });
        }
        setIsTrackerDisabled(!hasTrackers);
        setIsInvertersDisabled(!hasInverters);
    }, [selectedPowerPlant]);

    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(setPeriodSynoptic(period));
        }
    }, [period]);

    useEffect(() => {
        if(checkDiffDays(periodDataTrackers.toDateTime, periodDataTrackers.fromDateTime) > 0){
            dispatch(setPeriodTrackers(periodDataTrackers));
        }
    }, [periodDataTrackers]);

    return (
        <>
            {headerMenu.buttons.chartsButton ?
                <Link to={getChartUrl()}>
                    <Button variant={"text"} color={"secondary"}
                            disabled={isDashboardOverviewChartsPage() || isDashboardPowerPlantChartsPage() || isDashboardPowerPlantSkidChartsPage()}
                            style={style.linkItem} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Gráficos
                    </Button>
                </Link> : null }

            {headerMenu.buttons.alarmsButton ?
                <Link to={getAlarmsUrl()}>
                    <Button variant={"text"} color={"secondary"}
                            disabled={isDashboardOverviewAlarmsPage() || isDashboardPowerPlantAlarmsPage() || isDashboardPowerPlantSkidAlarmsPage()}
                            style={style.linkItem} sx={{textTransform:"capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar} />}>
                        Alarmes
                    </Button>
                </Link> : null }


            {headerMenu.buttons.mapButton && !isDashboardPowerPlantPage() ?
                <Link to={`/dashboard/map`}>
                    <Button variant={"text"} color={"secondary"} disabled={isDashboardOverviewMapPage()}
                            style={style.linkItem} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Mapa
                    </Button>
                </Link> : null}

            { headerMenu.buttons.synopticButton ?
                <Link to={`/dashboard/synoptic`}>
                    <Button variant={"text"} color={"secondary"} disabled={isDashboardOverviewSynopticPage()}
                            style={style.linkItem} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Sinóptico
                    </Button>
                </Link> : null}

            { headerMenu.buttons.activePowerButton ?
                <Link to={`/dashboard/active-power`}>
                    <Button variant={"text"} color={"secondary"} disabled={isDashboardOverviewActivePowerPage()}
                            style={style.linkItem} sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Potência Ativa
                    </Button>
                </Link> : null}

            {headerMenu.buttons.layoutButton && !isDashboardOverviewLayoutPage() ?
                <Link  to={getLayoutUrl()}>
                    <Button variant={"text"} color={"secondary"}
                            style={style.linkItem}
                            disabled={isDashboardPowerPlantSkidLayoutPage() || isDashboardPowerPlantLayoutPage()}
                            sx={{textTransform: "capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        Unifilar
                    </Button>
                </Link>: null }

            {headerMenu.buttons.equipmentsButton && !isDashboardPowerPlantSKidPage() ?
                <Link to={`/dashboard/power-plant${location.pathname.includes("skid") ? "/skid": ""}/equipments`}>
                    <Button variant={"text"} color={"secondary"}
                            style={style.linkItem} sx={{textTransform: "capitalize"}}
                            disabled={location.pathname.includes("equipments")}
                            startIcon={<FontAwesomeIcon icon={faChartBar}/>}>
                        <Typography>Equipments</Typography>
                    </Button>
                </Link> : null}

            {headerMenu.buttons.trackersButton && (isDashboardPowerPlantTrackersPage() || isDashboardPowerPlantOverviewPage) ?
                <Link to={isTrackersDisabled ? '#' : getTrackersUrl()}>
                    <Button
                        variant={"text"} color={"secondary"}
                        sx={{textTransform: "capitalize"}}
                        style={isTrackersDisabled ? {} : style.linkItem}
                        disabled={location.pathname.includes("trackers") || isTrackersDisabled}
                        startIcon={<FontAwesomeIcon icon={faChartBar}/>}
                    >Trackers</Button>
                </Link>: null }

            {headerMenu.buttons.invertersButton && (isDashboardPowerPlantInvertersPage() || isDashboardPowerPlantOverviewPage) ?
                <Link to={isInvertersDisabled ? '#' : getInvertersUrl()}>
                    <Button
                        variant={"text"} color={"secondary"}
                        sx={{textTransform: "capitalize"}}
                        style={isInvertersDisabled ? {} : style.linkItem}
                        disabled={location.pathname.includes("inverter") || isInvertersDisabled}
                        startIcon={<FontAwesomeIcon icon={faChartBar}/>}
                    >Inversor</Button>
                </Link>: null
            }

            { isDashboardOverviewSynopticPage() ?
                <div style={{marginLeft: 'auto'}}>
                    <PeriodSelector
                        styleProps={{base: {display: "flex", flexDirection: "row", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        periodInterval={{
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                            month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                            year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                            general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                        }}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["day", "week", "month", "year", "general"]}
                    />
                </div> : ""
            }

            { isDashboardPowerPlantTrackersPage() ?
                <div style={{marginLeft: 'auto'}}>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        periodInterval={{
                            day: {date: periodDataTrackers.toDateTime, startTime: "04:00:00", endTime: "21:00:00"},
                        }}

                        period={periodDataTrackers}
                        onChange={setPeriodDataTrackers}
                        groupByOptions={["day"]}
                    />
                </div> : ""
            }

        </>
    );
}

export default DashboardHeaderMenuMediumLargeScreens;