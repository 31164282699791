import * as React from "react";
import {useState} from "react";
import MenuReportReactQuery from "./reactQuery/MenuReportReactQuery";
import {NavigationMenu} from "@atiautomacao/ati-ui-library";
import {
    DashBoardNavigationAnalysisSelectedId
} from "../../../Analysis/types/reducer/DashboardNavigationAnalysisMenuState";
import {TeleMeasurementReport} from "../../../Analysis/types/DashboardNavigationAnalysisRowsOfGrid";

interface ReportTeleObjectMenuProps {
    onSelectedIdsChange: Function;
    preSelectedEquipmentIds: number[];
    selectedReport: TeleMeasurementReport | null;
}

export default function ReportTeleObjectMenu({ onSelectedIdsChange, preSelectedEquipmentIds, selectedReport }: Readonly<ReportTeleObjectMenuProps>) {
    // const [dashboardNavigationAnalysisMenu, setDashboardNavigationAnalysisMenu] = useState<DashboardNavigationMenuInterface[]>();
    const [selectedIds, setSelectedIds] = useState<DashBoardNavigationAnalysisSelectedId | undefined>();

    const { data, isSuccess } = MenuReportReactQuery();

    let dataMenu = data?.data;

    const handleNodeSelected = (nodes: any) => {
        setSelectedIds(nodes);
        onSelectedIdsChange(nodes)
    };



    return (
        <React.Fragment>
            {
                dataMenu !== undefined && dataMenu.length > 0 &&
                <NavigationMenu data={dataMenu} onNodeClick={handleNodeSelected} selectedReport={selectedReport}/>
            }
        </React.Fragment>
    );
}