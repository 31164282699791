import {QueryClient, QueryClientProvider} from "react-query";
import {ADataGrid, ADataGridColumn} from "@atiautomacao/ati-ui-library";
import IconButton from "@mui/material/IconButton";
import {Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {TeleObjectType} from "../../../../Shared/Types/TeleObjectType";
import {RowEquipmentClass} from "../EquipmentClassSettingsPage";

export const initColumns = [
    {
        name: 'model',
        label: 'Modelo',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'description',
        label: 'Descrição',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[];

interface PointClassDataGridHomeProps {
    teleObjectList?: Array<TeleObjectType>
    isSuccess: boolean;
}

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowTeleObjClass {
    id?: number;
    name?: string | null;
    teleObjType?: string | null;
    variation?: string | null;
    severity?: string | null;
    adjustment?: string | null;
    normalization?: string | null;
    unit?: string | null;
    point?: number | null;
    address?: string | null;
    note?: string | null;
    commandVariables?: string | null;
    actionsConfig?: Array<ActionConfig>
}

export const ModelDataGrid = () => {
    const [rows, setRows] = useState<Array<RowTeleObjClass>>([]);
    const [totalOfRecords, setTotalOfRecords] = useState(0);

    // useEffect(() => {
    //     if(isSuccess){
    //         setTotalOfRecords(teleObjectList ? teleObjectList.length : 0);
    //         if(teleObjectList && teleObjectList.length > 0){
    //             let rowsTeleObj = teleObjectList.map((teleObj: any) => {
    //                 console.log(teleObj)
    //                 return {
    //                     id: teleObj.id,
    //                     name: teleObj.name,
    //                     teleObjType: teleObj.teleObjectType.name,
    //                     variation: teleObj.variation,
    //                     severity: teleObj.severity.name,
    //                     adjustment: teleObj.teleObjectConvertExpression,
    //                     normalization: teleObj.normalAlarmCondition,
    //                     unit: teleObj.unit,
    //                     point: teleObj.point,
    //                     address: teleObj.address,
    //                     note: teleObj.note,
    //                     commandVariables: null,
    //                     actionsConfig: [
    //                         {actionName: "edit", disabled: false},
    //                         {actionName: "delete", disabled: false},
    //                     ]
    //                 }
    //             });
    //
    //             setRows(rowsTeleObj);
    //         }
    //     }
    // }, [teleObjectList]);

    return (
        <>
            <ADataGrid
                columns={initColumns}
                rows={[]}
                loading={false}
                totalOfRecords={totalOfRecords}
                hideSelection={true}
                actions={
                    <>
                        <IconButton name="edit" size={"small"} aria-label="Edit">
                            <Tooltip title="Editar">
                                <Edit/>
                            </Tooltip>
                        </IconButton>
                        <IconButton name="delete" size={"small"} aria-label="Delete">
                            <Tooltip title="Excluir">
                                <Delete/>
                            </Tooltip>
                        </IconButton>
                    </>
                }
            />
        </>
    );
}

export default function ModelDataGridHome() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModelDataGrid/>
        </QueryClientProvider>
    )
}