import {useQuery} from "react-query";
import axios from "axios";


interface DashboardNavigationAnalysis {
    id: number;
    name: string;
    selected?: any;
    status?: any;
    skidList: {
        id: number;
        name: string;
        selected?: boolean;
        status?: any;
        equipmentList: {
            id: number,
            name: string,
            selected?: any;
            etsStatus?: string;
        }[]
    }[];
    equipmentList: {
        id: number,
        name: string,
        selected?: any;
        etsStatus?: any;
    }[]
}

interface Response {
    message: string;
    data: Array<DashboardNavigationAnalysis>
}

const apiUrl = 'api/analysis/power-stations';

const fetchDashboardNavigationMenu = async (): Promise<Response> =>{
    return await axios
        .get<Response>(`${apiUrl}`,{timeout:30000})
        .then((res) => res.data)
}

const MenuReportReactQuery = () => {
    return useQuery({
        queryKey: ['data'],
        queryFn: () => fetchDashboardNavigationMenu()
    })
}

export default MenuReportReactQuery;