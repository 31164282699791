import React, {FC} from 'react';
import { AppBar, Toolbar, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelOutlined from "@mui/icons-material/CancelOutlined";

interface CustomAppBarProps {
    handleSave: () => void;
    handleBack: () => void;
}

const CustomAppBar: FC<CustomAppBarProps> = ({ handleSave, handleBack }) => {
    return (
        <AppBar position="fixed" style={{top: 64}}>
            <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                <Button
                    variant="contained"
                    size={"small"}
                    color={"primary"}
                    endIcon={<SaveIcon/>}
                    style={{marginLeft: '22%'}}
                    onClick={handleSave}
                >
                    Salvar
                </Button>
                <Button
                    variant="outlined"
                    size={"small"}
                    color={"primary"}
                    endIcon={<CancelOutlined />}
                    style={{marginLeft: '1%'}}
                    onClick={handleBack}
                >
                    Cancelar
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;