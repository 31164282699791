import {Breadcrumbs, Link as MatLink} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {
    includesDashboardPowerPlantEquipmentPage,
    includesDashboardPowerPlantSkidEquipmentPage,
    includesSettingsCountryPage,
    includesSettingsProfilesPage,
    isAnalysisPage,
    isDashboardOverviewMapPage,
    isDashboardOverviewPage,
    isDashboardPage,
    isDashboardPowerPlantAlarmsPage,
    isDashboardPowerPlantChartsPage,
    isDashboardPowerPlantEquipmentComparePage,
    isDashboardPowerPlantEquipmentDetailsPage,
    isDashboardPowerPlantLayoutPage,
    isDashboardPowerPlantPage,
    isDashboardPowerPlantSkidAlarmsPage,
    isDashboardPowerPlantSkidChartsPage,
    isDashboardPowerPlantSkidEquipmentComparePage,
    isDashboardPowerPlantSkidEquipmentDetailsPage,
    isDashboardPowerPlantSkidLayoutPage,
    isDashboardPowerPlantSKidPage, isGraphElementPage,
    isHelpPage,
    isReportsAlarmsPage,
    isReportsAuditPage,
    isReportsEquipmentClassPage,
    isReportsEquipmentPage,
    isReportsManagementReport,
    isReportsPage,
    isReportsPerformanceSummaryPage,
    isReportsPowerPlantPage,
    isReportsSkidPage,
    isReportsTeleobjectPage,
    isSettingsAuditPage, isSettingsEquipmentClassFormPage,
    isSettingsEquipmentClassPage,
    isSettingsEquipmentPage,
    isSettingsEquipmentTypePage, isSettingsGraficElementPage,
    isSettingsMacroRegionPage,
    isSettingsPage,
    isSettingsPowerPlantPage, isSettingsPowerStationExcelPage,
    isSettingsRegionPage,
    isSettingsSkidPage,
    isSettingsUsersPage,
    SystemRoutes,
} from "../../../../Utils/RouteUtils";
import {useAppSelector} from "../../../../Config/Hooks";

export default function DashboardPowerPlantChartsPage(){
    const {selectedEquipment, selectedPowerPlant, selectedSkid} = useAppSelector((state) => state.dashboard);
    return(
        <Breadcrumbs aria-label="breadcrumb"
                     sx={{ display:{xs: "none", lg: "block"} }}
                     style={{marginLeft: 20, color: "white", fontWeight: "bolder"}}
                     separator={
                         <FontAwesomeIcon
                             icon="caret-right"
                             style={{marginBottom: 3, marginLeft: 3, marginRight: 3 }}
                         />
                     }

        >

            {/* DASHBOARD */}
            {/*FIRST LEVEL*/}
            {isDashboardPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD}>DASHBOARD</MatLink> : null}
            {isAnalysisPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.ANALYSIS}>ANALYSIS</MatLink> : null}
            {isSettingsPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS}>SETTINGS</MatLink> : null}
            {isReportsPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS}>REPORTS</MatLink> : null}
            {isHelpPage() ? <MatLink underline="hover" color="inherit" href={SystemRoutes.HELP}>HELP</MatLink> : null}

            {/* SECOND LEVEL */}
            {/* Dashboard pages */}
            {isDashboardOverviewPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW}>Visão Geral</MatLink>
                : null}

            {isDashboardOverviewMapPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_OVERVIEW_MAP}>Map</MatLink>
                : null}

            {isDashboardPowerPlantPage() ?
                // <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW}>Power Plant</MatLink>
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_OVERVIEW}>{selectedPowerPlant?.name}</MatLink>
                : null}

            {isDashboardPowerPlantLayoutPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_LAYOUT}>Planta</MatLink>
                : null}

            {isDashboardPowerPlantAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_ALARMS}>Alarmes</MatLink>
                : null}

            {isDashboardPowerPlantChartsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_CHARTS}>Gráficos</MatLink>
                : null}

            {isDashboardPowerPlantSKidPage() ?
                // <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID}>Skid</MatLink>
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_OVERVIEW}>{selectedSkid.name}</MatLink>
                : null}

            {isDashboardPowerPlantSkidLayoutPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_LAYOUT}>Planta</MatLink>
                : null}

            {isDashboardPowerPlantSkidAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_ALARMS}>Alarmes</MatLink>
                : null}

            {isDashboardPowerPlantSkidChartsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_CHARTS}>Gráficos</MatLink>
                : null}

            {includesDashboardPowerPlantEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS}>{selectedEquipment.name}</MatLink>
                : null}

            {includesDashboardPowerPlantSkidEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS}>{selectedEquipment.name}</MatLink>
                : null}

            {isDashboardPowerPlantEquipmentDetailsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_DETAILS}>Detalhes</MatLink>
                : null}
            {isDashboardPowerPlantSkidEquipmentDetailsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS}>Detalhes</MatLink>
                : null}

            {isDashboardPowerPlantEquipmentComparePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_EQUIPMENTS_COMPARE}>Comparar</MatLink>
                : null}

            {isDashboardPowerPlantSkidEquipmentComparePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_COMPARE}>Comparar</MatLink>
                : null}

            {/*Settings pages */}
            {includesSettingsCountryPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_COUNTRY}>Country</MatLink>
                : null}
            {isSettingsMacroRegionPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_MACRO_REGION}>Macro Region</MatLink>
                : null}
            {isSettingsRegionPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_REGION}>Region</MatLink>
                : null}
            {isSettingsEquipmentTypePage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_TYPE}>Equipment Type</MatLink>
                : null}
            {isSettingsGraficElementPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAFIC_ELEMENT}>Grafic Element</MatLink>
                : null}
            {isSettingsPowerStationExcelPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_STATION_EXCEL}>Usina via Excel</MatLink>
                : null}
            {isGraphElementPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_GRAPH_ELEMENT}>Elemento Gráfico</MatLink>
                : null}
            {isSettingsEquipmentClassPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}>Equipment Class</MatLink>
                : null}
            {isSettingsEquipmentClassFormPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}>Equipment Class Form</MatLink>
                : null}
            {isSettingsEquipmentPage() && (!isSettingsEquipmentTypePage() && !isSettingsEquipmentClassPage())?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_EQUIPMENT}>Equipment</MatLink>
                : null}
            {isSettingsSkidPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_SKID}>Skid</MatLink>
                : null}
            {isSettingsPowerPlantPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_POWER_PLANT}>Power Plant</MatLink>
                : null}
            {includesSettingsProfilesPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_PROFILE}>Profiles</MatLink>
                : null}
            {isSettingsUsersPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_USERS}>Users</MatLink>
                : null}
            {isSettingsAuditPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.SETTINGS_AUDIT}>Audit</MatLink>
                : null}

            Reports page
            {isReportsAlarmsPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_ALARMS}>Alarms</MatLink>
                : null}
            {isReportsPerformanceSummaryPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_PERFORMANCE_SUMMARY}>Performance Summary</MatLink>
                : null}
            {isReportsPowerPlantPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_POWER_PLANTS}>Power Plant</MatLink>
                : null}
            {isReportsManagementReport() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_MANAGEMENT_REPORT}>Relatório de Gestão</MatLink>
                : null}
            {isReportsEquipmentClassPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_EQUIPMENT_CLASS}>Equipment Classes</MatLink>
                : null}
            {isReportsEquipmentPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_EQUIPMENT}>Equipment</MatLink>
                : null}
            {isReportsSkidPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_SKID}>Skid</MatLink>
                : null}
            {isReportsTeleobjectPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_TELEOBJECTS}>Teleobjects</MatLink>
                : null}
            {isReportsAuditPage() ?
                <MatLink underline="hover" color="inherit" href={SystemRoutes.REPORTS_AUDIT}>Audit</MatLink>
                : null}

        </Breadcrumbs>
    );
}