import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setPowerPlant, setSkid} from '../../Modules/Dashboard/DashboardReducer';
import {RootState} from '../../Config/Store';
import {useAppSelector} from '../../Config/Hooks';
import {useEffect, useState} from 'react';

export const useSkidNavigation = (): (powerStationId: number, skidId: number) => void => {
    const [newPowerStation, setNewPowerStation] = useState<any>(null);
    const [newSkid, setNewSkid] = useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { entities } = useAppSelector((state: RootState) => state.dashboardNavigationMenu);
    const { selectedSkid } =  useAppSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        if (newPowerStation && newSkid) {
            dispatch(setPowerPlant(newPowerStation));
            dispatch(setSkid(newSkid));
        }
    }, [newSkid]);

    useEffect(() => {
        if (newSkid && selectedSkid && (selectedSkid.id === newSkid.id)) {
            navigate(`/dashboard/power-plant/skid/plant`);
        }
    }, [selectedSkid, newSkid])

    function navigateToSkid(powerStationId: number, skidId: number): void {
        if (!skidId) {
            return;
        }

        const [ powerPlant] = entities.filter(entity => entity.id === powerStationId);
        const [ skid] = powerPlant.skidList.filter(skid => skid.id === skidId);

        if (!powerPlant || !skid) {
            return;
        }

        setNewPowerStation(powerPlant);
        setNewSkid(skid);
    }

    return navigateToSkid;
}