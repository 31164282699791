import {useQuery} from "react-query";
import axios from "axios";

export interface Telemetry {
    id: number;
    name: string;
    unit: string;
    value: number[];
    time: string[];
}
export interface Telecommand {
    id: number;
    name: string;
    unit: string;
    value: number[];
    time: string[];
}
export interface Telesinal {
    id: number;
    name: string;
    value: number[];
    time: string[];
}
export interface EquipmentDetailsData {
    telemetryList: Telemetry[];
    telecommandList: Telecommand[];
    telesinalList: Telesinal[];

}
export interface TeleObjectDTO {
    startDateTime: string;
    stopDateTime:string;
    equipmentId: number;
    teleObjectList: number[];
}

interface Response {
    message: string;
    data: EquipmentDetailsData
}
const apiUrl = 'api/equipment';

const fetchData = async (teleObjectDTO: any): Promise<Response> =>{
    try {
        return await axios
            .post<Response>(`${apiUrl}/tele-objects/`, teleObjectDTO,{timeout:30000})
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(new Error("Error fetching echart data"));
    }
}

export const EquipmentDetailsReactQuery = (teleObject: any) => {
    return useQuery({
        queryKey: ['fetchTeleObjectData', teleObject],
        queryFn: () => {
            return fetchData(teleObject)
        },
        enabled: !!teleObject,
        retry: false
    })
}