import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../Config/Hooks";
import {RootState} from "../../Config/Store";
import {setPowerPlant} from "../../Modules/Dashboard/DashboardReducer";

export const usePowerStationNavigationToPlant = (): (powerStationId: number) => void => {
    const [newPowerStation, setNewPowerStation] = useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appState = useAppSelector((state: RootState) => state)
    const { entities } = appState.dashboardNavigationMenu;
    const { selectedPowerPlant } = appState.dashboard;

    useEffect(() => {
        if (newPowerStation) {
            dispatch(setPowerPlant(newPowerStation));
        }
    }, [newPowerStation]);

    useEffect(() => {
        if (newPowerStation && (selectedPowerPlant.id === newPowerStation.id)) {
            navigate(`/dashboard/power-plant/plant`);
        }
    }, [selectedPowerPlant])

    return useCallback((powerStationId: number) => {
        if (!powerStationId) {
            return;
        }

        const [powerStation] = entities.filter(entity => entity.id === powerStationId);

        if (!powerStation) {
            return;
        }

        // Adiciona uma chave única
        const updatedPowerStation = {...powerStation, uniqueKey: Date.now()};
        setNewPowerStation(updatedPowerStation);
    }, [entities]);


}

