export const mapPowerStationStatusToColor = (status: string): string => {
    const colors: Record<string, string> = {
        'Crítico': '#000000',
        'Não crítico': '#a4f657',
        'Configurado': '#ffffff',
        'Não configurado': '#e3ca24',
        'Não identificado': '#0000FF',
        'Respondendo': 'none',
        'Não responde': '#f0a12b',
        'Alarmado': '#e02222',
        'Não alarmado': 'none',
        'Habilitado': 'none',
        'Inibído': '#6e6767',
        'Monitorando': '#e8e8e8',
        'Não monitorando': 'none',
        'Normalizado': '#a4f657',
        'Ignored': '#808080',
        'default': '#28D7F6'
    }

    if (!status || colors[status] === undefined) {
        return colors.default;
    }

    return colors[status];
};
