import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

export class AuthorityEntity {
    id: number | null | undefined;
    name: string | null |undefined;
    constructor(id: number | null | undefined, name: string | null | undefined) {
        this.id = id;
        this.name = name;
    }
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<AuthorityEntity>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/authorities';

// Actions
export const searchEntitiesData = createAsyncThunk<any,PagedSearchParams | undefined,{rejectValue: any }>(
    'authority/search_entity',
    async (params : PagedSearchParams | undefined, thunkAPI) => {
        return axios.get(
            apiUrl + "/search",
            {
                params: params ? params.toURLSearchParams() : null
            }
        );
    }
);

export const getAllEntities = createAsyncThunk<any>(
    'authority/fetch_entity',
    async () => {
        return axios.get<AuthorityEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'authority/fetch_entity',
    async (id : number) => {
        return axios.get<AuthorityEntity>(`${apiUrl}/${id}`);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'authority/create_entity',
    async (entity: AuthorityEntity, thunkAPI) => {
        const result = await axios.post(apiUrl, entity);
        thunkAPI.dispatch(searchEntitiesData(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    'authority/update_entity',
    async (entity: AuthorityEntity, thunkAPI) => {
        const result = await axios.put(`${apiUrl}/${entity.id}`, entity);
        thunkAPI.dispatch(searchEntitiesData(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
    'authority/partial_update_entity',
    async (entity: AuthorityEntity, thunkAPI) => {
        const result = await axios.patch(`${apiUrl}/${entity.id}`, entity);
        thunkAPI.dispatch(searchEntitiesData(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'authority/delete_entity',
    async (id : number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete(requestUrl);
        thunkAPI.dispatch(searchEntitiesData(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);


// Slices
const authority = createSlice({
    name: 'authority',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clearProfileEntity: (state) => {
            state.entity = {id: 0, name: ""};
        },
        clearProfilesMessages: (state) => {
            state.successMessage = undefined;
            state.errorMessage = undefined;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(searchEntitiesData), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isFulfilled(getAllEntities), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = action.payload.data.message;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
            })
            .addMatcher(isPending(searchEntitiesData, getEntity, getAllEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntitiesData), (state, action) => {
                state.loading = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isRejected(getEntity, getAllEntities), (state, action) => {
                state.loading = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            })
            .addMatcher(isFulfilled(createEntity, getEntity, updateEntity, deleteEntity, partialUpdateEntity), (state, action) => {
                state.loading = false;
                state.saving = false;
                state.entity = action.payload.data.data;
                state.successMessage = action.payload.data.message;
                state.errorMessage = undefined;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = true;
            })
            .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            });
    },
})

export const { reset, clearProfileEntity, clearProfilesMessages } = authority.actions;

// Reducer
export default authority.reducer;
