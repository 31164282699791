import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setPowerPlant} from '../../Modules/Dashboard/DashboardReducer';
import {RootState} from '../../Config/Store';
import {useAppSelector} from '../../Config/Hooks';
import {useEffect, useState} from 'react';

export const usePowerStationNavigation = (): (powerStationId: number) => void => {
    const [newPowerStation, setNewPowerStation] = useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appState = useAppSelector((state: RootState) => state)
    const { entities } = appState.dashboardNavigationMenu;
    const { selectedPowerPlant } = appState.dashboard;

    useEffect(() => {
        if (newPowerStation) {
            dispatch(setPowerPlant(newPowerStation));
        }
    }, [newPowerStation]);

    useEffect(() => {
        if (newPowerStation && (selectedPowerPlant.id === newPowerStation.id)) {
            navigate(`/dashboard/power-plant/overview`);
        }
    }, [selectedPowerPlant])

    function navigateToPowerStation(powerStationId: number): void {
        if (!powerStationId) {
            return;
        }

        const [powerStation] = entities.filter(entity => entity.id === powerStationId);

        if (!powerStation) {
            return;
        }

        setNewPowerStation(powerStation);
    }

    return navigateToPowerStation;
}