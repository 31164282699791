import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {LayoutTotalizerState} from './types/layoutTotalizerState'
import {LayoutTotalizer} from "./types/layoutTotalizer";

const initialState: LayoutTotalizerState = {
    isLoading: false,
    errorMessage: null,
    totalizers: {
        generatedEnergy: 0,
        performanceRatio: 0,
        availability: 0,
        activePower: 0,
        capacityFactor: 0,
        irradiance: 0,
        irradiation: 0
    },
};

const apiUrl = 'api/skid/generation-skid/totalizers';


export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'Skid/fetch_entity',
    async ({ skidId }) => {
        const url = `${apiUrl}/${skidId}`;
        return await axios.get<LayoutTotalizer>(url, {
            timeout: 0
        });
    },
    { serializeError: serializeAxiosError }
);

// Slices
const TotalizerPlantSkidReducer = createSlice({
    name: 'TotalizerPlantSkidReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(getEntity), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    totalizers: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntity), state => {
                state.errorMessage = null;
                state.isLoading = true;
            })
    },
})

export const { reset } = TotalizerPlantSkidReducer.actions;
export const selectTotalizerPlantSkid = (state: RootState) => state.totalizerPlantSkid;

// Reducer
export default TotalizerPlantSkidReducer.reducer;
