import React from 'react';
import {mapElementToNode} from '../../../../../Shared/Helpers/mapElementToNode';
import {ACard} from '@atiautomacao/ati-ui-library';
import {Flow, Skeleton} from '@atiautomacao/ati-ui-library';
import {Box, Container, List, ListItemButton, ListItemText, styled} from "@mui/material";
import {Element} from "../../../../../Shared/Types/element";
import {truncateNumber} from "../../../../../Utils/NumberUtil";

interface Props {
    entities: any;
    solarimetricStation: any;
    statusData: any;
    isSuccess: boolean;
    title: string;
}
const FireNav = styled(List)<{ component?: React.ElementType }>({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

export const sortNodeChildren = (node: Element): Element => {
    const sortedEdges = node.edges.map(edge => sortNodeChildren(edge));
    sortedEdges.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

    return {...node, edges: sortedEdges };
};

const InverterNodes = ({entities, solarimetricStation, statusData, isSuccess,title}: Props): JSX.Element => {

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.sort((a,b) => a.label.localeCompare(b.label)).map(element => sortNodeChildren(element));
    };

    function fireNavCard(data:any[], label:string) {
        return(<FireNav component="nav" disablePadding>
            <Box
                sx={{
                    pb: 2,
                }}
            >
                <ListItemButton
                    alignItems="flex-start"
                    sx={{
                        px: 3,
                        pt: 2.5
                    }}
                >
                    <ListItemText
                        primary={label}
                        primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                            color: '#000',
                        }}
                        sx={{ my: 0 }}
                    />
                </ListItemButton>
                {data ?
                    data.map((item:any) => (
                        <ListItemButton
                            key={item.id}
                            sx={{ py: 0, minHeight: 32, color: '#666' }}
                        >
                            <ListItemText
                                primary={item.name}
                                title={item.name}
                                primaryTypographyProps={
                                    {
                                        fontSize: 14, fontWeight: 'medium',
                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                                        color: item?.status ? (item.status === 'Normal' ? '#0FE812' : '#FF0000') : '#666'
                                    }
                            }
                            />
                            <ListItemText
                                primary={item.value ? truncateNumber(item.value, 2) + (item.unityName ? item.unityName : "") : "-"}
                                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium', textAlign: 'right' }}
                            />
                        </ListItemButton>
                    ))
                    : null
                }
            </Box>
        </FireNav>)
    }

    let cardContentToRender: JSX.Element;
    if (!isSuccess) {
        cardContentToRender = (<Skeleton animation="wave" height={440} variant="rectangular" width="100%" />);
    } else if(entities && entities?.elements?.length > 0) {
        const newEntities:Element[] = entities?.elements ? [...entities.elements] : [];
        cardContentToRender = (
            <div style={{height: '600px', width: '100%'}}>
                <Box style={{width: window.innerWidth <= 1400 ? '50%' : '30%', position: 'absolute', float: 'left', zIndex: 10, left: '10px', top: '20px'}}>
                    {fireNavCard(statusData, "Status")}
                </Box>
                <Flow
                    nodes={traverseAndSortTree(newEntities).map(element =>
                        mapElementToNode(element, entities.images))}
                    isCreating={false}
                />
                <Box style={{width: window.innerWidth <= 1400 ? '50%' : '30%', position: 'absolute', float: 'right', zIndex: 10, right: '10px', top: '20px'}}>
                    {fireNavCard(solarimetricStation, "Solarimetrica")}
                </Box>
            </div>
        );
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{ bgcolor: '#e1e0e0', height: '600px', width: '100%' ,alignItems: 'center',
                    justifyContent: 'center', display:'flex'}}>
                    <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum dado encontrado</span>
                    </div>
                </Box>
            </Container>
        )
    }

    return (
        <ACard
            cardStyle={{ height: 639, position: 'relative' }}
            titleStyle={{fontSize: 17,justifyContent: 'center', textAlign: 'center'}}
            title={title}
        >

            {cardContentToRender}
        </ACard>
    );
};

export default InverterNodes;
