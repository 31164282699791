import * as React from 'react';
import {
    Box,
    Button,
    Collapse,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import SendIcon from '@mui/icons-material/Send';
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export default function SGDHelpMenu() {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box style={{textAlign: "center"}}>
            <br/>
            <TextField
                id="filled-multiline-static"
                label="Assunto"
                rows={4}
                defaultValue=""
                variant="standard"
                fullWidth
            />
            <br/>
            <br/>
            <TextField
                id="filled-multiline-static"
                label="Mensagem"
                fullWidth
                multiline
                rows={4}
                defaultValue=""
                variant="standard"
            />
            <br/>
            <br/>
            <Button variant="contained" endIcon={<SendIcon />}>
                Enviar
            </Button>

            <br/>
            <br/>
            <br/>
            <br/>
            <Box style={{textAlign: "left"}}>
                <Typography>Chamados</Typography>
                <Divider />
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4658" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box style={{paddingLeft: 50, paddingRight: 0}}>
                            <Typography>Starred Starred Starred Starred Starred Starred Starred</Typography>
                        </Box>
                    </Collapse>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4597" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4597" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4597" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4598" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4599" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faEnvelope} fontSize={21}/>
                        </ListItemIcon>
                        <ListItemText primary="Chamado #4600" />
                    </ListItemButton>
                </List>
            </Box>
        </Box>

    );
}
