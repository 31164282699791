import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CountryLayoutState, HeaderMenuButtons} from "./Country";
import {LayoutMode} from "../../../Config/Reducers/LayoutReducer";

const initialState: CountryLayoutState = {
    mode: LayoutMode.Desktop,
    header: {
        open: true,
        buttons: {
            newRecord: true
        },
        marginLeft: 20
    },
    subHeader: {
        open: false
    }
};

// Slice / Reducer
const CountryLayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {

        // Actions
        showCountryHeaderMenu :(state) => {
            state.header.open = true;
        },
        hideCountryHeaderMenu :(state) => {
            state.header.open = false;
        },
        showCountryHeaderSubMenu :(state) => {
            state.subHeader.open = true;
        },
        hideCountryHeaderSubMenu :(state) => {
            state.subHeader.open = false;
        },

        setCountryHeaderMenuButtons :(state, action: PayloadAction<HeaderMenuButtons>) => {
            Object.assign(
                state.header,
                {
                    buttons: {
                        newRecord: action.payload.newRecord
                    }
                }
            );
        },

        setCountryHeaderSubMenuButtonsState :(state) => {
            Object.assign(state.subHeader, {open: true});
        },

        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    }
})

export const {
    reset,
    setCountryHeaderSubMenuButtonsState,
    showCountryHeaderMenu,
    showCountryHeaderSubMenu,
    setCountryHeaderMenuButtons,
    hideCountryHeaderMenu,
    hideCountryHeaderSubMenu
} = CountryLayoutSlice.actions;

// Reducer
export default CountryLayoutSlice.reducer;
