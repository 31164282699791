import * as React from "react";
import {FunctionComponent} from "react";
import {AppBar, Button} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate} from "react-router-dom";
import {AddCircle, CancelOutlined} from "@mui/icons-material";
import {useAppSelector} from "../../Config/Hooks";

import SaveIcon from "@mui/icons-material/Save";


interface Props {
    isOnSave?: boolean,
    systemRoutes: string,
    handleSave?: any
}

export const HeaderMenu: FunctionComponent<Props> = ({ isOnSave=false, handleSave, systemRoutes}: Props) => {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const navigate = useNavigate();

    const handleSaveMenu = () => {
        handleSave()
    }
    if (isAuthenticated ) return (
        <AppBar style={{top: 64}}>
            <Toolbar
                sx={{
                    marginLeft: {xs: "initial", md: isAuthenticated ? (
                            openSubMenu ? (
                                openMainMenu ? `${400}px` : `${80}px`
                            ) : (
                                openMainMenu ? `${80}px` : `${0}` // Set margin to 50 if submenu closed and main menu open
                            )
                        ) : 'initial'},
                    backgroundColor: "white"
                }}
            >
                {
                    !isOnSave  ?
                    <Button
                        size={"small"}
                        variant="contained"
                        // component={Link}
                        onClick={() => `${navigate(systemRoutes)}`}
                        endIcon={<AddCircle />} >
                        Novo
                    </Button>
                        :
                       <>
                           <Button
                               variant="contained"
                               size={"small"}
                               color={"primary"}
                               endIcon={<SaveIcon/>}
                               onClick={handleSaveMenu}
                           >
                               Salvar
                           </Button>
                           <Button
                               size={"small"}
                               variant="outlined"
                               onClick={() => `${navigate(systemRoutes)}`}
                               endIcon={<CancelOutlined />}
                               style={{marginLeft: 5}}
                           >
                               Cancelar
                           </Button>
                       </>
                }


            </Toolbar>
        </AppBar>
    );

    return null;
}