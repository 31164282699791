import  React from "react";
import {Box, Paper, Typography} from "@mui/material";

export default function DashboardOverviewPage(){

    return (
        <Paper elevation={3}>
            <Box p={2} style={{height: 600}}>
                <Typography variant="h5" component="h1">Dashboard Root Page</Typography>
                <br/>
            </Box>
        </Paper>
    );
}