import axios, {AxiosRequestConfig} from "axios";
import {handleFileDownload} from "../Modules/Reports/ManagementReport/utils/postAndDownloadFile";


export const downloadFilePostMapping = (url: string, data: any, filename: string, successCallBack?: () => void, errorCallback?: (error: any) => void) => {
    const config: AxiosRequestConfig = {
        url: url,
        data,
        method: 'POST',
        responseType: 'blob'
    };

    axios({
        ...config,
        timeout: 40000
    })
        .then((response) => {
            handleFileDownload(response, filename, successCallBack, errorCallback);
        }).catch(error => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
}