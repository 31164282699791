import React, {useEffect} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {getTotalizers} from "../../../Shared/Components/AlarmTotalizers/AlarmTotalizersReducer";
import {Container} from "../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import {Skeleton, Totalizer} from "@atiautomacao/ati-ui-library";
import {PriorityHigh, QuestionMark, SolarPower, Construction, NotificationImportant} from "@mui/icons-material";
import {ACard, FloatMenuButton, FloatMenuItem} from "@atiautomacao/ati-ui-library";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import { Box, Grid } from "@mui/material";
import useInterval from "../../../Shared/Hooks/useInterval";

const TotalizerAlarms = () => {

    const { totalizers, isLoading } = useAppSelector((state: RootState) => state.alarmTotalizers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getTotalizers());
    }, []);

    useInterval(() => {
        dispatch(getTotalizers());
    }, 60000); //5 minutes

    if (isLoading) {
        return (
            <Box style={{paddingBottom: 20}}>
                <ACard
                    title={"Performance"}
                    // headerActions={
                    //     <FloatMenuButton
                    //         icon={<MoreVertIcon/>}
                    //         tooltip={"Floating Menu"}
                    //     >
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                    //             text="Settings"
                    //             disable={true}
                    //             link={"/"}
                    //         />
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                    //             text="Expand"
                    //             disable={true}
                    //         />
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                    //             text="Print"
                    //             disable={true}
                    //         />
                    //     </FloatMenuButton>
                    // }
                >
                    <Container data-testid="loading-skeleton">
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <Box style={{paddingBottom: 20}}>
            <ACard
                title={"Performance"}
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVertIcon/>}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </FloatMenuButton>
                // }
            >
                <Grid container spacing={2}>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.powerStationOff}
                            label="Usinas Fora do Ar"
                            cardIcon={<SolarPower style={{fontSize:"25px"}}/>}
                            background="#0288d1"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.equipmentsOff}
                            label="Equipamentos Fora do Ar"
                            cardIcon={<Construction style={{fontSize:"25px"}}/>}
                            background="#00bcd4"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.equipmentsAlarmed}
                            label="Equipamentos Alarmados"
                            cardIcon={<Construction style={{fontSize:"25px"}}/>}
                            background="#ffc107"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.active}
                            label="Ativos"
                            cardIcon={<NotificationImportant style={{fontSize:"25px"}}/>}
                            background="#616161"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.activeCritical}
                            label="Ativos Críticos"
                            cardIcon={<PriorityHigh style={{fontSize:"25px"}}/>}
                            background="#ff0019"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                        <Totalizer
                            value={totalizers.notAcknowledged}
                            label="Não Reconhecidos"
                            cardIcon={<QuestionMark style={{fontSize:"25px"}}/>}
                            background="#6B8897"
                            textColor="#ffffff"
                            width={200}
                            boldLabel={true}
                        />
                    </Grid>
                </Grid>
            </ACard>
        </Box>
    )

}

export default TotalizerAlarms;
