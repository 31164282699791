import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Container,
    TextField,
    InputLabel,
    FormHelperText, Select, MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindRegionByIdReactQuery,
    FindAllMacroRegionReactQuery,
    SaveRegionReactQuery
} from "./RegionReactQuery";
import {MacroRegionProps, RegionProps} from "../../../Shared/Types/Region";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const RegionFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;

    const mutationFindAllMacroRegion = FindAllMacroRegionReactQuery();

    const [regionName, setRegionName] = useState<string>("");
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [macroRegion, setMacroRegion] = useState<number>(-1);
    const [macroRegionList, setMacroRegionList] = useState<MacroRegionProps[]>(mutationFindAllMacroRegion.data?.data ?? []);

    const mutationSave = SaveRegionReactQuery();
    const mutationFindById = FindRegionByIdReactQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let regionData = data?.data as RegionProps;
                    setRegionName(regionData.name);
                    setLatitude(regionData.latitude);
                    setLongitude(regionData.longitude);
                    setMacroRegion(regionData.macroRegion.id ?? -1);
                }
            });
        }
    }, []);

    useEffect(() => {
        setMacroRegionList(mutationFindAllMacroRegion.data?.data ?? [])
    }, [mutationFindAllMacroRegion.data]);

    function handleSave() {
        const newMacroRegion = macroRegionList.find((entity) => entity.id == macroRegion);
        if(newMacroRegion && latitude != null && longitude != null && regionName.length >= 3) {
            let newRegion: RegionProps = {
                id: id != null ? Number(id) : null,
                name: regionName,
                latitude: latitude,
                longitude: longitude,
                macroRegion: newMacroRegion
            }

            mutationSave.mutate(newRegion, {
                onSuccess: () => {
                    navigate(SystemRoutes.SETTINGS_REGION);
                }
            });
        }

    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_REGION}/>
            <Box style={{paddingTop: 64}}>
                <ACard>
                    <Container style={{
                        marginLeft: -10,
                        display: 'flex',
                        flexDirection: window.innerWidth <= 900 ? "column": "row",
                        alignItems: 'left',
                        justifyContent: 'left',
                        columnGap: 30
                    }}>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="region-name">Nome</InputLabel>
                                <TextField
                                    error={regionName === ""}
                                    fullWidth
                                    id="region-name"
                                    variant="standard"
                                    placeholder={"Nome da região"}
                                    onChange={(value) => setRegionName(value.target.value)}
                                    value={regionName}
                                />
                                {
                                    regionName === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="latitude-region">Latitude</InputLabel>
                                <TextField
                                    fullWidth
                                    id="latitude"
                                    variant="standard"
                                    placeholder={"Número da Latitude"}
                                    type="number"
                                    onChange={(value) => setLatitude(parseInt(value.target.value))}
                                    value={latitude}
                                />
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="longitude-region">Longitude</InputLabel>
                                <TextField
                                    fullWidth
                                    id="longitude"
                                    variant="standard"
                                    placeholder={"Número da Longitude"}
                                    type="number"
                                    onChange={(value) => setLongitude(parseInt(value.target.value))}
                                    value={longitude}
                                />
                            </Box>
                        </div>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="macro-region">Macro Região</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="selectMacroRegion"
                                    id="selectMacroRegion"
                                    placeholder={"Selecione a Macro Região"}
                                    value={macroRegion}
                                    label="macro"
                                    onChange={(value) => setMacroRegion(value.target.value as number)}
                                    variant={"standard"}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        macroRegionList.map((entity: MacroRegionProps) => {
                                            if(entity.id) {
                                                return (
                                                    <MenuItem value={entity.id}>{entity.name}</MenuItem>
                                                )
                                            }

                                        })
                                    }
                                </Select>
                                { macroRegion == -1 &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>

                                }
                            </Box>
                        </div>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}

export function RegionHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <RegionFormPage />
        </QueryClientProvider>
    )
}