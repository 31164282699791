import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

export interface ProfileEntity {
    id?: number | null;
    name?: string ;
    authorityGroupItemDTOList?: Array<any>
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<ProfileEntity>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/authority-groups';

// Actions
export const searchEntities = createAsyncThunk<any,PagedSearchParams | undefined,{rejectValue: any }>(
    'profiles/search_entity',
    async (params : PagedSearchParams | undefined, thunkAPI) => {
        return axios.get(
            apiUrl + "/search",
            {
                params: params ? params.toURLSearchParams() : null
            }
        );
    }
);

export const getAllEntities = createAsyncThunk<any, void, {rejectValue: any }>(
    'profiles/fetch_entity',
    async (_, { rejectWithValue }) => {
        return axios.get<ProfileEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'profiles/fetch_entity',
    async (id : number) => {
        return axios.get<ProfileEntity>(`${apiUrl}/${id}`);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'profiles/create_entity',
    async (entity: ProfileEntity, thunkAPI) => {
        const result = await axios.post(apiUrl, entity);
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    'profiles/update_entity',
    async (entity: ProfileEntity, thunkAPI) => {
        return await axios.put(`${apiUrl}/${entity.id}`, entity);
    },
    { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
    'profiles/partial_update_entity',
    async (entity: ProfileEntity, thunkAPI) => {
        const result = await axios.patch(`${apiUrl}/${entity.id}`, entity);
        thunkAPI.dispatch(searchEntities(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'profiles/delete_entity',
    async (id : number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return await axios.delete(requestUrl);
    },
    { serializeError: serializeAxiosError }
);


// Slices
const authorityGroup = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clearProfileEntity: (state) => {
            state.entity = {id: 0, name: ""};
        },
        clearProfilesMessages: (state) => {
            state.successMessage = undefined;
            state.errorMessage = undefined;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(searchEntities, getAllEntities), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isPending(searchEntities, getEntity, getAllEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntities, getAllEntities), (state, action) => {
                state.loading = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;
            })
            .addMatcher(isRejected(getEntity), (state, action) => {
                state.loading = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            })
            .addMatcher(isFulfilled(createEntity, getEntity, updateEntity, deleteEntity, partialUpdateEntity), (state, action) => {
                state.loading = false;
                state.saving = false;
                state.entity = action.payload.data.data;
                state.successMessage = action.payload.data.message;
                state.errorMessage = undefined;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = true;
            })
            .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            });
    },
})

export const { reset, clearProfileEntity, clearProfilesMessages } = authorityGroup.actions;

// Reducer
export default authorityGroup.reducer;
