import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {FindUsersByIdReactQuery, SaveUsersReactQuery} from "./UsersReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {
    AppBar,
    Button, Checkbox,
    Container, FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {CancelOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import SaveIcon from "@mui/icons-material/Save";
import ListItemText from "@mui/material/ListItemText";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {getAllEntities, ProfileEntity} from "../Profiles/ProfilesReducer";
import {RootState} from "../../../Config/Store";


export interface Group {
    authorityGroup?: {
        name?: string;
    };
}

export const UsersFormPage = () => {

    const location = useLocation();
    const id = location.state?.id as string;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [checkedAllProfiles, setCheckedAllProfiles] = useState<boolean>(false);
    const [checkedProfiles, setCheckedProfiles] = useState<{
        name: string;
        checked: boolean;
        data: { id: number; name: string }
    }[]>([]);

    const {data, isSuccess} = SaveUsersReactQuery();
    const mutationSave = SaveUsersReactQuery();
    const mutationFindById = FindUsersByIdReactQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const entities = useAppSelector((state: RootState) => state.profile.entities);

    const profilesCheckedOfUser = (entities: any, userGroupList = []) => {
        return entities.map((entity: { name: any; id: any; }) => ({
            name: entity.name ?? '',
            checked: userGroupList.some((group: Group) => group?.authorityGroup?.name === entity.name) || false,
            data: { id: Number(entity.id) || 0, name: entity.name ?? '' }
        }));
    };


    useEffect(() => {
        if (id) {
            setIsEditing(true);
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let user = data?.data;
                    setName(user.name);
                    setUserName(user.userName);
                    setEmail(user.email);
                    setPassword(user.password)

                    const profiles = profilesCheckedOfUser(entities, user.groupList);
                    setCheckedProfiles(profiles);
                }
            });
        } else {
            setName("");
            setUserName("");
            setEmail("");
            setPassword("");
            setCheckedAllProfiles(false);
            if (entities.length > 0) {
                const profiles = entities.map((entity: ProfileEntity) => ({
                    name: entity.name ?? '',
                    checked: false,
                    data: {id: Number(entity.id) || 0, name: entity.name ?? ''}
                }));
                setCheckedProfiles(profiles);
            }
        }
    }, [id, entities]);

    useEffect(() => {
        dispatch(getAllEntities());
    }, [isEditing]);

    function handleSave() {
        const groupListChecked = checkedProfiles.map(checked => {
            if (checked.checked) {
                const entity = entities.find(entity => entity.id === checked.data.id);
                return {
                    id: null,
                    authorityGroup: {
                        id: entity.id,
                        name: entity.name,
                        authorityGroupItemList: entity.authorityGroupItemDTOList.map((item: {
                            id: any;
                            authority: { id: any; name: any; displayName: any; domain: any; };
                        }) => ({
                            id: item.id,
                            authorityGroup: null,
                            authority: {
                                id: item?.authority?.id ?? null,
                                name: item?.authority?.name,
                                displayName: item?.authority?.displayName,
                                domain: item?.authority?.domain
                            }
                        }))
                    }
                };
            }
            return null;
        }).filter(item => item !== null);

        let objectToSave = {
            id: id ? Number(id) : null,
            name: name,
            userName: userName,
            email: email,
            password: password,
            groupList: groupListChecked
        };
        mutationSave.mutate(objectToSave, {
            onSuccess: () => {
                navigate(SystemRoutes.SETTINGS_USERS);
            }
        });
    }

    const handleBack = () => {
        navigate(SystemRoutes.SETTINGS_USERS);

    }
    const handleChangeAllProfilesCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedProfiles(checkedProfiles.map(item => {
            return {...item, checked: event.target.checked};
        }));
        setCheckedAllProfiles(event.target.checked);
    };

    const handleChangeProfileCheckbox = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let updatedCheckedProfiles = [...checkedProfiles];
        updatedCheckedProfiles[index].checked = event.target.checked;
        setCheckedProfiles(updatedCheckedProfiles);
        setCheckedAllProfiles(updatedCheckedProfiles.every(item => item.checked));
    };


    return (
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <Button
                        variant="contained"
                        size={"small"}
                        color={"primary"}
                        endIcon={<SaveIcon/>}
                        style={{marginLeft: '22%'}}
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                    <Button
                        variant="outlined"
                        size={"small"}
                        color={"primary"}
                        endIcon={<CancelOutlined/>}
                        style={{marginLeft: '1%'}}
                        onClick={handleBack}
                    >
                        Cancelar
                    </Button>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>

                <ACard>
                    <Container style={{
                        marginLeft: -10,
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: 'left',
                        justifyContent: 'left',
                        columnGap: 30
                    }}>
                        <div style={{width: '50%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="user-name">Nome</InputLabel>
                                <TextField
                                    error={name === ""}
                                    fullWidth
                                    id="user-name"
                                    variant="standard"
                                    placeholder={"Nome do usuário"}
                                    onChange={(value) => setName(value.target.value)}
                                    value={name}
                                />
                                {
                                    name === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório. Digite sem caracteres
                                        especiais ou acentos</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="username">Nome de usuário</InputLabel>
                                <TextField
                                    error={userName === ""}
                                    fullWidth
                                    id="username"
                                    variant="standard"
                                    placeholder={"Username de usuário"}
                                    onChange={(value) => setUserName(value.target.value)}
                                    value={userName}
                                />
                                {
                                    userName === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                                {
                                    isEditing &&
                                    <FormHelperText id="my-helper-text" style={{color: 'red'}}>Caso queira editar o nome
                                        de usuário,
                                        lembre-se de alterá-lo de forma concisa.</FormHelperText>
                                }
                            </Box>
                        </div>
                        <div style={{width: '50%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="email">Email</InputLabel>
                                <TextField
                                    error={email === ""}
                                    fullWidth
                                    id="email"
                                    variant="standard"
                                    placeholder={"Email do usuário"}
                                    onChange={(value) => setEmail(value.target.value)}
                                    value={email}
                                />
                                {
                                    email === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="password">Senha</InputLabel>
                                <TextField
                                    error={password === ""}
                                    fullWidth
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    variant="standard"
                                    placeholder={"Senha do usuário"}
                                    onChange={(value) => setPassword(value.target.value)}
                                    value={password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {
                                    password === "" &&
                                    <FormHelperText id="my-helper-text">Campo Obrigatório. A senha precisa ter letras e
                                        números</FormHelperText>
                                }
                                {
                                    isEditing &&
                                    <FormHelperText id="my-helper-text" style={{color: 'red'}}>Caso queira editar a
                                        senha, lembre-se de alterá-la com letras e números.</FormHelperText>
                                }
                            </Box>
                        </div>
                    </Container>
                    <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel
                            id="demo-multiple-checkbox-label"
                        >
                            Perfis
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={checkedProfiles.filter(item => item.checked).map(item => item.name)}
                            input={<OutlinedInput label="Perfis"/>}
                            renderValue={(selected) => selected.join(', ')}
                            variant="standard"
                            disableUnderline
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {border: 0}
                            }}
                        >
                            <MenuItem value="Todos">
                                <Checkbox
                                    checked={checkedAllProfiles}
                                    onChange={handleChangeAllProfilesCheckbox}
                                />
                                <ListItemText primary="Todos"/>
                            </MenuItem>
                            {checkedProfiles.map((nameObject, index) => (
                                <MenuItem
                                    key={nameObject.name}
                                    value={nameObject.name}
                                >
                                    <Checkbox
                                        checked={nameObject.checked}
                                        onChange={(e) => handleChangeProfileCheckbox(e, index)}
                                    />
                                    <ListItemText primary={nameObject.name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ACard>
            </Box>
        </>
    )

}

export default function UserHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <UsersFormPage/>
        </QueryClientProvider>
    )
}