import {Grid} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TransformerTemperatureChart from "./TransformerTemperatureChart/TransformerTemperatureChart";
import GenerationEnergySkidChart from "./GenerationEnergySkidChart/GenerationEnergySkidChart";
import ActivePowerInvertersSkidChart from "./ActivePowerInverterSkidChart/ActivePowerInvertersSkidChart";
import PowerPUForInvertersChart from "./PowerPUForInvertersChart/PowerPUForInvertersChart";
import GenerationRateOfInvertersChart from "./GenerationRateOfInvertersChart/GenerationRateOfInvertersChart";
import ErrorBoundaryChart from "../../../Shared/Error/ErrorBoudaryChart"

export default function DashboardPowerPlantSkidChartsPage(){

    const {selectedPowerPlant, selectedSkid} = useAppSelector((state) => state.dashboard);

    return(
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6} xl={4}>
                <ErrorBoundaryChart chartName={"Potência PU"}>
                    <PowerPUForInvertersChart skid={selectedSkid}/>
                </ErrorBoundaryChart>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ErrorBoundaryChart chartName={"Energia Gerada vs Injetada"}>
                    <GenerationEnergySkidChart powerPlant={selectedPowerPlant.id} skid={selectedSkid.id}/>
                </ErrorBoundaryChart>

            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ErrorBoundaryChart chartName={"Temperatura do Transformador"}>
                    <TransformerTemperatureChart powerPlant={selectedPowerPlant.id} skidId={selectedSkid.id}/>
                </ErrorBoundaryChart>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ErrorBoundaryChart chartName={"Potência de Inversores"}>
                    <ActivePowerInvertersSkidChart skidId={selectedSkid.id}/>
                </ErrorBoundaryChart>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ErrorBoundaryChart chartName={"Taxa de Geração"}>
                    <GenerationRateOfInvertersChart skidId={selectedSkid.id}/>
                </ErrorBoundaryChart>
            </Grid>
        </Grid>
    );
}
