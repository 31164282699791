import {ChartState} from "../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";

import { useQuery } from "react-query";
import axios from "axios";


export interface EquipmentEntity {
    activePower: number;
    reactivePower: number;
    powerPerUnit: number;
    equipmentId: number;
    equipmentName: string;
    value: number;
    dateTime: string;
    irradiance: number;
    powerStationName: string;
    skidName: string;
}

interface Response {
    message: string;
    data: Array<EquipmentEntity>
}

const apiUrl = 'api/power-per-unit/overview';

const fetchEquipments = async (period: any, unity: string, powerStationId:number): Promise<Response> =>{
    return  await axios
        .get<Response>(`${apiUrl}/${powerStationId}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}&grouping=${unity}`,{timeout:30000})
        .then((res) => res.data)
}

const ActivePowerReactQuery = (period: any, unity: string, powerStationId:number) => {
    return useQuery({
        queryKey: ['data', period, unity, powerStationId],
        queryFn: () => fetchEquipments(period, unity, powerStationId),
        refetchInterval: 60000,
    })
}
export default ActivePowerReactQuery;
