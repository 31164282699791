import React, {useEffect, useState} from "react";
import SynopticChart from "./SynopticChart";
import {SynopticData, clear, getEntity, clearEntity} from "./SynopticChartReducer";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import {Grid} from "@mui/material";
import {ACard} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import useInterval from "../../../Shared/Hooks/useInterval";

export default function SynopticPage() {
    const entity: SynopticData = useAppSelector((state: RootState) => state.synopticChart.entity);
    const dispatch = useAppDispatch();
    const { periodSynoptic, errorMessage } = useAppSelector((state : RootState) => state.synopticChart);
    const entitiesDashboard = useAppSelector(
        (state: RootState) => state.dashboardNavigationMenu.entities
    );
    const [actualIndex, setActualIndex] = useState(-1);
    const [powerStationList, setPowerStationList] = useState<Array<SynopticData>>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(clear());
        dispatch(clearEntity());

        if(entitiesDashboard && entitiesDashboard.length > 0 && periodSynoptic && !periodSynoptic.fromDateTime.toString().includes("999Z")){
            let psList = entitiesDashboard.map((ps) => {
                return {id: ps.id, powerStationName: ps.name, etsStatus: "", activePower: 0, generationRate: 0, irradiance: 0, generatedEnergy: 0, expectedIrradiance: 0, expectedInjectedEnergy: 0, loading: true}
            });
            setPowerStationList(psList);
            setActualIndex(0);
        }
    },[periodSynoptic]);

    useEffect(() => {
        if(actualIndex > -1){
            if(entitiesDashboard && entitiesDashboard.length > 0 && actualIndex < entitiesDashboard.length){
                dispatch(getEntity({period: periodSynoptic, powerStationId: entitiesDashboard[actualIndex].id}))
                    .then(() => {
                        setActualIndex(actualIndex + 1);
                    })
            }
        }
    }, [actualIndex]);

    useInterval(() => {
        setActualIndex(0);
    }, 60000); //1 minute

    useEffect(() => {
        if(entity){
            setPowerStationList(prevList => prevList.map(item => {
                if(item.id === entity.id && item.powerStationName === entity.powerStationName){
                    return {...entity, loading: false};
                } else {
                    return item;
                }
            }));
        }
    }, [entity, actualIndex]);

    useEffect(() => {
        if(errorMessage){
            setActualIndex(-1);
            enqueueSnackbar(errorMessage, {variant: "error"});
        }
    }, [errorMessage]);

    return(
        <>{powerStationList ?
            <Grid container spacing={2}>
                {powerStationList.map((data,idx) => (
                    <>
                        {
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={3} key={idx}>
                                <SynopticChart synopticData={data} isLoading={data.loading}/>
                            </Grid>
                        }
                    </>
                ))
                }
            </Grid>
            : <Grid container spacing={1}>
                <ACard>
                    Dados não encontrados
                </ACard>
            </Grid>
        }
        </>
    )
}