import {Box} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import React from "react";
import InvertersPage from "./inverters/InverterPage";
import {QueryClient, QueryClientProvider} from "react-query";

export default function DashboardPowerPlantInverterPage(){
    const queryClient = new QueryClient();

    const { selectedPowerPlant } = useAppSelector((state) => state.dashboard);
    return(
        <QueryClientProvider client={queryClient}>
            <Box p={2} style={{paddingBottom: '20px'}}>
                {selectedPowerPlant ? <InvertersPage powerPlant={selectedPowerPlant}/>: null}
            </Box>
        </QueryClientProvider>

    );
}
