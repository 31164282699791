import {StyledRootSkeleton, StyledSkeleton} from './DashboardMenuSkeleton.theme';

const DashboardMenuSkeleton = (): JSX.Element => {
    return (
        <>
            <StyledRootSkeleton animation="wave" variant="rounded" width={250} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
            <StyledSkeleton animation="wave" variant="rounded" width={230} height={20} />
        </>
    )
}

export default DashboardMenuSkeleton;