import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../../Config/Types";

export class TeleObject {
    id: number;
    name: string;
    status: string;
    unit:  string;
    value: number;

    constructor(id: number, name: string,status: string, unit:  string, value: number){
        this.id = id;
        this.name = name;
        this.status = status;
        this.unit= unit;
        this.value = value;
    }
}
export interface SerializedTeleObjectType {
    telesinalList: TeleObject[];
    telemetryList: TeleObject[];
    telecommandList: TeleObject[];
}

export class TeleObjectType {
    telesinalList: TeleObject[];
    telemetryList: TeleObject[];
    telecommandList: TeleObject[];

    constructor(telesinalList: TeleObject[], telemetryList: TeleObject[], telecommandList: TeleObject[]) {
        this.telesinalList = telesinalList;
        this.telemetryList = telemetryList;
        this.telecommandList = telecommandList;
    }

    toSerializableObject(): SerializedTeleObjectType {
        return {
            telesinalList: this.telesinalList,
            telemetryList: this.telemetryList,
            telecommandList: this.telecommandList,
        };
    }
}

interface EquipmentDetailsPlantSkidState extends DefaultState {
    entity: SerializedTeleObjectType | null;
}

const initialState: EquipmentDetailsPlantSkidState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<TeleObjectType>(),
    entity: null,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/equipment/details';
export const getEntities = createAsyncThunk(
    'detail_equipment/fetch_entity_list',
    async (equipmentId) => {
        return axios.get<TeleObjectType>(`${apiUrl}/${equipmentId}`);

    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'detail_equipment/fetch_entity',
    async (equipmentId) => {
       return await axios.get<TeleObjectType>(`${apiUrl}/${equipmentId}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const EquipmentDetailsSkidReducer = createSlice({
    name: 'EquipmentDetailsPlantSkidReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                telesinalList: [],
                telemetryList: [],
                telecommandList: []
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                    entity: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
    },
})

export const { reset,clear } = EquipmentDetailsSkidReducer.actions;
export const selectDetails = (state: RootState) => state.equipmentDetailsSkid;

// Reducer
export default EquipmentDetailsSkidReducer.reducer;