import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import type { NodesElement } from '../../../../Shared/Types/element';

interface State {
    isLoading: boolean;
    errorMessage: string | null;
    entities: NodesElement | undefined | null;
}

const initialState: State= {
    isLoading: false,
    errorMessage: null,
    entities: null,
};

const apiUrl = 'api/nodes';

export const getEntities = createAsyncThunk<any, any>(
    'elements/fetch_entity_list',
    async (id: number) => {
        return axios.get<Element>(`${apiUrl}/power-station/${id}`, {
            timeout: 20000
        });
    },
    { serializeError: serializeAxiosError }
);

const PowerStationElementsReducer = createSlice({
    name: 'PowerStationElementsReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder

            .addMatcher(isFulfilled(getEntities), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities), state => {
                return {
                    ...state,
                    isLoading: true,
                }
            })
            .addMatcher(isRejected(getEntities), state => {
                return {
                    ...state,
                    isLoading: false,
                }
            })
    },
})

export const { reset } = PowerStationElementsReducer.actions;
export default PowerStationElementsReducer.reducer;