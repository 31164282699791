const config = {
  VERSION: process.env.REACT_APP_VERSION,
};

export default config;

export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const AUTHENTICATION_URL = process.env.REACT_APP_AUTHENTICATION_URL;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const VERSION = process.env.REACT_APP_VERSION;
// export const SERVER_API_URL = 'http://10.20.30.50:8090/';

export const AUTHORITIES = {
  "SYSADMIN":"SYSADMIN",
  "VIEW_ACCOUNT":"VIEW_ACCOUNT",
  "SAVE_USER":"SAVE_USER",
  "SEARCH_USERS":"SEARCH_USERS",
  "VIEW_USER":"VIEW_USER",
  "DELETE_USER":"DELETE_USER",
  "SAVE_AUTHORITY":"SAVE_AUTHORITY",
  "SEARCH_AUTHORITIES":"SEARCH_AUTHORITIES",
  "VIEW_AUTHORITIES":"VIEW_AUTHORITIES",
  "DELETE_AUTHORITY":"DELETE_AUTHORITY",
  "SAVE_COUNTRY":"SAVE_COUNTRY",
  "SEARCH_COUNTRIES":"SEARCH_COUNTRIES",
  "VIEW_COUNTRY":"VIEW_COUNTRY",
  "DELETE_COUNTRY":"DELETE_COUNTRY",
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
