import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

export class TemperatureMeasurement {
    teleObjectId: number;
    equipmentId: number;
    equipmentName: string;
    teleObjectConfigName: string;
    dateTime: string[];
    value: number[];


    constructor(  teleObjectId: number, equipmentId: number, equipmentName: string, teleObjectConfigName: string, dateTime: string[], value: number[]) {
        this.teleObjectId = teleObjectId;
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.teleObjectConfigName = teleObjectConfigName;
        this.dateTime = dateTime;
        this.value = value;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<TemperatureMeasurement>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/equipment/transformers/temperature';

export const getEntities = createAsyncThunk<any, any>(
    'transformer temperature/fetch_entity_list',
    async ({period,powerPlant, skidId}) => {
      
        let groupBy:string = 'day';
        if( period.groupBy.includes('day')) {
            groupBy = 'hour';
        }

        return axios.get<TemperatureMeasurement>(`${apiUrl}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}&grouping=hour&powerStationId=${powerPlant}&skidIds=${skidId}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'transformerTemperature/fetch_entity',
    async () => {
        return axios.get<TemperatureMeasurement>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const TransformerTemperatureReducer = createSlice({
    name: 'TransformerTemperatureReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = { name:"", measurement: [] };
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), state => {
                state.errorMessage = "Erro ao carregar dados de Temperatura do Transformador";
                state.loading = false;
            })
    },
})

export const { reset, clear } = TransformerTemperatureReducer.actions;
export const selectTransformerTemperatureChart = (state: RootState) => state.transformerTemperatureChart;

// Reducer
export default TransformerTemperatureReducer.reducer;