export const hasPermission = (authorities, hasAnyAuthorities) => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyAuthorities.length === 0) {
            return true;
        }
        return authorities.filter(item => hasAnyAuthorities.includes(item.name)).length > 0;
    }
    return false;
};

