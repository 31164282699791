import React, {Component, ErrorInfo, ReactNode} from 'react';
import {ACard, APopoverIcon} from "@atiautomacao/ati-ui-library"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFileCsv} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {Box, CardContent, Typography} from '@mui/material';

interface ErrorBoundaryProps {
    children: ReactNode;
    chartName?: string;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundaryChart extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by Error Boundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return  <ACard
                title={this.props.chartName}
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography variant="h6" component="h2">
                                Erro ao carregar o gráfico
                            </Typography>
                        </Box>
                    </CardContent>
                }
            </ACard>;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryChart;