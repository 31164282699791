import {Box} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TrackersPage from "./Trackers/TrackersPage";
import React from "react";

export default function DashboardPowerPlantTrackersPage(){

    const { selectedPowerPlant } = useAppSelector((state) => state.dashboard);
    return(
        <>
            <Box p={2} style={{paddingBottom: '20px'}}>
                {selectedPowerPlant ? <TrackersPage powerPlantId={selectedPowerPlant.id}/> : null}
            </Box>
        </>

    );
}
