import {Grid} from "@mui/material";
import React, {useState} from "react";
import ActivePowerInvertersChart from "../ActivePower/ActivePowerInvertersChart";
import {useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import {ACard, Skeleton} from "@atiautomacao/ati-ui-library";
import {QueryClient, QueryClientProvider} from "react-query";


export default function DashboardOverviewAtivePowerPage(){
    const  {entities} = useAppSelector((state: RootState) => state.dashboardNavigationMenu);

    const [dataFound, setDataFound] = useState(new Set());

    const queryClient = new QueryClient();

    const handleDataFound = (id: number) => {
        if (id) {
            setDataFound((prev) => new Set(prev).add(id));
        }
    };

    return(
        <QueryClientProvider client={queryClient}>
            <Grid container spacing={2}>
                <>
                    {entities && entities.length > 0 ?
                        entities.map((data, index) => (
                            <Grid item xs={12} md={6} lg={4} key={data.id}>
                                {(index === 0 || dataFound.has(entities[index - 1].id)) ?
                                    <ActivePowerInvertersChart onDataFound={handleDataFound} powerStationData={data} />
                                    :
                                    <Skeleton animation="wave" height={350} variant="rounded" width={"100%"} />
                                }
                            </Grid>
                        )) :
                        <Grid item xs={12}>
                            <ACard>
                                Dados não encontrados
                            </ACard>
                        </Grid>
                    }
                </>
            </Grid>
        </QueryClientProvider>
    );
}