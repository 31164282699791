import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../Config/Hooks';
import {getEntities} from './SkidNodesReducer';
import type {RootState} from '../../../../Config/Store';
import {mapElementToNode} from '../../../../Shared/Helpers/mapElementToNode';
import {ACard, FloatMenuButton, FloatMenuItem, APopoverIcon, Flow, Skeleton} from '@atiautomacao/ati-ui-library';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faExpand,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faGear,
    faPrint
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import {useEquipmentNavigation} from '../../../../Shared/Hooks/useEquipmentNavigation';
import {Box, Container} from "@mui/material";
import {Element} from "../../../../Shared/Types/element";
import useInterval from "../../../../Shared/Hooks/useInterval";

interface Props {
    skidId: number;
    powerPlantId: number;
}

const SkidNodes = ({ skidId , powerPlantId}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { entities, isLoading } = useAppSelector((state: RootState) => state.skidNodesReducer);
    const [elementsSkid, setElementsSkid] = useState<any[]>([])
    const navigateToEquipment = useEquipmentNavigation();

    useEffect(() => {
        dispatch(getEntities({skidId, powerPlantId}));
    }, []);

    useInterval(() => {
        dispatch(getEntities({skidId, powerPlantId}));
    }, 60000) // 5 minutes

    useEffect(() => {
        if(entities && entities.elements.length > 0) {
            let skidElements = [...entities.elements];
            skidElements.forEach((elt: any) => {
                if(elt.parentId !== null && !skidElements.find((elm: any) => elm.id === elt.parentId)) {
                    skidElements.push({
                        id: elt.parentId,
                        label: elt.parentId.toUpperCase(),
                        edges: [],
                        leftValues: [],
                        rightValues: [],
                        topValues: [],
                        icon: '',
                        isNavigable: true,
                        status: '',
                        type: 'group',
                        idImage: undefined
                    })
                }
            });

            setElementsSkid(skidElements);
        }
    }, [entities]);

    const handleNodeClick = (id: string, isNavigable: boolean): void => {
        const newID = id?.replace(/\D/g, "")
        if (isNavigable) {
            navigateToEquipment(Number(newID));
        }
    };

    const sortNodeChildren = (node: Element): Element => {
        const sortedEdges = node.edges.map(edge => sortNodeChildren(edge));
        sortedEdges.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
        return { ...node, edges: sortedEdges };
    };

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.sort((a,b) => a.label.localeCompare(b.label)).map(element => sortNodeChildren(element));
    };


    let cardContentToRender: JSX.Element;
    if (isLoading) {
        cardContentToRender = (<Skeleton animation="wave" height={500} variant="rectangular" width="100%" />);
    } else if(entities && elementsSkid && elementsSkid.length > 0) {
        const newEntities = [...elementsSkid]
        cardContentToRender = (
            <div style={{height: '800px', width: '100%'}}>
                <Flow nodes={traverseAndSortTree(newEntities).map(element => mapElementToNode(element, entities.images))} onNodeClick={handleNodeClick} isCreating={false}/>
            </div>
        );
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{ bgcolor: '#e1e0e0', height: '500px', width: '800px' ,alignItems: 'center',
                    justifyContent: 'center', display:'flex'}}>
                    <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum equipamento encontrado</span>
                    </div>
                </Box>

            </Container>
        )
    }

    return (
        <ACard
            title="Unifilar do Skid"
            // headerActions={
            //     <FloatMenuButton
            //         icon={<MoreVertIcon />}
            //         tooltip={"Floating Menu"}
            //     >
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20} />}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20} />}
            //             text="Expand"
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20} />}
            //             text="Print"
            //         />
            //     </FloatMenuButton>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20} />}>
                    <IconButton>
                        <FontAwesomeIcon icon={faFilePdf} fontSize={20} />
                    </IconButton>
                    <IconButton>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                    </IconButton>
                    <IconButton>
                        <FontAwesomeIcon icon={faFileExcel} fontSize={20} />
                    </IconButton>
                </APopoverIcon>
            }
        >
            {cardContentToRender}
        </ACard>
    );
};

export default SkidNodes;
