import axios from "axios";
import {useSnackbar} from "notistack";
import {useMutation, useQuery} from "react-query";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

const apiUrl = "api/teleobject-config";

const fetchPointMapEquipmentClass = async (id: any, pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search/${id}`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const PointMapEquipmentClassReactQuery = (id: any, pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchPointMapEquipmentClass(id, pagedSearchParams);
        }
    });
};

const savePointClass = async (data: any) => {
    try {
        if(data.id) {
            return await axios.put(`${apiUrl}/${data.id}`, data,{timeout:30000}).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const SavePointClassReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return savePointClass(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Ponto salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar ponto de classe: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteClassPoint = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const DeletePointClassReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteClassPoint(id);
        },
        onSuccess: () => {
            enqueueSnackbar("Ponto de classe excluído com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir ponto de classe: ${error.message}`, { variant: "error" });
        }
    })
};
const deleteTeleObjectAlarmCondition = async (id: number) => {
    try {
        return await axios.delete(`api/teleobject-alarm-condition/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching teleObjectAlarmCondition data:", error);
        return Promise.reject(error);
    }
};

export const DeleteTeleObjectAlarmConditionReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteTeleObjectAlarmCondition(id);
        },
        onSuccess: () => {
            enqueueSnackbar("Severidade excluída com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir severidade: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchPointClassById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const FindPointClassByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchPointClassById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um ponto de classe: ${error.message}`, { variant: "error" });
        }
    })
};