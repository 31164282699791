import {Box, Paper, Typography} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";

export default function DashboardPowerPlantSkidEquipmentsOverviewPage(){

    const {selectedPowerPlantId, selectedSkidId} = useAppSelector((state) => state.dashboard);
    return(
        <Paper elevation={3}>
            <Box p={2} style={{height: 600}}>
                <Typography variant="h5" component="h1">Dashboard Power Plant Skid Equipments Overview Page</Typography>
                {selectedPowerPlantId ?
                    <>
                        <br/>
                        <Typography>Power Plant Id: {selectedPowerPlantId}</Typography>
                    </> : null}
                {selectedSkidId ?
                    <>
                        <br/>
                        <Typography>Power Plant Skid Id: {selectedSkidId}</Typography>
                    </> : null}
            </Box>
        </Paper>
    );
}
