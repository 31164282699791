import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';

function not(a: SubHeader[], b: SubHeader[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: SubHeader[], b: SubHeader[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: SubHeader[], b: SubHeader[]) {
    return [...a, ...not(b, a)];
}

export interface SubHeader {
    name: string;
    prop:string;
    checked: boolean;
}

export interface Props {
    data: SubHeader[];
    maxItem:number;
    handleListRight: any
}

export default function SelectAllTransferList({data, maxItem, handleListRight}: Props) {

    const { enqueueSnackbar } = useSnackbar();
    
    const getDataInition = () => {
        return data.slice(0, 14);
    }
     
    const removeDataInition = () => {
        return data.slice(14);
    }
    const [checked, setChecked] = React.useState<SubHeader[]>([]);
    const [left, setLeft] = React.useState<SubHeader[]>(removeDataInition);
    const [right, setRight] = React.useState<SubHeader[]>(getDataInition);
    
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: SubHeader, type: 'left' | 'right') => () => {
        console.log("type------",type);
        if((type === 'left' && (leftChecked.length + right.length) >= maxItem)) {
            enqueueSnackbar("Você atingiu o limite máximo de " + maxItem + " itens no relatório", {variant: 'warning'});
            
        }

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        value.checked = !value.checked;
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items: SubHeader[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: SubHeader[]) => () => {
        items.map(element => {
            element.checked = !element.checked
        })
        if (numberOfChecked(items) === items.length) {

            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
       
        const newLeftCheckList:SubHeader[] = [];
        leftChecked.map(element => {
            element.checked = false;
            newLeftCheckList.push(element);
        });
        setRight(right.concat(newLeftCheckList));
        setLeft(not(left, newLeftCheckList));
        setChecked(not(checked, newLeftCheckList));
    };

    const handleCheckedLeft = () => {
        const newRightCheckList:SubHeader[] = [];
        rightChecked.map(element => {
            element.checked = false;
            newRightCheckList.push(element);
        });
        setLeft(left.concat(newRightCheckList));
        setRight(not(right, newRightCheckList));
        setChecked(not(checked, newRightCheckList));
    };

    const customList = (title: React.ReactNode, items: SubHeader[], type: 'left' | 'right') => (
        <Card variant="outlined" style={{height: '80%', borderWidth: 2 }}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
            />
            <Divider />
            <List
                key={type}
                sx={{
                    height: 530,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: SubHeader) => {
                    const labelId = `transfer-list-all-item-${value + type}-label`;
                    
                    return (
                        <ListItemButton
                            key={`key-${type + '-' + value.name}`}
                            role="listitem"
                            onClick={handleToggle(value, type)}
                        >
                            <ListItemIcon key={value.prop}>
                                <Checkbox
                                    checked={checked.indexOf(value) != null && checked[checked.indexOf(value)]?.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItemButton>
                    );
                })}
            </List>
        
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            {/* <Grid item xs={12} textAlign={'center'} ><Typography variant='h4'>Selecione {maxItem} item para gerar o relatório</Typography></Grid> */}

            <Grid item xs={12} md={4} lg={4} xl={4}>{customList('Gerenciais', left, 'left')}</Grid>
            <Grid item xs={12} md={2} lg={2} xl={2}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0 || (leftChecked.length+right.length > maxItem)}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>{customList('Relatório Final', right, 'right')}</Grid>

            <Grid item xs={12} style={{ display: 'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                <Box style={{ width: 200}}>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="contained"
                        size="small"
                        onClick={() => handleListRight(right)}
                        disabled={right.length != maxItem}
                        title={`Selecione ${maxItem} itens para gerar o relatório`}
                        aria-label="move selected right"
                    >
                        Gerar relatório
                    </Button>
                </Box>
                
            </Grid>
        </Grid>
    );
}