import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";


const apiUrl = "api/modBusObjectsTypes";

const fetchModBusObjectType = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(apiUrl + "/search", {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const ModBusObjectTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchModBusObjectType(pagedSearchParams);
        }
    });
};

const saveModBusObjectType = async (data: any) => {
    try {
        if(data.id){
            return await axios.put(`${apiUrl}/${data.id}`, data).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error saving data:", error);
        return Promise.reject(error);
    }
};

export const SaveModBusObjectTypeReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveModBusObjectType(data);
        },
        onSuccess: () => {
            enqueueSnackbar("ModBusObjectType salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar ModBusObjectType: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteModBusObjectType = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteModBusObjectTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteModBusObjectType(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Tipo de Objeto excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir Tipo de Objeto: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchModBusObjectTypeById = async (id: number) => {
    return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
};

export const FindModBusObjectTypeByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchModBusObjectTypeById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um ModBusObjectType: ${error.message}`, { variant: "error" });
        }
    })
};