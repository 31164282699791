import React, {FunctionComponent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {GenerationRateInverter, getEntities} from "./types/GenerationRateOfInvertersReducer";
import {RootState} from "../../../../Config/Store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import {truncateNumber} from "../../../../Utils/NumberUtil";
import IconButton from "@mui/material/IconButton";
import {ACard, AChartX, Period, PeriodSelector, Skeleton, FloatMenuItem, APopoverIcon, YAxis, XAxis} from "@atiautomacao/ati-ui-library";
import {format} from "date-fns";
import {checkDiffDays} from "../../../../Utils/DataUitils";
import DataNotFound from "../../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../../Shared/Hooks/useInterval";
import {Button} from "@mui/material";
import ColorInformation, {NewHtmlTooltip} from "../../../../Utils/ColorInformation";

const GenerationRateOfInvertersChart: FunctionComponent<{ skidId: any }> = ({skidId}) => {

    const dispatch = useAppDispatch();
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const generationRateInverter: Array<GenerationRateInverter> = useAppSelector((state: RootState) => state.generationRateByInverter.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationRateByInverter);

    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);
    const [seriesData, setSeriesData] = useState<any[]>([])
    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<YAxis>();

    const formatCaption = (fromDateTime: Date, toDateTime: Date): string => {
        let dateIntervalOfCaption;
        if (period.groupBy && (period.groupBy.valueOf() === "none" || period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month")) {
            dateIntervalOfCaption = format(fromDateTime, 'dd/MM') + ' a ' + format(toDateTime, 'dd/MM')
        } else {
            dateIntervalOfCaption = format(fromDateTime, 'HH:mm') + ' a ' + format(toDateTime, 'HH:mm')
        }

        return dateIntervalOfCaption;
    }

    const dateByPeriod = (): string => {
        let fromDateTime = new Date();
        let toDateTime = new Date();
        let captionData: string;

        if (period.fromDateTime) {
            fromDateTime = period.fromDateTime;
        }
        if (period.toDateTime) {
            toDateTime = period.toDateTime;
        }

        captionData = formatCaption(fromDateTime, toDateTime);
        return captionData;
    }
    useEffect(() => {
        setSeriesData(Array.isArray(generationRateInverter)
            ? generationRateInverter.map(item => {
                let truncatedRate;
                truncatedRate = item.generationRate;
                return item.generationRate !== undefined && item.generationRate !== null
                    ? truncateNumber(truncatedRate, 2)
                    : '-';
            })
            : []
        )
        setXAxis({
            type: 'category',
            data: Array.isArray(generationRateInverter)
                ? generationRateInverter.map(item => {
                    if (item.equipmentName !== undefined && item.equipmentName !== null) {
                        return item.equipmentName;
                    }else{
                        return ""
                    }
                })
                : [],

        })

        setYAxis({
            type: 'value',
            min: 0,
            max: yAxisMax,
            interval: truncateNumber(yAxisMax / 5),
            axisLabel: {
                formatter: function (value: any) {
                    return value.toFixed(2);
                },
            },
        })
    }, [generationRateInverter]);

    useEffect(() => {
        setSeriesData([])
    }, [skidId, period]);

    const maxAxisY = Math.max(
        ...(generationRateInverter || []).map(item => item.generationRate ?? 0)
    );

    const yAxisMax = maxAxisY > 100 ? 120 : 100;


    const datePeriodTime: any = dateByPeriod();


    const chartOption: any = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value: any) => `${value} %`,
        },
        toolbox: {
            feature: {
                dataView: {show: false, readOnly: false},
                magicType: {show: false, type: ['line', 'bar']},
                restore: {show: false},
                saveAsImage: {show: false}
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        legend: {
            show: true,
            orient: 'vertical',
            right: 45,
            top: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1,
                color: function (params: any) {
                    const value = params.data >= 0 ? params.data : 0;
                    console.log("value")
                    console.log(value)
                    if (value < 60) {
                        return 'red';
                    } else if (value >= 60 && value <= 80) {
                        return 'yellow';
                    } else {
                        return 'green';
                    }
                },
            },
            data: ['Taxa de Geração']
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: {
            name: 'Taxa de Geração',
            data: seriesData,
            type: 'bar',
            unity: '%',
            date: datePeriodTime,
            itemStyle: {
                color: function (params: any) {
                    const value = params.data;
                    if (value < 60) {
                        return '#E56C6C';
                    } else if (value >= 60 && value <= 80) {
                        return '#F9D37F';
                    } else {
                        return '#91CC75';
                    }
                }
            }
        }
    };



    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime)){
        dispatch(getEntities({period: period, skidId: skidId}));
        }
    }, [dispatch, period, skidId]);

    useInterval(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime)){
            dispatch(getEntities({period: period, skidId: skidId}));
        }
    }, 60000) // 5 minutes


    let content;

    if (loading) {
        content = <Skeleton animation="wave" height={289} variant="rounded" width="100%" />;
    } else if (generationRateInverter) {
        content = <AChartX height={289} width="100%" option={chartOption} />;
    }


    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 22, left: 160 }}>
                <NewHtmlTooltip
                    title={
                        <ColorInformation />
                    }
                >
                    <Button>HTML</Button>
                </NewHtmlTooltip>
            </div>
            <ACard
                key={`chart-skid-ration-${openSubMenu}`}
                headerControlsPosition={"header"}
                cardStyle={{ height: 445 }}
                contentStyle={{ padding: 0 }}
                title="Taxa de Geração"
                headerControls={
                    <PeriodSelector
                        styleProps={{ base: { flexDirection: "row", display: "flex", alignItems: "center" } }}
                        mode="hybrid"
                        hideDatetime={false}
                        hideGroup={false}
                        disableHourSelector={true}
                        inputFormat={'dd/MM/yyyy'}
                        periodInterval={{
                            day: { date: period.toDateTime, startTime: "00:00:00", endTime: "23:59:59" },
                            week: { startTime: "00:00:00", endTime: "23:59:59", startInterval: 6 },
                            month: { startTime: "00:00:00", endTime: "23:59:59", startInterval: 30 }
                        }}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["day", "week", "month"]}
                    />
                }
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20} />}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20} />}
                //             text="Expand"
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20} />}
                //             text="Print"
                //         />
                //     </>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20} />}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                {seriesData && seriesData.length > 0 ? content :
                    <DataNotFound boxStyle={{ height: 289, width: '100%' }} />}
            </ACard>
        </div>
    );

};

export default GenerationRateOfInvertersChart;