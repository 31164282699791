import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";

const apiUrl = "api/teleObjectTypes";

const fetchTeleObjectType = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(apiUrl + "/search", {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const TeleObjectTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchTeleObjectType(pagedSearchParams);
        }
    });
};

const saveTeleObjectType = async (data: any) => {
    try {
        if(data.id){
            return await axios.put(apiUrl, data,{timeout:30000}).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error saving data:", error);
        throw error;
    }
};

export const SaveTeleObjectTypeReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveTeleObjectType(data);
        },
        onSuccess: () => {
            enqueueSnackbar("TeleObjectType saved successfully!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Error saving TeleObjectType: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteTeleObjectType = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const DeleteTeleObjectTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteTeleObjectType(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Tipo Específico excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir tipo de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchTeleObjectTypeById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const FindTeleObjectTypeByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchTeleObjectTypeById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Error loading TeleObjectType: ${error.message}`, { variant: "error" });
        }
    })
};