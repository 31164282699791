import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DashboardLayoutState, HeaderMenuButtons} from "./Dashboard";
import {LayoutMode} from "../../Config/Reducers/LayoutReducer";

const initialState: DashboardLayoutState = {
    mode: LayoutMode.Desktop,
    header: {
        open: true,
        buttons: {
            layoutButton: true,
            chartsButton: true,
            mapButton: false,
            alarmsButton: true,
            equipmentsButton: false,
            synopticButton: true,
            activePowerButton: true,
            trackersButton: true,
            invertersButton: true
        },
        marginLeft: 20
    },
    subHeader: {
        open: false
    }
};

// Slice / Reducer
const DashboardLayoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {

        // Actions
        showDashboardHeaderMenu :(state:DashboardLayoutState) => {
            state.header.open = true;
        },
        hideDashboardHeaderMenu :(state:DashboardLayoutState) => {
            state.header.open = false;
        },
        showDashboardSubHeaderMenu :(state:DashboardLayoutState) => {
            state.subHeader.open = true;
        },
        hideDashboardSubHeaderMenu :(state:DashboardLayoutState) => {
            state.subHeader.open = false;
        },

        setDashboardHeaderMenuButtons :(state, action) => {
            Object.assign(
                state.header,
                {
                    buttons: {
                        layoutButton: action.payload.layoutButton,
                        chartsButton: action.payload.chartsButton,
                        mapButton: action.payload.mapButton,
                        alarmsButton: action.payload.alarmsButton,
                        equipmentsButton: action.payload.equipmentsButton,
                        synopticButton: action.payload.synopticButton,
                        activePowerButton: action.payload.activePowerButton,
                        trackersButton: action.payload.trackersButton,
                        invertersButton: action.payload.invertersButton
                    }
                }
            );
        },

        setDashboardHeaderSubMenuButtonsState :(state:DashboardLayoutState) => {
            Object.assign(state.subHeader, {open: true});
        },

        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    }
})

export const {
    reset,
    setDashboardHeaderSubMenuButtonsState,
    showDashboardHeaderMenu,
    showDashboardSubHeaderMenu,
    setDashboardHeaderMenuButtons,
    hideDashboardHeaderMenu,
    hideDashboardSubHeaderMenu
} = DashboardLayoutSlice.actions;

// Reducer
export default DashboardLayoutSlice.reducer;
