import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../Config/Types";
import {Period} from "@atiautomacao/ati-ui-library";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";

export class Generation {

    dateTime: string;
    expectedInjectedEnergy: number;
    generatedEnergy: number;

    constructor(dateTime: string, generatedEnergy: number, expectedInjectedEnergy: number ) {
        this.dateTime = dateTime;
        this.generatedEnergy = generatedEnergy;
        this.expectedInjectedEnergy = expectedInjectedEnergy;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<Generation>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/generation-energy/';


// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk(
    'generation energy/fetch_entity_list',
    async (period: Period) => {
        let groupBy:string = 'day';
        if( period.groupBy.includes('week') ||  period.groupBy.includes('month')){
            groupBy = 'day';
        } else if( period.groupBy.includes('year')) {
            groupBy = 'month';
        } else if( period.groupBy.includes('general')){
            groupBy = 'year';
        }
        return axios.get<Generation>(`${apiUrl}${groupBy}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation/fetch_entity',
    async () => {
        return axios.get<Generation>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const generationEnergyChartReducer = createSlice({
    name: 'generationEnergyChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {dateTime:"",generatedEnergy:0, expectedInjectedEnergy: 0};
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), (state: ChartState, action) => {
                state.loading = false;
                state.errorMessage = "Erro ao carregar dados de Fator de Capacidade";
            })
    },
})

export const { reset, clear } = generationEnergyChartReducer.actions;
export const selectGenerationEnergyChart = (state: RootState) => state.generationEnergyChart;

// Reducer
export default generationEnergyChartReducer.reducer;