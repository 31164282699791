import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setPowerPlant} from '../../Modules/Dashboard/DashboardReducer';
import {RootState} from '../../Config/Store';
import {useAppSelector} from '../../Config/Hooks';
import {useCallback, useEffect, useState} from 'react';

export const usePowerStationNavigationToAlarmsPage = (): (powerStationId: number) => void => {
    const [newPowerStation, setNewPowerStation] = useState<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appState = useAppSelector((state: RootState) => state)
    const { entities } = appState.dashboardNavigationMenu;
    const { selectedPowerPlant } = appState.dashboard;

    useEffect(() => {
        if (newPowerStation) {
            dispatch(setPowerPlant(newPowerStation));
        }
    }, [newPowerStation, dispatch]);

    useEffect(() => {
        if (newPowerStation && (selectedPowerPlant.id === newPowerStation.id)) {
            navigate(`/dashboard/power-plant/alarms`);
        }
    }, [selectedPowerPlant, newPowerStation, navigate])

    return useCallback((powerStationId: number) => {
        if (!powerStationId) {
            return;
        }

        const [powerStation] = entities.filter(entity => entity.id === powerStationId);

        if (!powerStation) {
            return;
        }


        const updatedPowerStation = {...powerStation, uniqueKey: Date.now()}; //unique key, this case is a date in the momemnt exactly
        setNewPowerStation(updatedPowerStation);
    }, [entities]);

}