import React, {MouseEvent, ReactElement, useCallback, useEffect, useState} from "react";

import {
    ACard,
    ADataGrid,
    ADataGridFilter,
    FloatMenuItem,
    APopoverIcon,
    PagedSearchParams,
    Period,
    PeriodSelector
} from "@atiautomacao/ati-ui-library";
import {Box, Button, LinearProgress, linearProgressClasses, Link, styled, Typography, useTheme} from "@mui/material";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {clear, Performance, searchEntities} from "./PerformanceAnalysisReducer";
import GeneratedInjectedChart from "./GeneratedInjectedChart";
import IrradianceChart from "./IrradianceChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faDownload,
    faExpand,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faGear,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import {usePowerStationNavigation} from '../../../Shared/Hooks/usePowerStationNavigation';
import IconButton from "@mui/material/IconButton";
import {getIconColor} from "../../../Shared/Components/Layout/menu/helpers/getIconColor";
import {MenuItemStatus} from "../../../Shared/Components/Layout/menu/types/MenuItemStatus";
import {performanceAnalysisColumns} from "./PerformanceAnalysisConfig";
import themeDefaults from "../../../Shared/Theme/ThemeDefaults";
import useInterval from "../../../Shared/Hooks/useInterval";

const PerformanceAnalysisPage = () : ReactElement => {
    const theme = useTheme();
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);

    const dispatch = useAppDispatch();
    const entities: Array<Performance> = useAppSelector((state: RootState) => state.performanceAnalysis.entities);
    const totalOfRecords = useAppSelector((state : RootState) => state.performanceAnalysis.totalOfRecords);
    const navigationToPowerStation = usePowerStationNavigation();

    const [rows, setRows] = useState<Array<any>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(performanceAnalysisColumns), 0, 10));
    const [clicked, setClicked] = useState(false);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const statusColor = (status: MenuItemStatus) => {
        const color = getIconColor(status)
        return <div style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            margin: "10px",
            float: "left",
            background: color
        }} title={status}>
        </div>
    }

    const verifyProgress = (value: number) => {
        if (value >= 70 && value <= 95) {
            return "#a4f657"
        }else if (value >= 60 || value > 95) {
            return "#FFD700";
        }else if (value < 60 ) {
            return "#FF4500"
        }
    };

    const handleNavigation = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStation(powerPlantId);
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: verifyProgress(value ? value : 0),
        },
    }));
    function irradianceSum (element: number) : number | string {
        return element ? parseFloat(element.toString()).toFixed(2) : " ";
    }
    function performanceRatio(element: number) : string{
        return element ? parseInt(element.toString()) + "%" : " ";
    }

    const GeneratedInjected = (generated:number, injected:number) => {
        return(
            <Box  >
                <Typography variant="subtitle1" fontSize={12} color={"#767676"}>Gerada: <label style={{color: "#000"}}>{generated ? `${generated.toFixed(2) + " MWh "}` : " "} </label></Typography>
                <Typography variant="subtitle1" fontSize={12} color={"#767676"}>Injetada: <label style={{color: "#000"}}>{injected ? `${injected.toFixed(2) + " MWh "}` : " "} </label></Typography>
            </Box>
        )
    }

    const structureRow = (entitiesValues: Array<Performance>) => {

        let entityAlarms: Array<any> = []
        entitiesValues.forEach( element => {
            entityAlarms.push({
                generatedEnergy: element.generatedEnergyList,
                irradianceSum:  clicked &&  Array.isArray(element.irradianceList) ? <div style={{width: "80%", marginBottom: "-17px"}}> <IrradianceChart dataIrradiance={element.irradianceList}/></div>  : irradianceSum(element.irradianceSum),
                powerStationName: <Link href="#" onClick={(event) => handleNavigation(event, element.powerStationId)} underline="hover">{element.powerStationName}</Link>,
                powerCapacityNominal: element.powerCapacityNominal ? parseInt(element.powerCapacityNominal.toString()) : " ",
                performanceRatio: clicked ? <BorderLinearProgress variant="determinate" value={parseInt(element.performanceRatio.toString())} title={parseInt(element.performanceRatio.toString()) + "%"} /> : performanceRatio(element.performanceRatio) ,
                instantPower: element.instantPower ? parseFloat(element.instantPower.toString()).toFixed(2) : " ",
                generatedEnergySum: clicked && element.generatedEnergyList.length > 0 && element.injectedEnergyList.length > 0 ?
                    <div style={{width: '80%', marginBottom: "-16px"}}>
                        <GeneratedInjectedChart dataGenerated={element.generatedEnergyList} dataInjected={element.injectedEnergyList}/>
                    </div> :
                    !clicked && GeneratedInjected(
                        element.generatedEnergySum,
                        element.injectedEnergySum
                    ),
                availability: element.availability ? parseInt(element.availability.toString()) + " %" : " ",
                status: statusColor(element.status),
            });
        });
        setRows(entityAlarms);
    }

    useEffect(() => {
        dispatch(searchEntities({period: period, params: pagedSearchParams}));
    }, [pagedSearchParams, period, clicked])

    useInterval(() => {
        dispatch(searchEntities({period: period, params: pagedSearchParams}));
    }, 60000) // 5 minutes

    useEffect(() => {
        if(entities && entities.length > 0){
            structureRow(entities);
        }
    }, [entities, clicked])

    useEffect(() => {
        dispatch(clear());
        setRows([])
    }, []);
    return (
        <ACard
            theme={theme}
            title="Análise de Desempenho"
            headerStyle={{fontSize: 19}}
            headerControlsPosition={"header"}
            headerControls={
                <>
                    <IconButton title={"Gráficos"} color="primary" onClick={() => setClicked(!clicked)}>
                        <FontAwesomeIcon icon={faChartLine} />
                    </IconButton>
                    <PeriodSelector
                        styleProps={{base: {flexDirection: "row", display: "flex", marginTop:3, alignItems: "center"}}}
                        mode="hybrid"
                        hideDatetime={false}
                        disableHourSelector={true}
                        inputFormat={'dd/MM/yyyy'}
                        hideGroup={false}
                        periodInterval={{
                            week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                        }}
                        period={period}
                        onChange={setPeriod}
                        groupByOptions={["week"]}/></>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFilePdf} fontSize={20}/>
                    </IconButton>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                    </IconButton>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileExcel} fontSize={20}/>
                    </IconButton>
                </APopoverIcon>
            }
        >
            <ADataGrid
                tableContainerStyle={{borderRadius: themeDefaults.borderRadius}}
                hideFilters={true}
                hideSelection={true}
                showFilterOptions={false}
                size={"small"}
                columns={performanceAnalysisColumns}
                rows={rows}
                headerStyle={{ backgroundColor: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize' }}
                page={pagedSearchParams.page}
                loading={false}
                totalOfRecords={totalOfRecords? totalOfRecords : 0}
                rowsPerPage={pagedSearchParams.size}
                onFiltersChange={handleOnFiltersChange}
            />
        </ACard>
    )

}

export default PerformanceAnalysisPage;