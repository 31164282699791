import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import { LayoutPowerStationMapState } from "../../Skid/TotalizerPlantSkid/types/layoutPowerStationMapState";
import {LayoutPowerStationMap} from "../../Skid/TotalizerPlantSkid/types/layoutPowerStationMap";
const initialState: LayoutPowerStationMapState = {
    isLoading: false,
    errorMessage: null,
    infoPowerStation: {
        powerStationName: '',
        latitude: 0,
        longitude: 0,
        etsStatus: ''
    },
};

const apiUrl = 'api/power-station/maps';

export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'MapPowerStation/fetch_entity',
    async ({ powerStationId }) => {
        return axios.get<LayoutPowerStationMap>(`${apiUrl}/${powerStationId}`);
    },
    { serializeError: serializeAxiosError }
);

const MapPowerStationReducer = createSlice({
    name: 'MapPowerStationReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(getEntity), (state, action) => {
                return {
                    ...state,
                    isLoading: false,
                    infoPowerStation: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntity), state => {
                state.errorMessage = null;
                state.isLoading = true;
            })
    },
})

export const { reset } = MapPowerStationReducer.actions;

export const selectPowerStationMap = (state: RootState) => state.powerStationMap;

//Reducer
export default MapPowerStationReducer.reducer;
