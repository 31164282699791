import {
    includesSettingsCountryPage,
    includesSettingsProfilesPage,
    isAnalysisPage,
    isDashboardOverviewPage,
    isDashboardPage,
    isDashboardPowerPlantOverviewPage,
    isDashboardPowerPlantEquipmentComparePage,
    isDashboardPowerPlantEquipmentDetailsPage,
    isDashboardPowerPlantSkidEquipmentComparePage,
    isDashboardPowerPlantSkidEquipmentDetailsPage,
    isDashboardPowerPlantSkidEquipmentPage,
    isDashboardPowerPlantSkidOverviewPage,
    isHelpPage,
    isIncludesAnalysisHomePage,
    isReportsPage,
    isSettingsCountryPage,
    isSettingsPage,
    isSettingsProfilesPage
} from "../../Utils/RouteUtils";
import {
    setLayoutHeaderMenuClosed,
    setLayoutHeaderMenuOpen,
    setLayoutSubHeaderMenuClosed,
    setLayoutSubHeaderMenuOpen
} from "../Reducers/LayoutReducer";
import {
    hideDashboardHeaderMenu,
    hideDashboardSubHeaderMenu,
    setDashboardHeaderMenuButtons,
    showDashboardHeaderMenu,
    showDashboardSubHeaderMenu
} from "../../Modules/Dashboard/DashboardMenuReducer";
import {setCountryHeaderMenuButtons, showCountryHeaderMenu} from "../../Modules/Settings/Country/CountryMenuReducer";
import {
    setProfilesHeaderMenuButtons,
    showProfilesHeaderMenu
} from "../../Modules/Settings/Profiles/ProfilesMenuReducer";
import {useAppDispatch, useAppSelector} from "../Hooks";

export default function useAppLayoutSetup() {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const dispatch = useAppDispatch();

    const setup = () => {
        if (isAuthenticated) {

            if (isDashboardPage()) {
                dispatch(setLayoutHeaderMenuOpen());
                dispatch(showDashboardHeaderMenu());

                // Dashboard Overview Pages
                if(isDashboardOverviewPage()) {
                    dispatch(setDashboardHeaderMenuButtons({
                            chartsButton: true,
                            layoutButton: false,
                            mapButton: true,
                            equipmentsButton: false,
                            alarmsButton: true,
                            synopticButton: true,
                            activePowerButton: true,
                            trackersButton: false
                        }
                    ));
                    dispatch(hideDashboardSubHeaderMenu());
                }

                // Power Plant Overview Pages
                if(isDashboardPowerPlantOverviewPage()) {
                    dispatch(setDashboardHeaderMenuButtons({
                            chartsButton: true,
                            layoutButton: true,
                            mapButton: true,
                            equipmentsButton: false,
                            alarmsButton: true,
                            trackersButton: true,
                            invertersButton: true
                        }
                    ));
                }

                // Power Plant Skip Overview Pages
                if(isDashboardPowerPlantSkidOverviewPage()) {
                    dispatch(hideDashboardSubHeaderMenu());
                    dispatch(setDashboardHeaderMenuButtons({
                            chartsButton: true,
                            layoutButton: true,
                            mapButton: false,
                            equipmentsButton: true,
                            alarmsButton: true,
                            trackersButton: false,
                            invertersButton: false
                        }
                    ));
                }

                if(isDashboardPowerPlantSkidEquipmentPage()){
                    dispatch(setLayoutSubHeaderMenuOpen());
                    dispatch(showDashboardSubHeaderMenu());
                    dispatch(setDashboardHeaderMenuButtons({
                            chartsButton: true,
                            layoutButton: true,
                            mapButton: false,
                            equipmentsButton: true,
                            alarmsButton: true,
                            trackersButton: false,
                            invertersButton: false
                        }
                    ));
                }

                if(!isDashboardPowerPlantSkidEquipmentPage()){
                    dispatch(setLayoutSubHeaderMenuClosed());
                    dispatch(hideDashboardSubHeaderMenu());
                }

                if(isDashboardPowerPlantSkidEquipmentDetailsPage() || isDashboardPowerPlantSkidEquipmentComparePage()){
                    dispatch(hideDashboardHeaderMenu());
                    dispatch(hideDashboardSubHeaderMenu());
                }

                if(isDashboardPowerPlantEquipmentDetailsPage() || isDashboardPowerPlantEquipmentComparePage()){
                    dispatch(hideDashboardHeaderMenu());
                    dispatch(hideDashboardSubHeaderMenu());
                }
            }

            if(isIncludesAnalysisHomePage()) {
                dispatch(hideDashboardSubHeaderMenu());
                dispatch(hideDashboardHeaderMenu());
                dispatch(setLayoutSubHeaderMenuClosed());
                dispatch(setLayoutHeaderMenuClosed());
            }

            if(isAnalysisPage()) {
                dispatch(hideDashboardSubHeaderMenu());
                dispatch(hideDashboardHeaderMenu());
                dispatch(setLayoutSubHeaderMenuClosed());
                dispatch(setLayoutHeaderMenuClosed());
            }

            if(isSettingsPage()){

                dispatch(hideDashboardSubHeaderMenu());
                dispatch(hideDashboardHeaderMenu());
                dispatch(setLayoutSubHeaderMenuClosed());
                dispatch(setLayoutHeaderMenuClosed());

                // Country page config
                if(isSettingsCountryPage() || includesSettingsCountryPage()) {
                    dispatch(setLayoutHeaderMenuOpen());
                    dispatch(showCountryHeaderMenu());
                    dispatch(setCountryHeaderMenuButtons({
                            newRecord: true,
                        }
                    ));
                }

                // Profiles page config
                if(isSettingsProfilesPage() || includesSettingsProfilesPage()) {
                    dispatch(setLayoutHeaderMenuOpen());
                    dispatch(showProfilesHeaderMenu());
                    dispatch(setProfilesHeaderMenuButtons({
                            newRecord: true,
                        }
                    ));
                }
            }

            if(isReportsPage()) {
                dispatch(hideDashboardSubHeaderMenu());
                dispatch(hideDashboardHeaderMenu());
                dispatch(setLayoutSubHeaderMenuClosed());
                dispatch(setLayoutHeaderMenuClosed());
            }

            if(isHelpPage()) {
                dispatch(hideDashboardSubHeaderMenu());
                dispatch(hideDashboardHeaderMenu());
                dispatch(setLayoutSubHeaderMenuClosed());
                dispatch(setLayoutHeaderMenuClosed());
            }

        }
    }

    return setup;
}


