import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {AppBar, Button} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {useAppSelector} from "../../../../Config/Hooks";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import {Period, PeriodSelector} from "@atiautomacao/ati-ui-library";


interface Props {
    onPeriod?: any,
    onClick?: any
}

const periodInterval = {
    week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
    month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
    year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
}

export const HeaderMenu: FunctionComponent<Props> = ({ onClick, onPeriod}: Props) => {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const [period, setPeriod] = useState<Period>(initialPeriod);

    const insertChart = () => {
        onClick()
    }

    const setMediumStyle = () => {
        if(!isAuthenticated){
            return 'initial'
        }
        if(openSubMenu){
            if(openMainMenu){
                return `${400}px`
            }
            return `${80}px`
        }
        if(openMainMenu){
            return `${80}px`
        }
        return `${0}`
    }

    useEffect(() => {
        if (onPeriod) {
            onPeriod(period);
        }
    }, [period, onPeriod]);

    if (isAuthenticated ) return (
        <AppBar style={{top: 64}}>
            <Toolbar
                sx={{
                    display: 'flex', // Define flexbox layout
                    justifyContent: 'flex-end', // Alinha tudo à direita
                    marginLeft: {xs: "initial", md: setMediumStyle()},
                    backgroundColor: "white"
                }}
            >
                <>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <PeriodSelector
                            styleProps={{base: {display: "flex", flexDirection: "row", alignItems: "center"}}}
                            mode="hybrid"
                            hideDatetime={false}
                            hideGroup={false}
                            periodInterval={periodInterval}
                            period={period}
                            onChange={setPeriod}
                            groupByOptions={["day", "week", "month", "year", "general"]}
                        />
                        <Button
                            variant="contained"
                            size={"small"}
                            color={"primary"}
                            endIcon={<InsertChartIcon />}
                            onClick={insertChart}
                            sx={{ marginLeft: 2, textTransform: 'capitalize', height: '27px' }} // Espaçamento entre os dois componentes
                        >
                            Gráficos
                        </Button>
                    </div>
                </>
            </Toolbar>
        </AppBar>
    );

    return null;
}