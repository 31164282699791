import {Box} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TotalizerPlantSkid from "./TotalizerPlantSkid/TotalizerPlantSkid";
import SkidNodes from "./SkidNodes/SkidNodes";

export default function DashboardPowerPlantSkidLayoutPage(){

    const { selectedSkid, selectedPowerPlant } = useAppSelector((state) => state.dashboard);
    return(
        <>
            <Box p={2} sx={{display: "none"}}>
                <TotalizerPlantSkid skidId={selectedSkid.id}/>
            </Box>
            <Box p={2} style={{height: '800px'}}>
                <SkidNodes skidId={selectedSkid.id} powerPlantId={selectedPowerPlant.id} />,
            </Box>
        </>

    );
}
