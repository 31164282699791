import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";

export class EquipmentEntity {
    activePower: number;
    reactivePower: number;
    powerPerUnit: number;
    equipmentId: number;
    equipmentName: string;
    value: number;
    dateTime: string;
    powerStationName: string;
    skidName: string;
    irradiance?: number;

    constructor( activePower: number, reactivePower: number, powerPerUnit: number, equipmentId: number, equipmentName: string, value: number, dateTime: string, powerStationName: string, skidName: string, irradiance?: number) {
        this.activePower = activePower;
        this.reactivePower = reactivePower;
        this.powerPerUnit = powerPerUnit;
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.value = value;
        this.dateTime = dateTime;
        this.powerStationName = powerStationName;
        this.skidName = skidName;
        this.irradiance = irradiance;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<EquipmentEntity>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-per-unit/overview';
///api/power-per-unit/overview/120?startDateTime=2023-10-01T00:00:00&endDateTime=2023-10-01T23:59:00
// //startDateTime=${LocalDateTimeFormatISO(new Date())}&endDateTime=${LocalDateTimeFormatISO(new Date())}`);
export const getEntities = createAsyncThunk<any,any>(
    'equipment_entity/entity_list',
    async ({period, unity, powerStationId}) => {
        let groupBy:string = 'day';
        if( period.groupBy.includes('day')) {
            groupBy = 'hour';
        }
        return axios.get<EquipmentEntity>(`${apiUrl}/${powerStationId}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}&grouping=${unity}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'equipment_entity/entity',
    async () => {
        return axios.get<EquipmentEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const activePowerInvertersChartReducer = createSlice({
    name: 'activePowerInvertersSkidChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = { activePower: "",
                reactivePower: " ",
                powerPerUnit:" ",
                equipmentId: " ",
                equipmentName: " ",
                value: " ",
                dateTime: "",
                powerStationName: " ",
                skidName: " "};
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
    },
})

export const { reset, clear } = activePowerInvertersChartReducer.actions;
export const selectActivePowerChartReducer = (state: RootState) => state.activePowerInvertersChart;

// Reducer
export default activePowerInvertersChartReducer.reducer;
