import React, {FunctionComponent} from 'react';
import {ACard, Skeleton} from "@atiautomacao/ati-ui-library";
import {Box, Container, List, ListItemButton, ListItemText, styled} from "@mui/material";
import {truncateNumber} from "../../../../../Utils/NumberUtil";

const CardList: FunctionComponent<{ data:any[], label:string, height?: number | string, isSuccess:boolean }> = ({data, label, height, isSuccess}) => {

    const FireNav = styled(List)<{ component?: React.ElementType }>({
        '& .MuiListItemButton-root': {
            paddingLeft: 24,
            paddingRight: 24,
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
    });

    return (
        <ACard
            cardStyle={{ height: height ?? undefined, marginBottom: 2 }}
            contentStyle={{ padding: 0 }}
            titleStyle={{fontSize: 17}}
            title={label}

        >
            { !isSuccess ?
                <Skeleton animation="wave" height={height ?? "100%"} variant="rounded" width={"100%"} />
                :
                <FireNav component="nav" disablePadding>
                    <Box
                        sx={{
                            pb: 2,
                        }}
                    >
                        {data.length > 0 ?
                            data.map((item) => (
                                <ListItemButton
                                    key={item.id}
                                    sx={{py: 0, minHeight: 32, color: '#666'}}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        title={item.name}
                                        primaryTypographyProps={{fontSize: 14, fontWeight: 'medium', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                                    />
                                    <ListItemText
                                        primary={item.value ? truncateNumber(item.value, 2) + ""+(item.unityName ? item.unityName : "") : "-"}
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            fontWeight: 'medium',
                                            textAlign: 'right'
                                        }}
                                    />
                                </ListItemButton>
                            ))
                        :
                            <Container fixed>
                                <Box sx={{
                                    bgcolor: '#e1e0e0', height: 'auto', width: '100%' ,alignItems: 'center',
                                    justifyContent: 'center', display:'flex'}}>
                                    <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                                        <span>Nenhum dado encontrado</span>
                                    </div>
                                </Box>
                            </Container>
                        }

                    </Box>
                </FireNav>
            }

        </ACard>
    );
}

export default CardList;