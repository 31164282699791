import {Box, Grid, Paper, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {RootState} from "../../../Config/Store";
import React from "react";

import TotalizerAlarm from "./TotalizerAlarm/TotalizerAlarm";
import AlarmPage from "./Alarms/AlarmPage";

export default function DashboardPowerPlantAlarmsPage(){
    const {selectedPowerPlant} = useAppSelector((state: RootState) => state.dashboard);

    return(
        <>
            {selectedPowerPlant &&
            <TotalizerAlarm powerStationId={selectedPowerPlant.id}/>}
            <Paper elevation={3}>
                <Box p={2}>
                    {selectedPowerPlant ? <AlarmPage powerPlantId={selectedPowerPlant.id}/>: null}
                </Box>
            </Paper>
        </>

    );
}
