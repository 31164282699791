import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {addMinutes, setHours, setMinutes} from "date-fns";

export class PowerPU {
    equipmentName: string;
    equipmentId: number;
    powerPerUnit: number;
    dateTime: string;
    skidName: string;

    constructor( equipmentName: string, equipmentId: number, powerPerUnit: number, dateTime: string, skidName: string) {
        this.equipmentName = equipmentName;
        this.equipmentId = equipmentId;
        this.powerPerUnit = powerPerUnit;
        this.dateTime = dateTime;
        this.skidName = skidName;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,

    entities: new Array<PowerPU>(),
    entity: undefined,
    
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-per-unit';


export const getEntities = createAsyncThunk<any, any>(
    'power_pu_for_inverters/fetch_entities',
    async ({skids, date}) => {
        let startDateTime = setMinutes(date, 0);
        let endDateTime = setHours(date, 23);
        endDateTime = addMinutes(endDateTime, 55);

        return axios.get<PowerPU>(`${apiUrl}/hour?startDateTime=${LocalDateTimeFormatISO(startDateTime)}&endDateTime=${LocalDateTimeFormatISO(endDateTime)}&skids=${skids}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'powerPU/fetch_entity',
    async () => {
        return axios.get<PowerPU>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const powerPUForInvertersReducer = createSlice({
    name: 'powerPUForInvertersReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = { name:"",data:[], dateTime: [] };
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
                state.entities = [];
            })
    },
})

export const { reset, clear } = powerPUForInvertersReducer.actions;
export const selectPowerPUChart = (state: RootState) => state.powerPUForInvertersChart;

// Reducer
export default powerPUForInvertersReducer.reducer;