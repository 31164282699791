import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {Container, FormHelperText, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import {FindModBusDataTypeByIdReactQuery, SaveModBusDataTypeReactQuery} from "./ModBusDataTypesReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import {useSnackbar} from "notistack";

export const ModBusDataTypeFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const mutationSave = SaveModBusDataTypeReactQuery();
    const mutationFindById = FindModBusDataTypeByIdReactQuery();

    const [modBusDataName, setModBusDataName] = useState<string>("");

    function handleSave() {
        if(modBusDataName.length < 3){
            enqueueSnackbar("Preencha o nome do tipo!", { variant: "warning" });
            return;
        }
        const dataToSave = id ? {id: Number(id), name: modBusDataName} : {name: modBusDataName};
        mutationSave.mutate(dataToSave, {
            onSuccess: (data) => {
                navigate(SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES);
            }
        });
    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setModBusDataName(data?.data.name);
                }
            });
        }
    }, []);

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_MOD_BUS_DATA_TYPES}/>
            <Box style={{paddingTop: 64}}>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="data-name">Nome</InputLabel>
                            <TextField
                                error={modBusDataName === ""}
                                fullWidth
                                id="data-name"
                                variant="standard"
                                placeholder={"Nome do tipo"}
                                onChange={(value) => setModBusDataName(value.target.value)}
                                value={modBusDataName}
                            />
                            {
                                modBusDataName.length <3 &&
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>


        </>

    )
}

export default function ModBusDataTypesPageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <ModBusDataTypeFormPage />
        </QueryClientProvider>
    )
}
