import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, isRejectedWithValue} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

export class PowerIrradiancia {

    dateTime: string;
    activePower: number;
    irradiance: number

    constructor(dateTime: string, activePower: number, irradiance: number ) {
        this.dateTime = dateTime;
        this.activePower = activePower;
        this.irradiance = irradiance;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<PowerIrradiancia>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-irradiance/';


// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk<any, any>(
    'power_irradiance/fetch_entities_list',
    async ({period, idPowerPlant} , { rejectWithValue }) => {
        try{
            let groupBy:string = 'hour';
            let response = await axios.get<PowerIrradiancia>(`${apiUrl}${groupBy}?powerStationId=${idPowerPlant}&startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
            return response.data
        } catch (error) {
            if(axios.isAxiosError(error)){
                if(error.response?.status === 500){
                    return rejectWithValue('Nenhum dado disponível devido a um erro interno do servidor');
                }
            }
        }

    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'power/fetch_entity',
    async () => {
        return axios.get<PowerIrradiancia>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const PowerIrradianceChartReducer = createSlice({
    name: 'PowerIrradianceChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {dateTime:"",activePower:0, irradiance: 0};
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    errorMessage: null,
                    entities: action.payload.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejectedWithValue(getEntities, getEntity), state => {
                state.errorMessage = 'Erro ao carregar dados de Potência x Irradiância';
                state.entities = [];
                state.loading = false;
            })
            .addMatcher(isRejected(getEntities, getEntity), state => {
                state.errorMessage = "Erro ao carregar dados de Potência x Irradiância";
                state.loading = false;
            })
    },
})

export const { reset, clear } = PowerIrradianceChartReducer.actions;
export const selectPowerIrradianceChart = (state: RootState) => state.powerIrradianceChart;

// Reducer
export default PowerIrradianceChartReducer.reducer;