import React from 'react';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";


export const NewHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
        <FontAwesomeIcon icon={faInfoCircle} fontSize={20} style={{color: "#1976D2"}}/>
    </Tooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        border: '3px solid #dadde9',
        borderRadius: '10px',
    },
}));

const ColorInformation: React.FC = () => (
    <React.Fragment>
        <Typography color="inherit">Informação das cores</Typography>
        <p><span style={{ color: '#91CC75' }}>■</span> Verde: Valores maiores que 80%</p>
        <p><span style={{ color: '#F9D37F' }}>■</span> Amarelo: Valores entre 60% e 80%</p>
        <p><span style={{ color: '#E56C6C' }}>■</span> Vermelho: Valores menores que 60%</p>
    </React.Fragment>
);

export const ColorInformationGenerationYieldChart: React.FC = () => (
    <React.Fragment>
        <Typography color="inherit">Informação das cores</Typography>
        <p><span style={{ color: '#91CC75' }}>■</span> Verde: Valores maiores que 90%</p>
        <p><span style={{ color: '#F9D37F' }}>■</span> Amarelo: Valores entre 80% e 90%</p>
        <p><span style={{ color: '#E56C6C' }}>■</span> Vermelho: Valores menores que 80%</p>
    </React.Fragment>
);


export default ColorInformation;