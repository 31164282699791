import {ADataGridColumn, ADataGridFilter} from "@atiautomacao/ati-ui-library";

export const extractFiltersFromColumns = (columns : Array<ADataGridColumn>) : Array<ADataGridFilter> => {
    let result = new Array<ADataGridFilter>();
    columns.filter(item => item.filter).map(item => item.filter ? result.push(item.filter) : null);
    return result;
}

export const checkDiffDays = (toDateTime: Date, fromDateTime: Date) => {
    const diffTime = toDateTime.getTime() - fromDateTime.getTime();

    return diffTime / (1000 * 60 * 60 * 24);
}