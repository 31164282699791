import * as React from 'react';
import {Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {SystemRoutes} from "../../../../Utils/RouteUtils";

const style = {
    linkItem: {
        textDecoration: "none",
        color: "inherit"
    },
};

export default function SGDRecordsMenu() {
    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
                <List>
                    {/*<Link aria-disabled={true} style={style.linkItem} to={SystemRoutes.REPORTS_ALARMS}>*/}
                        <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Alarms" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_PERFORMANCE_SUMMARY}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Performance Summary" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_POWER_PLANTS}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Power Plants" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_EQUIPMENT_CLASS}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Equipment Classes" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_EQUIPMENT}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Equipments" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_SKID}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Skids" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.REPORTS_TELEOBJECTS}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Relatório de Telemedidas" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    {/*<Link style={style.linkItem} to={SystemRoutes.REPORTS_AUDIT}>*/}
                    <Link aria-disabled={true} style={style.linkItem} to="/reports">
                        <ListItem disablePadding>
                            <ListItemButton disabled>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Audit" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link style={style.linkItem} to={SystemRoutes.REPORTS_MANAGEMENT_REPORT}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <FontAwesomeIcon fontSize={20} icon={faFileLines} />
                                </ListItemIcon>
                                <ListItemText primary="Relatório de Gestão" />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </List>
            </nav>
        </Box>

    );
}