import * as React from "react";
import {TextField} from "@mui/material";

export const CustomTextField = React.forwardRef((props:any,ref) => {
    return (
        <TextField
            {...props}
            error={props?.value ===  ''}
            fullWidth
            variant="standard"
            inputRef={ref}
        />
    );
});