import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Container,
    TextField,
    InputLabel,
    FormHelperText,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindAlarmSeverityByIdReactQuery,
    SaveAlarmSeverityReactQuery
} from "./AlarmSeverityReactQuery";
import {AlarmSeverityProps} from "../../../Shared/Types/AlarmSeverity";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import {useSnackbar} from "notistack";

export const AlarmSeverityHomeForm = () => {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const id = location.state?.id as string;

    const [alarmSeverityName, setAlarmSeverityName] = useState<string>("");
    const [alarmSeverityColor, setAlarmSeverityColor] = useState<string>("");

    const mutationSave = SaveAlarmSeverityReactQuery();
    const mutationFindById = FindAlarmSeverityByIdReactQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let alarmSeverity = data?.data as AlarmSeverityProps;
                    setAlarmSeverityName(alarmSeverity.name);
                    setAlarmSeverityColor(alarmSeverity.color);
                }
            });
        }
    }, []);

    function handleSave() {
        let newAlarmSeverity: AlarmSeverityProps = {
            id: id != null ? Number(id) : null,
            name: alarmSeverityName,
            color: alarmSeverityColor.replace(/#/g, ""),
        }
        if (newAlarmSeverity.name.length < 1 || newAlarmSeverity.color.length < 1) {
            enqueueSnackbar(`É necessário preencher todos os campos obrigatório!`, { variant: "error" });

        } else {
            mutationSave.mutate(newAlarmSeverity, {
                onSuccess: () => {
                    navigate(SystemRoutes.SETTINGS_ALARM_SEVERITY);
                }
            });
        }

    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_ALARM_SEVERITY}/>
            <Box style={{paddingTop: 64}}>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "column", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>
                        <div style={{width: '100%'}}>
                            <Box style={{marginBottom: 20}}>
                                <InputLabel id="type-name">Nome</InputLabel>
                                <TextField
                                    error={alarmSeverityName === ""}
                                    fullWidth
                                    id="type-name"
                                    variant="standard"
                                    placeholder={"Nome da type"}
                                    onChange={(value) => setAlarmSeverityName(value.target.value)}
                                    value={alarmSeverityName}
                                />
                                {
                                    alarmSeverityName === "" &&
                                    <FormHelperText id="my-helper-text" style={{color:"#d70909"}}>Campo Obrigatório.</FormHelperText>
                                }
                            </Box>
                        </div>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="type-color">Cor</InputLabel>
                            <Box style={{display: "flex", flexDirection: 'row', justifyContent: "space-between"}}>
                                <TextField
                                    error={alarmSeverityColor === ""}
                                    fullWidth
                                    id="type-color-type"
                                    variant="standard"
                                    type="color"
                                    placeholder={"Nome da cor"}
                                    onChange={(value) => setAlarmSeverityColor(value.target.value)}
                                    value={alarmSeverityColor.includes("#") ? `${alarmSeverityColor}` : `#${alarmSeverityColor}`}
                                    style={{width: 24}}
                                />
                                <TextField
                                    error={alarmSeverityColor === ""}
                                    fullWidth
                                    id="type-color"
                                    variant="standard"
                                    placeholder={"Nome da cor"}
                                    onChange={(value) => setAlarmSeverityColor(value.target.value)}
                                    value={alarmSeverityColor.includes("#") ? `${alarmSeverityColor}` : `#${alarmSeverityColor}`}
                                    style={{color: `#${alarmSeverityColor}`}}

                                />
                            </Box>
                            {
                                alarmSeverityColor.length < 1 &&
                                <FormHelperText id="my-helper-text" style={{color:"#d70909"}}>Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>
        </>
    )
}

export function AlarmSeverityHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AlarmSeverityHomeForm />
        </QueryClientProvider>
    )
}