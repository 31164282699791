import type {Node} from '@atiautomacao/ati-ui-library';
import type {Element, ImageNode} from '../Types/element';
import {mapPowerStationStatusToColor} from './mapPowerStationStatusToColor';

export const mapElementToNode = (element: Element, images: ImageNode[], visited = new Set<string>()): any => {
    const {
        id,
        label,
        leftValues,
        rightValues,
        topValues,
        status,
        edges,
        idImage,
        etsNotResponding,
        alarmType,
        parentId,
        type,
        valueProgress
    } = element;

    if (visited.has(id)) {
        return {id: id, label: "", edges: [], leftValues: [], rightValues: [], topValues: []};
    }

    visited.add(id);

    const text = [];

    if (status) {
        text.push(status);
    }

    const image = images?.find(image => image.id === idImage);
    const icon = image ? image.base64.toString() : "";

    const nodeEdges = edges
        ? edges.map(edge => mapElementToNode(edge, images, visited)).filter(edge => edge !== null) as Node[]
        : [];

    const node: Node = {
        id,
        label,
        text,
        color: mapPowerStationStatusToColor(status),
        image: icon,
        leftValues,
        rightValues,
        topValues,
        edges: nodeEdges,
        etsNotResponding,
        alarmType,
        parentId,
        type,
        valueProgress
    };

    return node;
};