import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Chip, InputLabel, MenuItem,
    Modal, OutlinedInput, Select, Theme, useTheme,
} from "@mui/material";
import {ACard} from "@atiautomacao/ati-ui-library";
import {SelectChangeEvent} from "@mui/material/Select";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";

interface PropsModal {
    openModal: boolean;
    onCloseModal: () => void;
    actualUnifilarElements: any[];
    handleConfirmGroup: (data: any) => void;
    selectedGroupId: string;
    onDeleteGroup: () => void;
    equipmentsGroup: any[];
    unifilarTypeSelected: string;
}

const style = {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    borderRadius: '25px'
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

export default function ModalAddGroup({openModal, onCloseModal, actualUnifilarElements, handleConfirmGroup, selectedGroupId, onDeleteGroup, equipmentsGroup, unifilarTypeSelected}: Readonly<PropsModal>) {
    const [agrupamento, setAgrupamento] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [elementsToSelect, setElementsToSelect] = useState<any[]>([]);
    useEffect(() => {
        if(selectedGroupId !== '') {
            let agpEdit: string[] = [];
            let elements: any[] = [];
            console.log(actualUnifilarElements);
            console.log(selectedGroupId);
            console.log(actualUnifilarElements.filter(el => el.parentId === undefined));
            actualUnifilarElements.filter(
                    elm => elm.label !== "Poste" &&
                    elm.label !== "Painel Solar" &&
                    elm.type !== "group" &&
                    (elm.parentId === undefined || elm.parentId === null || elm.parentId === selectedGroupId))
                .forEach((elt) => {
                    let equipment = equipmentsGroup.find(el => el.id === elt.equipmentId);

                    if(elt.label.includes("Skid")) {
                        equipment = equipmentsGroup.find(el => el.id === elt.skidId);
                    }
                    if(elt.parentId === selectedGroupId) {
                        if(equipmentsGroup.length > 0) {
                            agpEdit.push(equipment.name);
                        } else {
                            agpEdit.push(elt.equipmentName);
                        }
                    }
                    if(equipment) {
                        elements.push(equipment);
                    } else {
                        elements.push({id: elt.equipmentId, name: elt.equipmentName});
                    }

                });

            elements.sort((a: any, b: any) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            setElementsToSelect(elements);
            setAgrupamento(agpEdit);
        }
    }, [actualUnifilarElements, equipmentsGroup, selectedGroupId]);

    const theme = useTheme();

    function handleCloseModal() {
        onCloseModal();
    }

    function handleConfirm() {
        let elementsConfirm:any[] = [];

        agrupamento.forEach(value => {
           elementsConfirm.push({id: elementsToSelect.find(el => el.name === value).id, name: value})
        });

        handleConfirmGroup(elementsConfirm);
    }

    function handleConfirmDialog() {
        setOpenDialog(false);
        onDeleteGroup();
    }

    const handleSelectEquipament = (event: SelectChangeEvent<typeof agrupamento>) => {
        const {
            target: { value },
        } = event;
        setAgrupamento(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getStyles(name: string, tobSelected: readonly string[], theme: Theme) {
        return {
            fontWeight:
                tobSelected.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ACard
                        title={"Agrupamento"}
                        cardStyle={{overflow: 'auto', padding: 2}}
                        footerActions={
                            <>
                                <Button
                                    variant="contained"
                                    color={"error"}
                                    style={{marginBottom: 20, marginRight: 10}}
                                    onClick={() => setOpenDialog(true)}
                                >
                                    Excluir
                                </Button>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    style={{marginBottom: 20, marginRight: 10}}
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    style={{marginBottom: 20}}
                                    onClick={() => handleConfirm()}

                                >
                                    Confirmar
                                </Button>
                            </>
                        }
                    >
                        <Box style={{marginBottom: 20, width: '30%'}}>
                            <InputLabel id="selectTipo">Selecione os Equipamentos</InputLabel>
                            <Select
                                labelId="teleObjectsMultipleSelect"
                                id="multiTeleObjects"
                                multiple
                                value={agrupamento}
                                onChange={handleSelectEquipament}
                                style={{width: '100%'}}
                                input={<OutlinedInput id="equipmentSelected" label="Equipment"/>}
                                renderValue={(selected) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value}/>
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {elementsToSelect.map((elem) => (
                                    <MenuItem
                                        key={elem.id}
                                        value={elem.name}
                                        style={getStyles(elem.name, agrupamento, theme)}
                                    >
                                        {elem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </ACard>
                </Box>
            </Modal>
            <ConfirmDialog
                title={'Excluir'}
                description={'Deseja excluir esse grupo?'}
                open={openDialog}
                handleConfirm={handleConfirmDialog}
                handleClose={() => setOpenDialog(false)}
            />
        </>
    )
}