import * as React from "react";
import {Link} from 'react-router-dom';
import {AppBar, Box, Button} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "../../Config/Hooks";
import {
    isDashboardOverviewAlarmsPage, isDashboardPowerPlantEquipmentComparePage, isDashboardPowerPlantEquipmentDetailsPage,
    isDashboardPowerPlantSkidAlarmsPage, isDashboardPowerPlantSkidEquipmentComparePage,
    isDashboardPowerPlantSkidEquipmentDetailsPage
} from "../../Utils/RouteUtils";

const style = {
    linkItem: {
        textDecoration: 'none',
        color: 'gray'
    },
    selectedLinkItem: {
        textDecoration: 'none',
        color: 'white',
        backgroundColor:"#eff1f2"
    }
}

export default function SGDDashboardSubHeaderMenu() {
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const openSubHeaderMenu = useAppSelector((state: any) => state.dashboardMenu.subHeader.open);

    if(isAuthenticated && openSubHeaderMenu) return (
    // if(isAuthenticated && includesDashboardPowerPlantSkidEquipmentPage()) return (
        <AppBar style={{top: 129}}>
            <Toolbar
                sx={{
                    marginLeft: {xs: "initial", md: isAuthenticated ? openSubMenu ? `${400}px` : `${0}px` : 'initial'},
                    backgroundColor: "white"
                }}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex'} }}>
                    <Link style={style.linkItem} to={ isDashboardPowerPlantEquipmentDetailsPage() ? "/dashboard/power-plant/equipments/details" : "/dashboard/power-plant/skid/equipments/details"}>
                        <Button
                            variant={"contained"} color={"secondary"}
                            disabled={isDashboardPowerPlantEquipmentDetailsPage() || isDashboardPowerPlantSkidEquipmentDetailsPage() ? true : false}
                            sx={{textTransform:"capitalize"}} startIcon={<FontAwesomeIcon icon={faChartBar} />}>
                            Detalhes
                        </Button>
                    </Link>
                    <Link to={isDashboardPowerPlantEquipmentComparePage() ? "/dashboard/power-plant/equipments/compare" : "/dashboard/power-plant/skid/equipments/compare"}>
                        <Button
                            variant={"contained"} color={"secondary"}
                            disabled={isDashboardPowerPlantEquipmentComparePage() || isDashboardPowerPlantSkidEquipmentComparePage() ? true : false}
                            sx={{textTransform:"capitalize"}}
                            startIcon={<FontAwesomeIcon icon={faChartBar} />}>
                            Comparar
                        </Button>
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    );

    return null;
}
