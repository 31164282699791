import {Grid} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TotalizerPlant from "./TotalizerPlant/TotalizerPlant";
import WeatherStationPlant from "./WeatherStationPlant/WeatherStationPlant";
import EnvironmentalDataPlant from "./EnvironmentalData/EnvironmentalDataPlant";
import PowerStationElements from "./PowerStationElements/PowerStationElements";
import MapPowertation from "./MapPowerStation/MapPowerstation";
import UTRDataPlant from "./UTRPlant/UTRDataPlant";

export default function DashboardPowerPlantLayoutPage(){
    const {selectedPowerPlant} = useAppSelector((state) => state.dashboard);
    return(
        <>
            {selectedPowerPlant.id ? (
                <Grid container spacing={1}>
                    {/*<Grid item xs={12} sm={12} lg={12}>*/}
                    {/*    <TotalizerPlant powerStationId={selectedPowerPlant.id} />*/}
                    {/*</Grid>*/}
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} sm={8} lg={8}>
                            <PowerStationElements powerStationId={selectedPowerPlant.id} />
                            {/*<EnvironmentalDataPlant powerPlantId={selectedPowerPlant.id} />*/}
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <WeatherStationPlant powerPlantId={selectedPowerPlant.id} />
                        </Grid>
                    </Grid>
                        {/*<Grid item xs={12} sm={12}>*/}
                        {/*    <UTRDataPlant powerPlantId={selectedPowerPlant.id} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={12} spacing={2}>*/}
                        {/*    <MapPowertation powerStationId={selectedPowerPlant.id} />*/}
                        {/*</Grid>*/}
                    <Grid item xs={12}>
                        <MapPowertation powerStationId={selectedPowerPlant.id} />
                    </Grid>
                </Grid>
            ) : null}
        </>
    );


}
