import {Box} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import EquipmentDetailsSkid from "./EquipmentDetailsSkid/EquipmentDetailsSkid";
import {QueryClient, QueryClientProvider} from "react-query";

export default function DashboardPowerPlantSkidEquipmentsDetailPage() {
    const {selectedEquipment} = useAppSelector((state) => state.dashboard);
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Box p={1} style={{height: 1000, marginTop:'-72px'}}>
                <EquipmentDetailsSkid equipmentId={selectedEquipment.id}/>
            </Box>
        </QueryClientProvider>
    );
}