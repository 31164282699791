import {createAsyncThunk, createSlice, isFulfilled, isPending} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import { LocalDateTimeFormatISO } from '../../../../Utils/DateFormatPatternUtils';

export class CapacityFactor {

    dateTime: string;
    capacityFactor: number;
    expectedCapacityFactor: number;
    injectedEnergy: number;
    generatedEnergy: number;

    constructor(dateTime: string, capacityFactor: number, expectedCapacityFactor: number,
                injectedEnergy: number, generatedEnergy: number ) {
        this.dateTime = dateTime;
        this.capacityFactor = capacityFactor;
        this.expectedCapacityFactor = expectedCapacityFactor;
        this.generatedEnergy = generatedEnergy;
        this.injectedEnergy = injectedEnergy;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<CapacityFactor>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/generation-energy/power-station/capacity-factor/';


export const getEntities = createAsyncThunk<any, any>(
    'capacity energy/fetch_entity_list',
    async ({period, powerPlant}) => {
        let groupBy:string = 'day';
        if( period.groupBy.includes('week') ||  period.groupBy.includes('month')){
            groupBy = 'day';
        } else if( period.groupBy.includes('year')) {
            groupBy = 'month';
        } else if( period.groupBy.includes('general')){
            groupBy = 'year';
        }
        return axios.get<CapacityFactor>(
            `${apiUrl}${powerPlant}/${groupBy}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'capacity/fetch_entity',
    async () => {
        return axios.get<CapacityFactor>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const capacityFactorChartReducer = createSlice({
    name: 'capacityFactorChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {dateTime: "", capacityFactor: 0, expectedCapacityFactor: 0,
                injectedEnergy: 0, generatedEnergy: 0};
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
    },
})

export const { reset } = capacityFactorChartReducer.actions;
export const selectCapacityFactorChart = (state: RootState) => state.capacityFactorChart;

// Reducer
export default capacityFactorChartReducer.reducer;