import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

export class EquipmentEntity {
    activePower: number;
    reactivePower: number;
    powerPerUnit: number;
    equipmentId: number;
    equipmentName: string;
    value: number;
    dateTime: string;

    constructor( activePower: number, reactivePower: number, powerPerUnit: number, equipmentId: number, equipmentName: string, value: number, dateTime: string) {
        this.activePower = activePower;
        this.reactivePower = reactivePower;
        this.powerPerUnit = powerPerUnit;
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.value = value;
        this.dateTime = dateTime;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<EquipmentEntity>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-per-unit';

export const getEntities = createAsyncThunk<any, any>(
    'equipment_entity/entity_list',
    async ({period, skidId}) => {
        let groupBy:string = 'day';
        if( period.groupBy.includes('day')) {
            groupBy = 'hour';
        }
        return axios.get<EquipmentEntity>(`${apiUrl}/hour?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}&skids=${skidId}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'equipment_entity/entity',
    async () => {
        return axios.get<EquipmentEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const activePowerInvertersSkidChartReducer = createSlice({
    name: 'activePowerInvertersSkidChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = { power:[], dateTime:"", name:""};
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), state => {
                state.errorMessage = "Erro ao carregar dados de Potência de Inversores";
                state.loading = false;
            })

    },
})

export const { reset, clear } = activePowerInvertersSkidChartReducer.actions;
export const selectActivePowerChartReducer = (state: RootState) => state.activePowerInvertersSkid;

// Reducer
export default activePowerInvertersSkidChartReducer.reducer;
