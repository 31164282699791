import {QueryClient, QueryClientProvider} from "react-query";
import React, {useEffect, useState} from "react";
import {ACard, Flow, Skeleton} from "@atiautomacao/ati-ui-library";
import type {Element} from "../../../Shared/Types/element";
import {mapElementToNode} from "../../../Shared/Helpers/mapElementToNode";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import ModalAddEquipmentHome from "./components/ModalAddEquipment";
import {
    DeleteNodeReactQuery,
    FindUnifilarByIdReactQuery,
    GetAllPowerStationsReactQuery,
    PrepareNewUnifilarReactQuery,
    SaveUnifilarReactQuery
} from "./GraphElementReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {HeaderMenu} from "../HeaderMenu";
import {useLocation, useNavigate} from "react-router-dom";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ModalAddGroup from "./components/ModalAddGroup";

interface Equipment {
    id: number;
    name: string;
    base64: string;
}

interface PositionValue {
    metadataId?: number;
    id: number;
    label: string,
    unit: string,
    value: number
}

export const GraphElementFormPage = () => {
    const location = useLocation();
    const unifilarFound = location.state?.data;

    const [searchEquipment, setSearchEquipment] = useState('');
    const [filteredEquipments, setFilteredEquipments] = useState<Equipment[]>();
    const [selectedSkidId, setSelectedSkidId] = useState(-1);
    const [openModal, setOpenModal] = useState(false);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [unifilarElements, setUnifilarElements] = useState<any[]>([]);
    const [newEntities, setNewEntities] = useState<any[]>([]);
    const [newCon, setNewCon] = useState<any>(null);
    const [selectedPowerStationId, setSelectedPowerStationId] = useState(-1);
    const [selectedPowerStation, setSelectedPowerStation] = useState<any>(null);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const [idToEdit, setIdToEdit] = useState<string>('');
    const [dataToEdit, setDataToEdit] = useState(null);
    const [isUnifilarPreviousCreated, setIsUnifilarPreviousCreated] = useState(false);
    const [listPowerStations, setListPowerStations] = useState<any[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const [openModalGroup, setOpenModalGroup] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState<string>('');
    const [equipmentsToGroup, setEquipmentsToGroup] = useState<any[]>([]);

    const {data: equipmentsImage, isSuccess} = PrepareNewUnifilarReactQuery();
    const {data: powerStationsList, isSuccess: isSuccessPowerStation} = GetAllPowerStationsReactQuery();

    const mutationSave = SaveUnifilarReactQuery();
    const {mutate: mutationFindById, isLoading: isLoadingFindById} = FindUnifilarByIdReactQuery();
    const mutationDelete = DeleteNodeReactQuery();

    const navigate = useNavigate();

    useEffect(() => {
        if(unifilarFound){
            mutationFindById(unifilarFound, {
                onSuccess: (data: any) => {
                    let elements = updateListFromEdit(data.data.elements);
                    setUnifilarElements(elements);
                    setSelectedPowerStationId(unifilarFound.powerStationId);
                    setSelectedSkidId(unifilarFound.skidId);
                    setIsUnifilarPreviousCreated(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        if(isSuccess && equipmentsImage && equipmentsImage.data.length > 0) {
            let equipments = equipmentsImage.data;
            equipments.push({id: 10, base64: '', name: 'Grupo'});
            setFilteredEquipments(equipments);
        }
    }, [equipmentsImage, isSuccess]);

    useEffect(() => {
        if(isSuccessPowerStation && powerStationsList && powerStationsList.data.length > 0) {
            setListPowerStations(powerStationsList.data);
            if(unifilarFound) {
                let powerPlantSelected = powerStationsList.data.find((ps: any) => ps.id === unifilarFound.powerStationId);
                let equipmentsPS: any[] = [];
                setSelectedPowerStation(powerPlantSelected);
                powerPlantSelected.skidList.forEach((skid: any, index: number) => {
                    skid.equipmentList.forEach((eqp: any) => {
                        if(selectedSkidId && selectedSkidId !== -1) {
                            equipmentsPS.push({id: eqp.id, name: eqp.name});
                        } else {
                            equipmentsPS.push({id: eqp.id, name: eqp.name + " - " + skid.name});
                        }
                    });
                    equipmentsPS.push({id: skid.id, name: skid.name})
                });
                setEquipmentsToGroup(equipmentsPS);
            }
        }
    }, [powerStationsList, isSuccessPowerStation]);

    useEffect(() => {
        const filteredList: any[] = equipmentsImage?.data.filter((equipment: any) =>
            equipment.name.toLowerCase().includes(searchEquipment)
        );
        setFilteredEquipments(filteredList);
    }, [searchEquipment, equipmentsImage]);

    useEffect(() => {
        if(newCon){
            let newElements = unifilarElements;
            const sourceElementIndex = newElements.findIndex(element => element.id === newCon.source);
            const targetIndex = newElements.findIndex(elm => elm.id === newCon.target);

            if(sourceElementIndex !== -1 && targetIndex !== -1) {
                newElements[sourceElementIndex].edges.push(newElements[targetIndex]);
                handleSortElements(newElements);
            }
        }
    }, [newCon]);

    useEffect(() => {
        handleSortElements(unifilarElements);
    }, [unifilarElements.length]);

    useEffect(() => {
        if(idToEdit !== '') {
            let nodeSelected = unifilarElements.find(node => node.id === idToEdit);
            if(nodeSelected?.equipmentId) {
                let equipmentFromNode: any = filteredEquipments?.find(eqp => eqp.id === nodeSelected.idImage);
                setSelectedEquipment(equipmentFromNode);
                setDataToEdit(nodeSelected);
                setOpenModal(true);
            }
        }
    }, [idToEdit]);

    function updateNode(node: any, elements: any[]) {
        node.edges.forEach((edge: any, index: number) => {
            const matchingEdge = elements.find((element) => element.id === edge.id);
            if(matchingEdge) {
                node.edges[index] = matchingEdge;
            }
        });
    }

    function handleSortElements(elements: any[]) {
        for (const node of elements) {
            updateNode(node, elements);
        }

        const sortedEntities = traverseAndSortTree([...elements]);
        setNewEntities([...sortedEntities]);
    }

    const handleSearchChange = (event: any) => {
        const newText = event.target.value.toLowerCase();
        setSearchEquipment(newText);
    };

    function onClickAdd(eqp: any) {
        if(eqp.id === 1 || eqp.id === 5) {
            let elements = unifilarElements;
            const crypto = window.crypto;
            const arrayCryptyo = new Uint16Array(1);
            crypto.getRandomValues(arrayCryptyo);
            const randomId = arrayCryptyo[0].toString();
            let newElement = {
                id: randomId,
                label: eqp.name,
                edges: eqp.id === 1 ? [{
                    id: randomId,
                    label: "",
                    edges: [],
                    leftValues: [],
                    rightValues: [],
                    topValues: []
                }] : [],
                leftValues: [],
                rightValues: [],
                topValues: [],
                icon: null,
                isNavigable: true,
                idImage: eqp.id,
                status: "",
                equipmentId: null,
                nodeId: randomId,
                parentNode: null
            };

            setUnifilarElements([...elements, newElement]);
        } else if (eqp.id === 10) {
            let groupId = `g${groups.length + 1}`
            let groupName = `Grupo ${groups.length + 1}`;
            let newGroup = {
                id: groupId,
                label: groupName,
                edges: [],
                leftValues: [],
                rightValues: [],
                topValues: [],
                icon: null,
                isNavigable: true,
                status: '',
                equipmentId: null,
                nodeId: groupId,
                parentNode: null,
                type: 'group'
            };

            setUnifilarElements([...unifilarElements, newGroup]);
            setGroups([...groups, {id: groupId, name: groupName}]);
        } else {
            setSelectedEquipment(eqp);
            setOpenModal(true);
        }
    }

    function handleAddElement(data: any) {
        const existingIndex = unifilarElements.findIndex(elt => elt.id === data.id);

        if (existingIndex !== -1) {
            let unifilarUpdated = [...unifilarElements];
            unifilarUpdated.map((element, index) => (index === existingIndex ? data : element));

            setUnifilarElements(unifilarUpdated);

            handleSortElements(unifilarUpdated);
        } else {
            setUnifilarElements([...unifilarElements, data]);
        }
    }

    function clearUnifilar() {
        setUnifilarElements([]);
        setNewEntities([]);
    }

    function onCloseModal(){
        setSelectedEquipment(null);
        setOpenModal(false);
        setIdToEdit('');
        setIdToDelete('');
        setDataToEdit(null);
    }

    function onCloseModalGroup() {
        setOpenModalGroup(false);
        setSelectedGroupId('');
    }

    function handleConfirmGroup(data: any[]) {
        let unifilarUpdated = [...unifilarElements];

        unifilarUpdated = unifilarUpdated.map(element => {
            if (data.some((d) => (d.id === element.equipmentId || d.id === element.skidId) || d.name === element.equipmentName)) {
                return { ...element, parentId: selectedGroupId };
            }
            return element;
        });

        unifilarUpdated = unifilarUpdated.map(element => {
            if (element.parentId === selectedGroupId && !data.some(d => (d.id === element.equipmentId || d.id === element.skidId) || d.name === element.equipmentName)) {
                return { ...element, parentId: null };
            }
            return element;
        });

        setUnifilarElements([...unifilarUpdated]);
        handleSortElements(unifilarUpdated);
        setOpenModalGroup(false);
        setSelectedGroupId('');
    }

    function handleClickOnGroup(id: string) {
        setSelectedGroupId(id);
        setOpenModalGroup(true);
    }

    function onDeleteGroup() {
        let indexToDelete = groups.findIndex(g => g.id === selectedGroupId);

        if(indexToDelete !== -1) {
            let groupsUpdated = [...groups];
            groupsUpdated.splice(indexToDelete, 1);
            setGroups(groupsUpdated);

            let updatedElements = [...unifilarElements];
            const indexElementToDelete= unifilarElements.findIndex(elt => elt.id === selectedGroupId);

            if(indexElementToDelete !== -1) {
                updatedElements = updatedElements.map(element => {
                    if (element.parentId === selectedGroupId) {
                        return { ...element, parentId: undefined };
                    }
                    return element;
                });
                updatedElements.splice(indexElementToDelete, 1);
                setUnifilarElements(updatedElements);
            }
        }
        setOpenModalGroup(false);
    }

    function handleSave() {
        let entitiesToSave = newEntities.map((entity: any) => createNode(entity));
        mutationSave.mutate(entitiesToSave, {
            onSuccess: (data) => {
                navigate(SystemRoutes.SETTINGS_GRAPH_ELEMENT);
            }
        })
    }

    function checkId(direction: any) {
        return direction.metadataId || direction.id || null;
    }

    function createNode(data: any, visited = new Set<any>()) {
        if (visited.has(data)) {
            return null;
        }
        visited.add(data);

        let edges = data.edges.filter((edg: any) => edg.label !== "" && (edg?.nodeId !== data.nodeId || edg.id !== data.id)).map((edge: any) => createNode(edge, visited)).filter((edge: any) => edge !== null);
        let equipment = findEquipmentInPowerStation(selectedPowerStation, data.equipmentId);
        let icon = equipmentsImage?.data.find((img: any) => img.id === data.idImage);
        let metadata: any[] = [];

        data.leftValues.forEach((left: PositionValue) => {
            metadata.push({
                id: checkId(left),
                teleObject: data.teleObjectList.find((teleObj: any) => teleObj.teleObjectConfig.name === left.label),
                position: "LEFT"
            })
        })
        data.rightValues.forEach((right: PositionValue) => {
            metadata.push({
                id: checkId(right),
                teleObject: data.teleObjectList.find((teleObj: any) => teleObj.teleObjectConfig.name === right.label),
                position: "RIGHT"
            })
        })

        let skidId = null;
        if(data.skidId && data.skidId !== -1) {
            skidId = data.skidId;
        } else if(selectedSkidId !== -1) {
            skidId = selectedSkidId;
        }

        return {
            id: data.idFromNode ? data.idFromNode : null,
            name: data.label,
            powerStationId: selectedPowerStationId,
            skidId: skidId,
            view: selectedSkidId && selectedSkidId !== -1 ? "SKID" : "POWER_STATION",
            icon: icon,
            edges: edges,
            equipment: equipment,
            metadata: metadata,
            nodeId: !data.nodeId ? data.id : data.nodeId,
            metadataToDelete: data.metadataToDelete ? data.metadataToDelete : [],
            parentId: data.parentId
        }
    }

    function findEquipmentInPowerStation(selectedPowerStation: any, equipmentId: number): any {
        if (!selectedPowerStation) return undefined;

        if (selectedPowerStation?.equipmentList?.some((equipment: any) => equipment.id === equipmentId)) {
            return selectedPowerStation.equipmentList.find((equipment: any) => equipment.id === equipmentId);
        }

        for (const skid of selectedPowerStation.skidList || []) {
            const foundEquipment = findEquipmentInPowerStation(skid, equipmentId);
            if (foundEquipment) return foundEquipment;
        }

        return undefined;
    }

    const sortNodeChildren = (node: Element, visited = new Set<Element>()): Element => {
        if (visited.has(node)) {
            return node;
        }

        visited.add(node);

        const sortedEdges = node?.edges?.map(edge => sortNodeChildren(edge, visited));
        sortedEdges?.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

        return { ...node, edges: sortedEdges };
    };

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.map(element => sortNodeChildren(element)).sort((a,b) => {
            const isAGeneratorUnit = a.label.toLowerCase().includes('unidade geradora') || a.label.toLowerCase().includes('skid');
            const isBGeneratorUnit = b.label.toLowerCase().includes('unidade geradora') || b.label.toLowerCase().includes('skid');
            if (isAGeneratorUnit && !isBGeneratorUnit) {
                return -1;
            } else if (!isAGeneratorUnit && isBGeneratorUnit) {
                return 1;
            } else if (isAGeneratorUnit && isBGeneratorUnit) {
                return a.label.localeCompare(b.label);
            } else {
                return a.label.localeCompare(b.label);
            }
        });
    };

    const sortPerSkid = (elements:Element):Element[] => {
        return elements.edges.sort((a,b) => {
            const isAGeneratorUnit = findSkid(a).toLowerCase().includes('unidade geradora') || findSkid(a).toLowerCase().includes('skid');
            const isBGeneratorUnit = findSkid(b).toLowerCase().includes('unidade geradora') || findSkid(b).toLowerCase().includes('skid');
            if (isAGeneratorUnit && !isBGeneratorUnit) {
                return -1;
            } else if (!isAGeneratorUnit && isBGeneratorUnit) {
                return 1;
            } else if (isAGeneratorUnit && isBGeneratorUnit) {
                return findSkid(a).localeCompare(findSkid(b));
            } else {
                return findSkid(a).localeCompare(findSkid(b));
            }
        })
    }

    const findSkid = (element:Element, visited = new Set<Element>()): string => {
        if (visited.has(element)) {
            return "";
        }

        visited.add(element);

        if (element.label.toLowerCase().includes('unidade geradora') || element.label.toLowerCase().includes('skid')) {
            return element.label;
        }
        if (element.edges) {
            for (const edge of element.edges) {
                const result = findSkid(edge, visited);
                if (result) return result;
            }
        }
        return element.label;
    }

    const sortTreePerSkid = (elements:Element[]): Element[] => {
        const newElements = elements.map(element => ({
            ...element,
            edges: [...element.edges]
        }));
        for(const element of newElements){
            element.edges = sortPerSkid({...element})
        }

        return newElements
    }

    function handleIdToDelete(id: string) {
        setOpenDialog(true);
        setIdToDelete(id);
    }

    const handleDialogConfirm = async () => {
        if(idToDelete !== '') {
            let updatedElements = [...unifilarElements];
            const elementToDelete = unifilarElements.find(elt => elt.id === idToDelete);
            const indexToDelete = unifilarElements.findIndex(elt => elt.id === idToDelete);

            if(elementToDelete.idFromNode) {
                let parentIds = findParents(elementToDelete.id);
                mutationDelete.mutate({idFromNode: elementToDelete.idFromNode, parentIds: parentIds});
            }

            if (indexToDelete !== -1) {
                updatedElements.forEach(elm => {
                    if(elm.edges.find((el: any) => el.id === idToDelete)) {
                        let indexEdge = elm.edges.findIndex((edg: any) => edg.id === idToDelete);
                        elm.edges.splice(indexEdge, 1);
                    }
                })
                updatedElements.splice(indexToDelete, 1);
                setUnifilarElements(updatedElements);
            }
        }
        setIdToDelete('');
        handleDialogClose();
    }

    function findParents(nodeId: string) {
        return unifilarElements.reduce((acc, element) => {
            if (element.edges.length > 0) {
                const hasParent = element.edges.some((edge: any) => edge.id === nodeId);
                if (hasParent) {
                    acc.push(element.idFromNode);
                }
            }
            return acc;
        }, []);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    function updateListFromEdit(elementsSaved: any[]) {
        let elements = elementsSaved;
        elements.forEach((elt: any) => {
            if(elt.parentId !== null && !elements.find((elm: any) => elm.id === elt.parentId)) {
                elements.push({
                    id: elt.parentId,
                    label: elt.parentId.toUpperCase(),
                    edges: [],
                    leftValues: [],
                    rightValues: [],
                    topValues: [],
                    icon: null,
                    isNavigable: true,
                    status: '',
                    equipmentId: null,
                    nodeId: elt.parentId,
                    parentNode: null,
                    type: 'group'
                })
            }
        })

        return elements;
    }
    console.log(sortTreePerSkid(newEntities).map(element => mapElementToNode(element, equipmentsImage?.data)))
    let cardContentToRender: JSX.Element;
    if(isLoadingFindById) {
        cardContentToRender = (<Skeleton animation="wave" height={500} variant="rectangular" width="80%" />);
    } else if (newEntities && newEntities.length > 0) {
        cardContentToRender = (
            <Container style={{height: 800, width: '100%', marginLeft: '-10%'}}>
                <Flow
                    nodes={sortTreePerSkid(newEntities).map(element => mapElementToNode(element, equipmentsImage?.data))}
                    isCreating={true}
                    handleClickEditNode={(id) => setIdToEdit(id)}
                    handleClickDeleteNode={(id) => handleIdToDelete(id)}
                    handleClickGroup={(id) => handleClickOnGroup(id)}
                    onEdgeUpdates={(oldEdge, newConnection) => setNewCon(newConnection)}
                />
            </Container>
        )
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{ bgcolor: '#e1e0e0', height: 700, width: '114%', marginLeft: '-14%' ,alignItems: 'center',
                    justifyContent: 'center', display:'flex'}}>
                    <div style={{ textAlign: 'center' , fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum equipamento encontrado</span>
                    </div>
                </Box>

            </Container>
        )
    }

    return (
        <>
            <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_GRAPH_ELEMENT}/>
            <Box style={{paddingTop: 64}}>
                <ACard
                    cardStyle={{height: 800, width: "100%"}}
                    title="Unifilar"
                >
                    <Container style={{marginBottom: 50, display: 'flex', flexDirection: "row", justifyContent: 'space-between'}}>
                        {cardContentToRender}
                        <Container
                            fixed
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '25%',
                                height: '700px',
                                marginRight: '-8%',
                                borderRadius: '16px',
                                overflowY: 'auto',
                                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                backgroundColor: '#fff'
                            }}
                        >
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 10,
                                flexGrow: 1
                            }}>
                                <TextField
                                    id="searchEquipment"
                                    label="Buscar"
                                    defaultValue=""
                                    variant="standard"
                                    fullWidth
                                    style={{marginBottom: '10px'}}
                                    value={searchEquipment}
                                    onChange={handleSearchChange}
                                />
                                {filteredEquipments ?
                                    filteredEquipments.map((equipment) => (
                                        <Box
                                            key={equipment.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '117%',
                                                marginLeft: '-10%',
                                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                                                borderRadius: '16px',
                                                marginBottom: 2,
                                                paddingBottom: 2
                                            }}>
                                            <h5 style={{fontSize: '16px', fontWeight: '400'}}>{equipment.name}</h5>
                                            {
                                                equipment.base64 ?
                                                    <Box key={equipment.id}>
                                                        <img
                                                            alt={equipment.name}
                                                            src={equipment.base64}
                                                            width="100"
                                                            height="80"
                                                        />
                                                    </Box>
                                                    : ""
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => onClickAdd(equipment)}
                                                sx={{width: "10px", padding: "5px"}}>
                                                <FontAwesomeIcon icon={faPlus} fontSize={20} />
                                            </Button>
                                        </Box>
                                    ))
                                    :
                                    ""}
                            </Box>
                        </Container>
                    </Container>
                    <ModalAddEquipmentHome
                        openModal={openModal}
                        onCloseModal={onCloseModal}
                        equipment={selectedEquipment}
                        setSkid={(skidId) => setSelectedSkidId(skidId)}
                        setPowerStation={(id) => setSelectedPowerStationId(id)}
                        powerStation={(ps) => setSelectedPowerStation(ps)}
                        addElementToUnifilar={handleAddElement}
                        clearUnifilar={clearUnifilar}
                        dataToEdit={dataToEdit}
                        powerStationIdFromForm={selectedPowerStationId}
                        skidIdFromForm={selectedSkidId}
                        isUnifilarPreviousCreated={isUnifilarPreviousCreated}
                        actualUnifilarElements={unifilarElements}
                        listPowerStation={listPowerStations}
                        unifilarToEdit={unifilarFound}
                    />
                    <ModalAddGroup
                        openModal={openModalGroup}
                        onCloseModal={onCloseModalGroup}
                        actualUnifilarElements={unifilarElements}
                        handleConfirmGroup={handleConfirmGroup}
                        selectedGroupId={selectedGroupId}
                        onDeleteGroup={onDeleteGroup}
                        equipmentsGroup={equipmentsToGroup}
                        unifilarTypeSelected={selectedSkidId && selectedSkidId !== -1 ? "SKID" : "POWER_STATION"}
                    />
                    <ConfirmDialog
                        title={'Excluir'}
                        description={'Deseja excluir esse elemento gráfico?'}
                        open={openDialog}
                        handleConfirm={handleDialogConfirm}
                        handleClose={handleDialogClose}
                    />
                </ACard>
            </Box>
        </>
    )
}
export default function GraphElementFormPageHome() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <GraphElementFormPage/>
        </QueryClientProvider>
    )
}