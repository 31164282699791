import {StyledTreeItemRoot} from './TreeItem.theme';
import {TreeItemProps as MUITreeItemProps} from '@mui/x-tree-view/TreeItem';
import * as React from 'react';
import {SvgIconProps} from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import {Tooltip, Typography} from '@mui/material';


type TreeItemProps = MUITreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelText: string;
    iconColor?: string;
    handleNodeClick?: Function;
};

const TreeItem = ({
                      bgColor,
                      color,
                      labelIcon: LabelIcon,
                      labelText,
                      iconColor = '#C9C9C9',
                      children,
                      handleNodeClick,
                      ...props
                  }: TreeItemProps) => {

    const customStyle = {
        color: color,
        backgroundColor: bgColor,
    };

    return (
        <StyledTreeItemRoot
            label={
                <div
                    onClick={handleNodeClick ? () => handleNodeClick() : undefined}
                    onKeyDown={handleNodeClick ? () => handleNodeClick() : undefined}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
                        <Box component={LabelIcon} color="inherit" sx={{mr: 1, color: iconColor}}/>
                        <Tooltip title={labelText}>
                            <Typography variant="body2" sx={{
                                fontWeight: 'inherit',
                                flexGrow: 1,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}>
                                {labelText}
                            </Typography>
                        </Tooltip>
                    </Box>
                </div>
            }
            style={customStyle}
            nodeId={props.nodeId}
        >
            {children}
        </StyledTreeItemRoot>
    );
}

export default TreeItem;
