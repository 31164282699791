import React, {useEffect, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Config/Hooks';
import { getEntities, reset } from './PowerStationElementsReducer';
import type { RootState } from '../../../../Config/Store';
import {mapElementToNode} from "../../../../Shared/Helpers/mapElementToNode";
import {ACard, Flow, Skeleton} from "@atiautomacao/ati-ui-library";
import { useSkidNavigation } from '../../../../Shared/Hooks/useSkidNavigation';
import { useEquipmentNavigation } from '../../../../Shared/Hooks/useEquipmentNavigation';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import type {Element} from '../../../../Shared/Types/element';
import useInterval from "../../../../Shared/Hooks/useInterval";
import HeaderActions from "./HeaderActions";

interface Props {
    powerStationId: number;
}

const PowerStationElements = ({ powerStationId }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigateToSkid = useSkidNavigation();
    const navigateToEquipment = useEquipmentNavigation();
    const { entities, isLoading } = useAppSelector((state: RootState) => state.powerStationElements);
    const { selectedPowerPlant } = useAppSelector((state: RootState) => state.dashboard);
    const [elementsData, setElementsData] = useState<any[]>([])

    useEffect(() => {
        dispatch(reset())
        dispatch(getEntities(powerStationId));
    }, [selectedPowerPlant]);

    useInterval(() => {
        dispatch(reset())
        dispatch(getEntities(powerStationId));
    }, 60000); // 5 minutes

    useEffect(() => {
        if(entities && entities.elements.length > 0) {
            let nodesElements = [...entities.elements];
            nodesElements.forEach((elt: any) => {
                if(elt.parentId !== null && !nodesElements.find((elm: any) => elm.id === elt.parentId)) {
                    nodesElements.push({
                        id: elt.parentId,
                        label: elt.parentId.toUpperCase(),
                        edges: [],
                        leftValues: [],
                        rightValues: [],
                        topValues: [],
                        icon: '',
                        isNavigable: true,
                        status: '',
                        type: 'group',
                        idImage: undefined
                    })
                }
            });

            setElementsData(nodesElements);
        }
    }, [entities]);

    const handleNodeClick = (id: string, isNavigable: boolean, label: string): void => {
        const newID = id?.replace(/\D/g, "")
        let node = entities?.elements.find(element => element.id === id);
        if (node?.isNavigable) {
            let idNumber = Number(newID);
            if(label.toLowerCase().includes('unidade geradora') || label.toLowerCase().includes('skid')){
                navigateToSkid(selectedPowerPlant.id, idNumber);
                return
            }
            navigateToEquipment(idNumber);
        }
    }
    const sortTreePerSkid = (elements:Element[]): Element[] => {
        const newElements = elements.map(element => ({
            ...element,
            edges: [...element.edges]
        }));
        for(const element of newElements){
            element.edges = sortPerSkid({...element})
        }
        return newElements
    }

    const sortNodeChildren = (node: Element): Element => {
        const sortedEdges = node.edges.map(edge => sortNodeChildren(edge));
        sortedEdges.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
        return { ...node, edges: sortedEdges };
    };


    const compareGeneratorUnits = (labelA: string, labelB: string): number => {
        const isAGeneratorUnit = labelA.toLowerCase().includes('unidade geradora') || labelA.toLowerCase().includes('skid');
        const isBGeneratorUnit = labelB.toLowerCase().includes('unidade geradora') || labelB.toLowerCase().includes('skid');

        if (isAGeneratorUnit && !isBGeneratorUnit) {
            return -1;
        } else if (!isAGeneratorUnit && isBGeneratorUnit) {
            return 1;
        } else {
            return labelA.localeCompare(labelB);
        }
    };

    const traverseAndSortTree = (elements: Element[]): Element[] => {
        return elements.map(element => sortNodeChildren(element)).sort((a, b) => {
            return compareGeneratorUnits(a.label, b.label);
        });
    };

    const sortPerSkid = (element: Element): Element[] => {
        return element.edges.sort((a, b) => {
            return compareGeneratorUnits(findSkid(a), findSkid(b));
        });
    };

    const findSkid = (element: Element): string => {
        if (element.label.toLowerCase().includes('unidade geradora') || element.label.toLowerCase().includes('skid')) {
            return element.label;
        }
        if (element.edges) {
            for (const edge of element.edges) {
                const result = findSkid(edge);
                if (result) return result;
            }
        }
        return element.label;
    };



    let cardContentToRender: JSX.Element;
    if (isLoading) {
        cardContentToRender = <Skeleton animation="wave" height={500} variant="rectangular" width={800} />
    } else if (entities && elementsData && elementsData.length > 0) {
        const newEntities = traverseAndSortTree([...elementsData])
        cardContentToRender = (
            <div style={{height: 500, width: '100%'}}>
                <Flow nodes={sortTreePerSkid(newEntities).map(element => mapElementToNode(element, entities.images))}
                      onNodeClick={handleNodeClick} isCreating={false}/>
            </div>
        );
    } else {
        cardContentToRender = (
            <Container fixed>
                <Box sx={{
                    bgcolor: '#e1e0e0', height: '500px', width: '800px', alignItems: 'center',
                    justifyContent: 'center', display: 'flex'
                }}>
                    <div style={{textAlign: 'center', fontSize: '23px', color: '#646363'}}>
                        <span>Nenhum equipamento encontrado</span>
                    </div>
                </Box>

            </Container>
        );
    }

    return (
        <ACard
            cardStyle={{height: 700, width:'100%'}}
            title="Unifilar"
            // headerActions={<HeaderActions/>}
            // footerActions={<FooterActions/>}
        >
            {cardContentToRender}
        </ACard>
    );
};

export default PowerStationElements;
