
export interface FormattedData {
    generatedEnergy: string;
    performanceRatio: string;
    availability: string;
    powerCapacityNominal: string;
    instantPower: string;
}

const formatValue = (value: number | undefined, decimalPlaces: number = 2): string => {
    if (value == null) {
        return "N/A";
    }
    return value.toFixed(decimalPlaces) + "%";
};

const formatNumber = (value: number | undefined, decimals: number, unit: string): string => {
    if (value == null || value < 0) {
        return "N/A";
    }
    return value.toFixed(decimals) + " " + unit;
};

const formatPercentage = (value: number | undefined, decimalPlaces: number = 0): string => {
    if (value == null) {
        return "N/A";
    }
    return (value * 100).toFixed(decimalPlaces) + "%";
};


export const formatData = (content: {
    powerStationName: string;
    powerCapacityNominal: number;
    generatedEnergySum: number;
    availability: number;
    performanceRatio: number;
    instantPower: number
}): FormattedData => {
    return {
        generatedEnergy: formatNumber(content?.generatedEnergySum, 3, "MWh"),
        performanceRatio: formatValue(content?.performanceRatio, 2),
        availability: formatValue(content?.availability),
        powerCapacityNominal: formatNumber(content?.powerCapacityNominal, 2, "kWp"),
        instantPower: formatNumber(content?.instantPower, 2, "kW"),
    };
};