import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../Utils/DateFormatPatternUtils";
import {PagedSearchParams, Period} from "@atiautomacao/ati-ui-library";
import {MenuItemStatus} from "../../../Shared/Components/Layout/menu/types/MenuItemStatus";

export class Chart {
    dateTime: string;
    value: number;

    constructor(dateTime: string, value: number) {
        this.dateTime = dateTime;
        this.value = value;
    }
}

export class Performance {

    powerStationId: number;
    generatedEnergySum: number;
    generatedEnergyList: Chart[];
    irradianceSum: number;
    irradianceList: Chart[];
    powerStationName: string;
    powerCapacityNominal: number;
    performanceRatio: number;
    instantPower: number;
    injectedEnergySum: number;
    injectedEnergyList: Chart[];
    availability: number;
    status: MenuItemStatus;

    constructor(powerStationId: number, generatedEnergySum: number, generatedEnergyList: Chart[], irradianceSum: number, irradianceList: Chart[], powerStationName: string, powerCapacityNominal: number, performanceRatio: number, instantPower: number, injectedEnergySum: number, injectedEnergyList: Chart[], availability: number, status: MenuItemStatus) {
        this.generatedEnergySum = generatedEnergySum;
        this.irradianceSum = irradianceSum;
        this.generatedEnergyList = generatedEnergyList;
        this.irradianceList = irradianceList;
        this.powerStationName = powerStationName;
        this.powerCapacityNominal = powerCapacityNominal;
        this.performanceRatio = performanceRatio;
        this.instantPower = instantPower;
        this.injectedEnergySum = injectedEnergySum;
        this.injectedEnergyList = injectedEnergyList;
        this.availability = availability;
        this.status = status;
        this.powerStationId = powerStationId;
    }
}

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<Performance>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/power-station/performance-analysis';


export const searchEntities = createAsyncThunk<
    any,
    {
        period: Period;
        params: PagedSearchParams | undefined;
    }
>(
    'performance Analysis/fetch_entity_list',
    async ({period, params}) => {

        return axios.get(
            apiUrl + `/comparison?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`,
            {
                params: params ? params.toURLSearchParams() : null
            }
        );

    },
    {serializeError: serializeAxiosError}
);

export const getEntitiesWithoutParams = createAsyncThunk(
    "performanceAnalysisForMapsOverview/fetch_entity_list",
    async () => {
        const now = new Date();
        // Formato da data: yyyy-MM-ddTHH:mm:ss
        const startDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T00:00:00`;
        const endDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T23:59:59`;

        return axios.get(apiUrl + `/comparison?startDateTime=${startDate}&endDateTime=${endDate}`);
    }

);
export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'performance/fetch_entity',
    async () => {
        return axios.get<Performance>(`${apiUrl}`);
    },
    {serializeError: serializeAxiosError}
);

// Slices
const PerformanceAnalysisReducer = createSlice({
    name: 'PerformanceAnalysisReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                powerStationId: 0,
                irradianceSum: 0,
                generatedEnergySum: 0,
                injectedEnergySum: 0,
                generatedEnergyList: 0,
                irradianceList: 0,
                powerStationName: "",
                powerCapacityNominal: 0,
                performanceRatio: 0,
                instantPower: 0,
                injectedEnergyList: 0,
                availability: 0,
                status: ""
            };
            state.entities = []
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(searchEntities, getEntity, getEntitiesWithoutParams), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,

                    totalOfPages: action.payload.data.totalOfPages,
                    totalOfRecords: action.payload.data.totalOfRecords,
                    totalOfRequestedRecords: action.payload.data.totalOfRequestedRecords,
                    pageNumber: action.payload.data.pageNumber,
                    recordsPerPage: action.payload.data.recordsPerPage,

                }
            })
            .addMatcher(isPending(searchEntities, getEntity, getEntitiesWithoutParams), state => {
                state.errorMessage = undefined;
                state.loading = true;
            })
            .addMatcher(isRejected(searchEntities, getEntity, getEntitiesWithoutParams), state => {
                return {
                    ...state,
                    isLoading: false,
                    entities: [],
                };
            })
    },
})

export const {reset,clear} = PerformanceAnalysisReducer.actions;
export const selectPerformanceAnalysis = (state: RootState) => state.performanceAnalysis;

// Reducer
export default PerformanceAnalysisReducer.reducer;