import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { RootState } from '../../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../../Config/Reducers/ReducerUtils";
import { ChartState} from "../../../../Config/Types";

export class EnvironmentalDataType {

    id: number;
    dateTime: string;
    co2: number;
    coal: number;
    treeEconomy: number;
    financialEconomics: number;

    constructor( id: number, dateTime: string, co2: number, coal: number, treeEconomy: number, financialEconomics: number) {
        this.id = id;
        this.dateTime = dateTime;
        this.co2 = co2;
        this.coal = coal;
        this.treeEconomy = treeEconomy;
        this.financialEconomics = financialEconomics;
    }
}

const initialState: ChartState= {
    loading: false,
    errorMessage: null,

    entities: new Array<EnvironmentalDataType>(),
    entity: EnvironmentalDataType,
    updating: false,
    updateSuccess: false
};

const apiUrl = 'api/environmental-data';


export const getEntities = createAsyncThunk<any, any>(
    'environmentalDataPlant/fetch_entity_list',
    async (id) => {
        return axios.get<EnvironmentalDataType>(`${apiUrl}/power-station/${id}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, { rejectValue: any }>(
    'environmentalDataPlant/fetch_entity',
    async (id) => {
        return axios.get<EnvironmentalDataType>(`${apiUrl}/power-station/${id}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const EnvironmentalDataStationPlantReducer = createSlice({
    name: 'EnvironmentalDataStationPlantReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                id: 0,
                dateTime: "",
                co2: 0,
                coal: 0,
                treeEconomy: 0,
                financialEconomics: 0,
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.loading = true;
            })
    },
})

export const { reset } = EnvironmentalDataStationPlantReducer.actions;
export const selectWeatherStationPlant = (state: RootState) => state.environmentalDataStationPlant;

// Reducer
export default EnvironmentalDataStationPlantReducer.reducer;