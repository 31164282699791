import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { RootState } from '../../../Config/Store';
import axios from 'axios';
import { serializeAxiosError } from "../../../Config/Reducers/ReducerUtils";
import { ChartState} from "../../../Config/Types";

export class TotalizerAlarm {

    powerPlantsOffTheAir: number;
    equipmentOffTheAir: number;
    alarmedEquipment: number;
    activeAlarms: number;
    criticalAlarms: number;
    alarmsNotRecognized: number;

    constructor(powerPlantsOffTheAir: number, equipmentOffTheAir: number, alarmedEquipment: number, activeAlarms: number, criticalAlarms: number, alarmsNotRecognized: number) {
        this.powerPlantsOffTheAir = powerPlantsOffTheAir;
        this.equipmentOffTheAir = equipmentOffTheAir;
        this.alarmedEquipment = alarmedEquipment;
        this.activeAlarms = activeAlarms;
        this.criticalAlarms = criticalAlarms;
        this.alarmsNotRecognized = alarmsNotRecognized;
    }
}

const initialState:  ChartState= {
    loading: false,
    errorMessage: null,
    entities: new Array<TotalizerAlarm>(),
    entity: TotalizerAlarm,
    updating: false,
    updateSuccess: false
};

const apiUrl = 'api/totalizer-alarms';


// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk(
    'Totalizer Alarms/fetch_entity_list',
    async () => {
        return axios.get<TotalizerAlarm>(`${apiUrl}`);

    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any>(
    'TotalizerAlarms/fetch_entity',
    async () => {
        return axios.get<TotalizerAlarm>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const TotalizerAlarmsReducer = createSlice({
    name: 'TotalizerAlarmsReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                powerPlantsOffTheAir: 0,
                equipmentOffTheAir: 0,
                alarmedEquipment: 0,
                activeAlarms: 0,
                criticalAlarms: 0,
                alarmsNotRecognized: 0,
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data,
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.loading = true;
            })
    },
})

export const { reset } = TotalizerAlarmsReducer.actions;
export const selectTotalizerAlarms = (state: RootState) => state.totalizerAlarms;

// Reducer
export default TotalizerAlarmsReducer.reducer;