import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import {AlarmSeverityProps} from "../../../Shared/Types/AlarmSeverity";

const apiUrl = "api/alarmSeverity";

const saveAlarmSeverity = async (data: AlarmSeverityProps) => {
    try {
        if(data.id != null){
            return await axios.put(`${apiUrl}`, data,{timeout:30000}).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const SaveAlarmSeverityReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveAlarmSeverity(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Severidade de alarme salva com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar severidade de alarme: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchAlarmSeverity = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const AlarmSeverityReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchAlarmSeverity(pagedSearchParams);
        }
    });
};

const fetchAlarmSeverityById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindAlarmSeverityByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchAlarmSeverityById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar uma severidade de alarme: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteAlarmSeverity = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteAlarmSeverityReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteAlarmSeverity(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Severidade de alarme excluída com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir severidade de alarme: ${error.message}`, { variant: "error" });
        }
    })
};
