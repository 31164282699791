import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import logo from "../../../Assets/logo.png";
import {AppBar, Badge, Button, Divider, Modal, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {ACard, ADataGrid, FloatMenuButton, FloatMenuItem} from "@atiautomacao/ati-ui-library";
import {
    faCalendarDays,
    faExpand,
    faGear, faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Logout, MoreVert} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import {toggleMainMenu, toggleSubMenu} from "../../../Config/Reducers/LayoutReducer";
import Breadcrumb from "./menu/Breadcrumb";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {QueryClient, QueryClientProvider} from "react-query";
import {CommandHistory} from "./types/CommandHistoryReactQuery";
import {useEffect, useState} from "react";
import MailIcon from '@mui/icons-material/Mail';
import {columnsCommandHistoryDataGrid} from "../../../Modules/Settings/GraficElement/columnsDataGrid";
import {DateFormat} from "../../../Utils/DateFormatPatternUtils";

const style = {
    logo: {
        height: 38,
        width: 'auto'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
};



export interface CommandHistoryRow {
    id: number;
    name: string;
    dateTime?: string;
    powerStationName?: string;
    value?: number;
    equipmentName?: string;
    userName?: string
}


function SGDAppBar(){
    const theme = useTheme();
    const isAuthenticated = useAppSelector((state: any) => state.authentication.isAuthenticated);
    const dispatch = useAppDispatch();
    const openMainMenu = useAppSelector((state: any) => state.layout.openMainMenu);
    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const account = useAppSelector((state) => state.authentication.account);
    const [rows, setRows] = React.useState<CommandHistoryRow[]>([]);
    const [qtdMessages, setQtdMessages] = React.useState(0);
    // const { data } = CommandHistory();

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const updateRows = (data: any) => {
        const filteredData = data?.data?.map((item: any) => {
            return {
                id: item.id,
                dateTime: DateFormat(item.dateTime),
                equipmentName: item?.teleObject?.equipment?.name,
                alarmedName: item?.teleObject?.teleObjectConfig?.alarmedNAme,
                powerStationName: item?.teleObject?.equipment?.powerStation?.name,
                value: item?.value,
                userName: item?.user?.name
            };
        });

        setRows(filteredData);
    };

    // useEffect(() => {
    //     // Verifique se os dados existem e são uma matriz
    //     if (data && Array.isArray(data.data)) {
    //         // Atualize a quantidade de mensagens com o novo tamanho dos dados
    //         setQtdMessages(data.data.length);
    //     }
    // }, [data]); // Atualize sempre que os dados mudarem
    //
    // // useInterval(() => {
    // //     console.log("entrou aqui")
    // //     updateRows(data)
    // // }, 10000);
    //
    // useEffect(() => {
    //     updateRows(data);
    // }, [data?.data]);


    const handleOnRow = (rows:any) => {
        // console.log("rows: ", rows);
    }

    const notificationsLabel = (count: number) => {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }
    const menuToogle = () => {
        if(openMainMenu && !openSubMenu) {
            dispatch(toggleMainMenu());
        }else {
            dispatch(toggleMainMenu());
            dispatch(toggleSubMenu());
        }

    }

    if(isAuthenticated) return (
        <AppBar position="fixed" sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}>
            <Toolbar style={{paddingLeft: 7}}>
                <IconButton
                    color="error"
                    aria-label="open drawer"
                    edge="start"
                    onClick={menuToogle}
                    sx={{ ml: 2, mr: 2, display: { lg: 'none' }}}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton edge="start" color="error" aria-label="Menu" style={{marginLeft: 0}}>
                    <img alt="logo" style={style.logo} src={logo}/>
                </IconButton>
                <Breadcrumb />
                <Box sx={{ flexGrow: 1}}></Box>

                {/*<IconButton aria-label={notificationsLabel(100)} style={{paddingRight: 25}}>*/}
                {/*    <Badge badgeContent={100} color="secondary">*/}
                {/*        <MailIcon />*/}
                {/*    </Badge>*/}
                {/*</IconButton>*/}
                <IconButton aria-label={notificationsLabel(qtdMessages)}>
                    <Button onClick={handleOpenModal}>
                        <Badge badgeContent={qtdMessages} color="secondary">
                            <MailIcon sx={{color: 'white'}}/>
                        </Badge>
                    </Button>
                </IconButton>

                {
                    account ? (<h5>{account.name}</h5>) : ""
                }
                <FloatMenuButton
                    icon={<Avatar sx={{ width: 32, height: 32, border:'solid 2px #FFF' }}>{account && account.name ? account.name.charAt(0) : 'A'}</Avatar>}
                    tooltip={"Account Menu"}
                >
                    <FloatMenuItem
                        icon={<FontAwesomeIcon icon={faCalendarDays} fontSize={20} />}
                        text="My Account"
                        disable={true}
                        link="/"
                    />
                    <FloatMenuItem
                        icon={<FontAwesomeIcon icon={faCalendarDays} fontSize={20} />}
                        text="Settings"
                        disable={true}
                        link="/"
                    />
                    <Divider />
                    <FloatMenuItem
                        icon={<Logout fontSize="small" />}
                        text="Logout"
                        link="/logout"
                    />
                </FloatMenuButton>
            </Toolbar>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <ACard
                    title="Histórico de Telecomandos"
                    cardStyle={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '65%',
                        height: "55%",
                        border: '2px solid #000',
                        boxShadow: '24px',
                        padding: '4',
                    }}
                    headerActions={
                        <FloatMenuButton
                            icon={<MoreVert/>}
                            tooltip={"Floating Menu"}
                        >
                            <FloatMenuItem
                                icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                                text="Settings"
                                disable={true}
                                link={"/"}
                            />
                            <FloatMenuItem
                                icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                                text="Expand"
                                disable={true}
                            />
                            <FloatMenuItem
                                icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                                text="Print"
                                disable={true}
                            />
                        </FloatMenuButton>
                    }
                >
                    <ADataGrid
                        headerStyle={{backgroundColor: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize'}}
                        hideFilters={true}
                        showFilterOptions={false}
                        hideSelection={true}
                        size={"small"}
                        columns={columnsCommandHistoryDataGrid}
                        rows={rows}
                        //page={pagedSearchParams.page}
                        onRowSelect={handleOnRow}
                        loading={false}
                        totalOfRecords={rows ? rows.length : 0}
                        rowsPerPage={rows ? rows.length : 0}
                        actionColumnWidth={50}
                        maxHeight={400}
                    />
                </ACard>
            </Modal>

        </AppBar>
    );

    return null;
}


export default function SGDAppBarPage(){
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <SGDAppBar />
        </QueryClientProvider>
    )


}
