import { ADataGridColumn } from "@atiautomacao/ati-ui-library";

const commonBreakpoints = {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true
};

const idBreakpoints = {
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true
};

export const columnsData: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        minWidth: 100,
        visibility: false,
        breakpoints: idBreakpoints
    },
    {
        name: 'domain',
        label: 'DOMAIN',
        align: 'left',
        minWidth: 100,
        breakpoints: commonBreakpoints
    },
    {
        name: 'name',
        label: 'NAME',
        align: 'left',
        minWidth: 100,
        breakpoints: commonBreakpoints
    },
    {
        name: 'displayName',
        label: 'DISPLAY NAME',
        align: 'left',
        minWidth: 100,
        breakpoints: commonBreakpoints
    }
];