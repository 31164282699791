import {useNavigate} from 'react-router-dom';
import {RootState} from '../../Config/Store';
import {useAppDispatch, useAppSelector} from '../../Config/Hooks';
import {SystemRoutes} from '../../Utils/RouteUtils';
import {setEquipment, setSkid} from '../../Modules/Dashboard/DashboardReducer';
import {useEffect, useState} from 'react';
import {DashboardNavigationMenu} from "../Components/Layout/menu/types/DashboardNavigationMenu";

interface EquipmentDetails {
    skid?: any;
    equipment?: any;
}

export const useEquipmentNavigation = (): (equipmentId: number) => { isSuccess: boolean } => {
    const [equipmentId, setEquipmentId] = useState<number | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { selectedEquipment } = useAppSelector((state: RootState) => state.dashboard);
    const { entities } = useAppSelector((state: RootState) => state.dashboardNavigationMenu);

    useEffect(() => {
        if (equipmentId) {
            navigate(SystemRoutes.DASHBOARD_POWER_PLANT_SKID_EQUIPMENTS_DETAILS);
            setEquipmentId(null)
        }
    }, [selectedEquipment, equipmentId]);

    function navigateToEquipment(equipmentId: number): { isSuccess: boolean } {
        if (!equipmentId) {
           return { isSuccess: false };
        }

        if (equipmentExists(entities, equipmentId)) {
            dispatch(setEquipment(equipmentInfo(entities, equipmentId).equipment));
            dispatch(setSkid(equipmentInfo(entities, equipmentId).skid));
            setEquipmentId(equipmentId);
            return { isSuccess: true };
        }
        return { isSuccess: false };
    }

    return navigateToEquipment;
}

const equipmentExists = (entities: DashboardNavigationMenu[], equipmentId: number): boolean => {
    for (const powerStation of entities) {
        for (const skid of powerStation.skidList) {
            for (const equipment of skid.equipmentList) {
                if(equipmentId === equipment.id) {
                    return true;
                }
            }
        }
    }

    return false;
}

const equipmentInfo = (entities: DashboardNavigationMenu[], equipmentId: number) => {
    let equipmentInfo: EquipmentDetails = {
        skid: null,
        equipment: null
    }
    for (const powerStation of entities) {
        for (const skid of powerStation.skidList) {
            for (const equipment of skid.equipmentList) {
                if(equipmentId === equipment.id) {
                    equipmentInfo.skid = skid
                    equipmentInfo.equipment = equipment
                    return equipmentInfo;
                }
            }
        }
    }

    return equipmentInfo;
}