import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import {DashboardOverviewMapPageState} from "./types/DashboardOverviewMapPageState";
import axios from 'axios';
import {Response} from "../../../Config/Types";
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {RootState} from "../../../Config/Store";
import {DashboardOverviewMapsPowerStationWithId} from "./types/DashboardOverviewMapsPowerStationWithId";

const initialState: DashboardOverviewMapPageState = {
    isLoading: false,
    errorMessage: null,
    entities: [],
};

const apiUrl = 'api/power-station/maps';

export const getEntities = createAsyncThunk(
    'power-station/maps/entity_list',
    async () => {
        return axios.get<Response<DashboardOverviewMapsPowerStationWithId>>(apiUrl);
    },
    { serializeError: serializeAxiosError },
);

const DashboardOverviewMapPageReducer = createSlice({
    name: 'DashboardOverviewMapPageReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const entities = action.payload.data.data;
                return {
                    ...state,
                    isLoading: false,
                    entities: entities,
                }
            })
            .addMatcher(isPending(getEntities), state => {
                return {
                    ...state,
                    isLoading: true,
                }
            })
            .addMatcher(isRejected(getEntities), state => {
                return {
                    ...state,
                    isLoading: false,
                    entities: [],
                }
            })
    },
});

export const { reset } = DashboardOverviewMapPageReducer.actions;
export const selectDashboardOverviewMapPage = (state: RootState) => state.dashboardMapPage;
export default DashboardOverviewMapPageReducer.reducer;

