import React, {useCallback, useEffect, useRef, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";

import {
    ACard,
    ADataGrid,
    ADataGridColumn, ADataGridFilter, PagedSearchParams
} from "@atiautomacao/ati-ui-library";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import {useSnackbar} from "notistack";

import {Button, Tooltip, IconButton, Toolbar} from "@mui/material";
import {AddCircle, Delete, Edit} from "@mui/icons-material";

import {TeleObjectType} from "../../../../Shared/Types/TeleObjectType";
import {ModalPointClass} from "./ModalPointClass";
import {EquipmentClassType} from "../../../../Shared/Types/EquipmentClass";
import ConfirmDialog from "../../../../Shared/Components/ConfirmDialog";
import {DeletePointClassReactQuery, PointMapEquipmentClassReactQuery} from "./PointClassReactQuery";
import {extractFiltersFromColumns} from "../../../../Utils/DataUitils";
import theme from "../../../../Shared/ThemeOld/dark";

export const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'teleObjType',
        label: 'Tipo',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'variation',
        label: 'Variação',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'adjustment',
        label: 'Regra de Conversão',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'unit',
        label: 'Unidade',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'point',
        label: 'Ponto',
        align: 'center',
        visibility: true,
        minWidth: 8,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'address',
        label: 'Endereço',
        align: 'center',
        visibility: true,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'note',
        label: 'Nota',
        align: 'center',
        visibility: true,
        minWidth: 8,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'commandVariables',
        label: 'Comando',
        align: 'center',
        visibility: true,
        minWidth: 15,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    }
] as ADataGridColumn[];

interface PointClassDataGridHomeProps {
    teleObjectList?: Array<TeleObjectType>
    dataForm: any;
    equipmentClass?: EquipmentClassType;
    toSaveObjects: any;
    idEquipmentClass?: number | null;
    refreshNewRowsToSave: any;
}

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface RowTeleObjClass {
    id?: number | null;
    name?: string | null;
    teleObjType?: string | null;
    variation?: string | null;
    severity?: string | null;
    adjustment?: string | null;
    normalization?: string | null;
    unit?: string | null;
    point?: number | null;
    address?: string | null;
    note?: string | null;
    commandVariables?: string | null;
    actionsConfig?: Array<ActionConfig>;
    index?: number;
}

const allowedExtensions = ["csv", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

export const PointClassDataGrid = ({teleObjectList, dataForm, equipmentClass, toSaveObjects, idEquipmentClass, refreshNewRowsToSave}: PointClassDataGridHomeProps) => {
    const [rows, setRows] = useState<Array<RowTeleObjClass>>([]);
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [dataSelected, setDataSelected] = useState<TeleObjectType | undefined>(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [selectedToRemove, setSelectedToRemove] = useState<any>(null);
    const [newRowsToSave, setNewRowsToSave] = useState<Array<TeleObjectType>>([]);
    // console.log("dataForm: ", dataForm)
    const mutationDelete = DeletePointClassReactQuery();
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const { data } = PointMapEquipmentClassReactQuery(idEquipmentClass, pagedSearchParams);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(data){
            setTotalOfRecords(data ? data.totalOfRecords : 0);
            if(data && data.data.length > 0){
                let rowsTeleObj = data.data.map((teleObj: any) => {
                    return {
                        id: teleObj.id,
                        name: teleObj.name,
                        teleObjType: teleObj.teleObjectType.name,
                        variation: teleObj.variation,
                        severity: teleObj.severity !== null ? teleObj.severity.name : "",
                        adjustment: teleObj?.teleObjectConvertExpression,
                        normalization: teleObj?.normalAlarmCondition,
                        unit: teleObj.unit,
                        point: teleObj.point,
                        address: teleObj.address,
                        note: teleObj.note,
                        commandVariables: teleObj.commandVariables,
                        actionsConfig: [
                            {actionName: "edit", disabled: false},
                            {actionName: "delete", disabled: false},
                        ]
                    }
                });

                setRows(rowsTeleObj);
            }
        }
    }, [data]);

    const handleOpenModal = useCallback((data: any) => {
        let teleObject = teleObjectList?.find(tob => tob.id === data.id);
        if(teleObject === undefined) {
            teleObject = newRowsToSave?.find(tob => tob.index === data.index);
        }
        setDataSelected(teleObject);
        setOpenModal(true);
    },[teleObjectList, newRowsToSave]);

    function onCloseModal(){
        setOpenModal(false);
        setDataSelected(undefined);
    }
    const refreshPointMap = useCallback((newData: any) => {
        let actualRows = rows;

        const isDuplicate = equipmentClass
            ? rows.some(row => row.id === newData.id)
            : rows.some(row => row.index === newData.index);


        if (isDuplicate) {
            actualRows = rows.filter(row => (
                equipmentClass ? row.id !== newData.id : row.index !== newData.index
            ));
        } else {
            setTotalOfRecords(prevTotalOfRecords => prevTotalOfRecords + 1);
        }

        if(equipmentClass === undefined) {
            if(newRowsToSave.some(row => row.index === newData.index)){
                let actualRowsToSave = newRowsToSave.filter(row => row.index !== newData.index);
                setNewRowsToSave([...actualRowsToSave, newData]);
            } else {
                setNewRowsToSave([...newRowsToSave, newData]);
            }
            toSaveObjects(newData);
            enqueueSnackbar("No final é necessário salvar a classe de equipamento para completar o registro de mapas de ponto.", {variant: 'warning'});
        }

        setRows([
            ...actualRows,
            {
                id: newData.id,
                name: newData.name,
                teleObjType: newData.teleObjectType.name,
                variation: newData.variation,
                adjustment: newData.teleObjectConvertExpression,
                unit: newData.unit,
                point: newData.point,
                address: newData.address,
                note: newData.note,
                commandVariables: newData.commandVariables,
                actionsConfig: [
                    { actionName: "edit", disabled: false },
                    { actionName: "delete", disabled: false },
                ],
                index: newData.index
            }
        ]);

    }, [rows, setRows, setTotalOfRecords, equipmentClass, newRowsToSave, enqueueSnackbar,toSaveObjects]);

    const handleDeletePoint = useCallback((data: any) => {
        if(data.id){
            setSelectedToDelete(data.id);
        } else {
            setSelectedToRemove(data.index);
        }
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        if(selectedToDelete){
            mutationDelete.mutate(selectedToDelete, {
                onSuccess: () => {
                    setRowsPrev();
                }
            });
            setSelectedToDelete(null);
        } else if(selectedToRemove !== null) {
            let actualRows = rows.filter(row => ( row.index !== selectedToRemove ));
            setTotalOfRecords(prevTotalOfRecords => prevTotalOfRecords - 1);
            setRows([...actualRows]);

            let actualNewRowsToSave = newRowsToSave.filter(row => ( row.index !== selectedToRemove ));
            setNewRowsToSave([...actualNewRowsToSave]);
        }
        handleDialogToggle();
    }

    function setRowsPrev() {
        setRows((prevRows) => prevRows.filter(item => item.id !== selectedToDelete));
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    useEffect(() => {
        refreshNewRowsToSave(newRowsToSave);
    }, [newRowsToSave]);

    function getModBusObjectType(modBusValue: string) {
        return dataForm.modBusObjectTypes.find((mbot: any) => mbot.name.toUpperCase() === modBusValue?.toUpperCase());
    }

    function getModBusDataType(modBusDtTypeValue: string) {
        return dataForm.modBusDataTypes.find((mbdt: any) => mbdt.name.toUpperCase() === modBusDtTypeValue?.toUpperCase());
    }

    function getSpecificationType(dnpValue: string) {
        return dataForm.teleObjectSpecificationTypes.find((tost: any) => tost.name.toUpperCase() === dnpValue?.toUpperCase());
    }

    function getGenericType(genericValue: string) {
        return dataForm.teleObjectTypes.find((tot: any) => tot.name.toUpperCase() === genericValue?.toUpperCase());
    }

    function getUnity(unitValue: string) {
        return dataForm.unities.find((unt: any) => unt.name.toUpperCase() === unitValue?.toUpperCase());
    }

    function getTeleObjectDataType(dataTypeValue: string) {
        return dataForm.teleObjectDataTypes.find((dtType: any) => dtType.name.toUpperCase() === dataTypeValue?.toUpperCase());
    }

    function getAlarmConditions(severities: string, alarmedNames: string, conditions: string) {
        if(severities !== '') {
            let listSeverityIds = severities?.split("@");
            let listNames = alarmedNames?.split("@");
            let listConditions = conditions?.split("@");

            let alarmConditions: any[] = [];

            if(listSeverityIds?.length > 0) {
                listSeverityIds.forEach((id: string, index: number) => {
                    let severityId: number = id === '0' ? 5 : Number(id);
                    alarmConditions.push({
                        severity: dataForm.alarmSeverities.find((sev: any) => sev.id === severityId),
                        normalAlarmCondition: listConditions[index],
                        alarmedName: listNames[index]
                    })
                });
            }

            return alarmConditions;
        }

        return [];
    }

    const handleFileChange = (e: any) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];

            if (!allowedExtensions.includes(fileExtension)) {
                enqueueSnackbar("Por favor, forneça um arquivo .csv", {variant: 'error'});
                return;
            }

            let newRows: TeleObjectType[] = [];

            if(fileExtension === "csv") {

                Papa.parse(inputFile, {
                    complete: function(results) {
                        if(results?.data) {
                            results.data.forEach((row: any, index: number) => {
                                if(row['Point'] === 'end' || Object.keys(row).length === 1){
                                    return;
                                }

                                newRows.push(createRow(row, index));
                                fillTable(newRows);
                            });
                        }
                    },
                    header: true
                });
            } else {
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onload = (event) => {
                    if(event) {
                        // @ts-ignore
                        const workbook = XLSX.read(event.target.result, { type: 'binary' });
                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];
                        const sheetData = XLSX.utils.sheet_to_json(sheet);

                        console.log(sheetData)
                        sheetData.forEach((row: any, index: number) => {
                            if(row['Point'] === 'end' || Object.keys(row).length === 1){
                                return;
                            }

                            newRows.push(createRow(row, index));
                            fillTable(newRows);
                        })
                    }
                };

                reader.readAsArrayBuffer(file);
            }
        }
    };

    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        // @ts-ignore
        hiddenFileInput.current.click();
    };

    function createRow(row: any, index: number) {
        console.log(row['Convert Expression'] ?? "")
        return {
            id: null,
            name: row['Name Portuguese'],
            alarmedNAme: '',
            normalName: '',
            teleObjectType: getGenericType(row['Generic Type']),
            teleObjectSpecificationType: getSpecificationType(row['DNP3 Type ID']),
            severity: null,
            address: row['Address'],
            teleObjectConvertExpression: row['Convert Expression'] ?? "",
            unit: row['Unit'],
            unity: getUnity(row['Unit']),
            note: row['Note'],
            createEvent: false,
            modBusObjectType: getModBusObjectType(row['Modbus Object Type ID']),
            point: row['Point'],
            modBusDataType: getModBusDataType(row['Conversion type']),
            variation: row['Variation'],
            discardLowerLimit: row['Discard Lower Limit'],
            discardUpperLimit: row['Discard Upper Limit'],
            commandVariables: '',
            teleObjectDataType: getTeleObjectDataType(row['Data Type']),
            index: index,
            pointTag: row['Point Tag'],
            inputType: row['Input Type'],
            teleObjectAlarmConditionsEntity: getAlarmConditions(row['Alarm Severities'], row['Alarmed Names'], row['Normalization Conditions']),
            reference: row['Reference']
        }
    }

    function fillTable(newRows: any[]) {
        setNewRowsToSave([...newRowsToSave, ...newRows]);
        let newRowsToTable: RowTeleObjClass[] = []
        newRows.forEach((row: TeleObjectType) => {
            newRowsToTable.push({
                id: row.id,
                name: row.name,
                teleObjType: row?.teleObjectType?.name,
                variation: row.variation?.toString() ?? null,
                severity: row.severity ? row.severity.name : null,
                adjustment: row?.teleObjectConvertExpression,
                unit: row.unit,
                point: row.point || null,
                address: row.address,
                note: row.note,
                commandVariables: row.commandVariables,
                actionsConfig: [
                    { actionName: "edit", disabled: false },
                    { actionName: "delete", disabled: false },
                ],
                index: row.index
            })
        });
        setRows([...rows, ...newRowsToTable]);
        setTotalOfRecords(newRowsToTable.length);
        console.log(newRows)
    }

    return (
        <ACard cardStyle={{marginLeft:0}}>
            <Toolbar
                sx={{
                    marginLeft:-2,
                    backgroundColor: "white"
                }}
            >
                <Button
                    size={"small"}
                    variant="contained"
                    // component={Link}
                    onClick={() => setOpenModal(true)}
                    endIcon={<AddCircle/>}>
                    Novo Ponto
                </Button>

                <Button
                    variant="contained"
                    size={"small"}
                    color={"primary"}
                    endIcon={<AddCircle/>}
                    onClick={handleClick}
                    style={{marginLeft: 5}}
                >
                    Importar Ponto
                </Button>
            </Toolbar>

            <input
                type="file"
                onChange={handleFileChange}
                ref={hiddenFileInput}
                style={{display: 'none'}}
            />
            <ADataGrid
                headerStyle={{ backgroundColor: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize' }}
                columns={initColumns}
                rows={rows}
                loading={false}
                totalOfRecords={totalOfRecords}
                hideSelection={true}
                onFiltersChange={handleOnFiltersChange}
                actions={
                    <>
                        <IconButton name="edit" size={"small"} aria-label="Edit"
                                    onClick={(value) => handleOpenModal(value)}>
                            <Tooltip title="Editar">
                                <Edit/>
                            </Tooltip>
                        </IconButton>
                        <IconButton name="delete" size={"small"} aria-label="Delete"
                                    onClick={(value) => handleDeletePoint(value)}>
                            <Tooltip title="Excluir">
                                <Delete/>
                            </Tooltip>
                        </IconButton>
                    </>
                }
            />
            <ModalPointClass data={dataSelected} onCloseModal={onCloseModal} open={openModal} dataForm={dataForm}
                             equipmentClass={equipmentClass} refreshPointMap={refreshPointMap} index={rows.length}/>
            <ConfirmDialog
                title={'Deletar'}
                description={'Deseja deletar esse ponto de classe?'}
                open={openDialog}
                handleConfirm={handleDialogConfirm}
                handleClose={handleDialogClose}
            />
        </ACard>
    );
}

export default function PointClassDataGridHome({
                                                   teleObjectList,
                                                   dataForm,
                                                   equipmentClass,
                                                   toSaveObjects,
                                                   idEquipmentClass,
                                                   refreshNewRowsToSave
                                               }: Readonly<PointClassDataGridHomeProps>) {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <PointClassDataGrid teleObjectList={teleObjectList} dataForm={dataForm} equipmentClass={equipmentClass} toSaveObjects={toSaveObjects} idEquipmentClass={idEquipmentClass} refreshNewRowsToSave={refreshNewRowsToSave}/>
        </QueryClientProvider>
    )
}