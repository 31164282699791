import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../Config/Reducers/ReducerUtils";
import {DefaultState, RequestError} from "../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {CountryEntity} from "./Country";

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<CountryEntity>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/countries';

// Actions
export const searchEntities = createAsyncThunk<
    any,
    PagedSearchParams | undefined,
    {
        serializedErrorType: RequestError
    }
>(
    'country/search_entity',
    async (params : PagedSearchParams | undefined, thunkAPI) => {
        return axios.get(
            apiUrl + "/search",
            {
                params: params ? params.toURLSearchParams() : null
            }
        );
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'country/fetch_entity',
    async (id : number) => {
        return axios.get<CountryEntity>(`${apiUrl}/${id}`);
    },
    { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
    'country/create_entity',
    async (entity: CountryEntity, thunkAPI) => {
        const result = await axios.post(apiUrl, entity);
        thunkAPI.dispatch(searchEntities(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
    'country/update_entity',
    async (entity: CountryEntity, thunkAPI) => {
        const result = await axios.put(`${apiUrl}`, entity);
        thunkAPI.dispatch(searchEntities(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
    'country/partial_update_entity',
    async (entity: CountryEntity, thunkAPI) => {
        const result = await axios.patch(`${apiUrl}/${entity.id}`, entity);
        thunkAPI.dispatch(searchEntities(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
    'country/delete_entity',
    async (id : number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete(requestUrl);
        // thunkAPI.dispatch(getEntities(undefined));
        return result;
    },
    { serializeError: serializeAxiosError }
);

// Slices
const countryReducer = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clearCountryEntity: (state) => {
            state.entity = {id: 0, name: ""};
        },
        clearCountryMessages: (state) => {
            state.successMessage = undefined;
            state.errorMessage = undefined;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(searchEntities), (state, action) => {
                    state.loading = false;
                    state.saving = false;

                    state.successMessage = undefined;
                    state.errorMessage = undefined;

                    state.entities = action.payload.data.data;
                    state.entity = undefined;

                    state.totalOfPages = action.payload.data.totalOfPages;
                    state.totalOfRecords = action.payload.data.totalOfRecords;
                    state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                    state.pageNumber = action.payload.data.pageNumber;
                    state.recordsPerPage = action.payload.data.recordsPerPage;

            })
            .addMatcher(isPending(searchEntities, getEntity), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntities), (state:DefaultState, action) => {
                state.loading = false;
                state.successMessage = undefined;
                state.errorMessage = action.error.detail;

            })
            .addMatcher(isRejected(getEntity), (state, action) => {
                state.loading = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            })
            .addMatcher(isFulfilled(createEntity, getEntity, updateEntity, deleteEntity, partialUpdateEntity), (state, action) => {
                state.loading = false;
                state.saving = false;
                state.entity = action.payload.data.data;
                state.successMessage = action.payload.data.message;
                state.errorMessage = undefined;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = true;
            })
            .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
                state.saving = false;
                state.successMessage = undefined;
                // @ts-ignore
                state.errorMessage = action.error.response.data.detail;
            });
    },
})

export const {
    reset,
    clearCountryEntity,
    clearCountryMessages
} = countryReducer.actions;
export const selectCountry = (state: RootState) => state.country;

// Reducer
export default countryReducer.reducer;
