import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";


const apiUrl = "api/users";

const fetchUsers = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(apiUrl, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching echart data:", error);
        return Promise.reject(error);
    }
};

export const UsersReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchUsers(pagedSearchParams);
        }
    });
}

const saveUsers = async (data: any) => {
    try {
        if(data.id){
            return await axios.put(`${apiUrl}/${data.id}`, data,{timeout:30000}).then((res) => res.data);
        } else {
            return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
    } catch (error) {
        console.error("Error fetching users data:", error);
        return Promise.reject(error);
    }
};

export const SaveUsersReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveUsers(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Usuário salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar usuário: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteUsers = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteUsersReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteUsers(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Usuário excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir usuário: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchUsersById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching users data:", error);
        return Promise.reject(error);
    }
};

export const FindUsersByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchUsersById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um usuário: ${error.message}`, { variant: "error" });
        }
    })
};


