import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    IconButton,
    Stack, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {Skid} from "../../../../Shared/Types/PowerStation"
import {sortSkid} from "../PowerStationFormPage"

interface Props {
    data: Array<Skid>;
    onSaveSkid?:any;
    onDeleteSkid?:any;
    deletedIds: Array<number>;
}
export const PowerStationFormSkidPage = ({data, onSaveSkid,onDeleteSkid, deletedIds}:Props)  => {
    const [inputValue, setInputValue] = useState('');
    const [skidList, setSkidList] = useState<Array<Skid>>(data);
    const [idsDeleted, setIdsDeleted] = useState<Array<number>>(deletedIds);

    const handleAddItem = () => {
        if (inputValue.trim()) {
            // @ts-ignore
            const newItem:Skid = {
                id: null,
                name: inputValue.trim(),
                numberOfEquipment: 0
            }
            const newSkidList = [...skidList, newItem]
            const sortedSkids = sortSkid(newSkidList)
            setSkidList(sortedSkids);

            setInputValue('');
        }
    };

    useEffect(() => {
        if(data != null && data.length > 0){
            const sortedSkids = sortSkid(data)
            setSkidList(sortedSkids)
        }
    }, []);

    useEffect(() => {
        onDeleteSkid(idsDeleted)
    }, [idsDeleted]);

    const handleOnDelete = (value:any) => {
        const idDeleted = skidList.filter((_,idx) => idx === value)[0].id
        if(idDeleted != null){
            setIdsDeleted(prevState => [...prevState, idDeleted])
        }
        setSkidList(skidList.filter((_,idx) => idx != value))
    }

    useEffect(() => {
        if(skidList != null){
            onSaveSkid(skidList)
        }
    }, [skidList]);


    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={1} alignItems="center" sx={{ pb: 2, maxWidth: '600px' }}>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <TextField
                        label="Adicionar item"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        variant="outlined"
                        sx={{
                            width: '60%',
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                '& input': {
                                    padding: '8px 14px',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '0.875rem',
                                top: '-6px',
                                textAlign: 'left',
                                width: '100%',
                            },
                            '& .MuiInputLabel-shrink': {
                                fontSize: '0.75rem',
                            }
                            }}
                    />
                    <IconButton
                        color="primary"
                        onClick={handleAddItem}
                        sx={{ ml: 1, alignSelf: 'center' }} // Alinha o botão verticalmente com o campo de texto
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
                <Grid item xs={12}>
                    <TableContainer sx={{ width: '50%' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '8px 16px' }}
                                    >
                                        <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                            <Box>
                                                {'Skids'}
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '8px 16px' }}
                                    >
                                        <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                            <Box>
                                                {'N.º de Equipamentos'}
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{ minWidth: 10, backgroundColor: 'rgba(4,104,190,0.92)', color: 'white', fontSize: '0.875rem', padding: '2px 6px' }}
                                    >
                                        <Stack spacing={0} sx={{ m: 0, p: 0 }}>
                                            <Box>
                                                {'Ações'}
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(skidList) && skidList.length > 0 ? skidList.map((column, index) => (
                                    <TableRow key={index.valueOf()}>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                fontSize: '0.875rem',
                                                padding: '8px 16px',
                                                height: '40px',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {column.name}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                fontSize: '0.875rem',
                                                padding: '8px 16px',
                                                height: '40px',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            {column.numberOfEquipment}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                fontSize: '0.875rem',
                                                padding: '2px 6px',
                                                height: '40px',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            <IconButton edge="end" aria-label="delete" onClick={() => { handleOnDelete(index); }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}