import React, {FunctionComponent, MouseEvent, useCallback, useEffect, useRef, useState} from "react";

import {
    ACard,
    ADataGrid,
    ADataGridColumn,
    ADataGridFilter,
    FloatMenuButton,
    FloatMenuItem,
    APopoverIcon,
    PagedSearchParams,
} from "@atiautomacao/ati-ui-library";

import {extractFiltersFromColumns} from "../../../../../Utils/DataUitils";
import {RootState} from "../../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../../Config/Hooks";
import {searchEntitiesGroup, clearGroup} from "./AlarmGroupReducer";
import {AlarmRow} from "../../AlarmPage"
import {Alarm, alarmAcknowledge, alarmFinalize, AlarmFinalizeArgs} from "../../AlarmReducer"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {BorderColor, MoreVert} from "@mui/icons-material";
import {isArray} from "lodash";
import {DateFormat, LocalDateTimeFormatISO, validateDateFormatISO} from "../../../../../Utils/DateFormatPatternUtils";
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Modal,
    useTheme
} from "@mui/material";
import {usePowerStationNavigation} from "../../../../../Shared/Hooks/usePowerStationNavigation";
import {useSkidNavigation} from "../../../../../Shared/Hooks/useSkidNavigation";
import {checkGroupingType, handleOnColor} from '../../../../../Utils/AlarmsService'
import AlarmNotion from "../AlarmNotion";
import {isValid} from "date-fns";
import {useSnackbar} from "notistack";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {Storage} from "../../../../../Utils/StorageUtils";

const initColumns = [
    {
        name: 'id',
        label: 'ID',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'description',
        label: 'descrição',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'severityName',
        label: 'severidade',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'macroRegionName',
        label: 'macro',
        align: 'left',
        minWidth: 10,
        visibility: false,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'regionName',
        label: 'região',
        align: 'left',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'powerStationName',
        label: 'usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'skidName',
        label: 'skid',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'equipmentName',
        label: 'equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'dateTime',
        label: 'início',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "dateTime",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'clearDate',
        label: 'finalizado',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "clearDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'acknowledgementDate',
        label: 'reconhecido',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
        filter: {
            name: "acknowledgementDate",
            condition: "starts-at",
            value: "",
            sort: "none",
            placeholder: 'dd/MM/yyyy HH:mm:ss'
        }
    },
    {
        name: 'userName',
        label: 'usuário',
        align: 'left',
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
] as ADataGridColumn[]

const style = {
    position: 'absolute' as 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    bgcolor: 'background.paper',
    border: '1px solid #666',
    // borderRadius: '25px'
}

const AlarmGroup: FunctionComponent<{alarm:any}> = ({alarm} ) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const isLoading = useAppSelector((state : RootState) => state.alarmGroupOverview.loading);
    const totalOfRecords = useAppSelector((state : RootState) => state.alarmGroupOverview.totalOfRecords);
    const entities = useAppSelector((state: RootState) => state.alarmGroupOverview.entities);
    const navigationToPowerStation = usePowerStationNavigation();
    const navigationToSkid = useSkidNavigation();

    const [rows, setRows] = useState<Array<AlarmRow>>([]);
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const [open, setOpen] = React.useState(false);
    const [entityToModel, setEntityToModel] = useState<object>();
    const [selectedRows, setSelectedRows] = useState<any>();

    const AUTH_TOKEN_KEY = 'api-token-key';
    const token = Storage.local.get(AUTH_TOKEN_KEY);
    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const username = tokenData.preferred_username;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {

        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const handleOnRowSelect = useCallback((row: any) => {
        console.log(`handleOnRowSelect()`, row);
    }, []);

    useEffect(() => {
        dispatch(clearGroup());
        if (!isLoading  && alarm ) {
            pagedSearchParams.filters.push(...checkGroupingType(alarm))
            dispatch(searchEntitiesGroup({params:pagedSearchParams}));
        }
    }, [alarm])

    const formatISO = (date: string) => {
        if(isValid(new Date(date))){
            return LocalDateTimeFormatISO(date)
        }
        const dateTime =  validateDateFormatISO(date);
        if(isValid(new Date(dateTime))){
            return LocalDateTimeFormatISO(dateTime)
        }
        enqueueSnackbar("Data invalida!", {variant: 'error'})
        return "";
    }

    const paramsRef = useRef(false);
    useEffect(() => {
        dispatch(clearGroup());
        if(paramsRef.current){
            if (alarm ) {
                if(pagedSearchParams.filters[8] && pagedSearchParams.filters[8].value.length > 0){
                    pagedSearchParams.filters[8].value = formatISO(pagedSearchParams.filters[8].value);
                }

                if(pagedSearchParams.filters[9] && pagedSearchParams.filters[9].value.length > 0){
                    pagedSearchParams.filters[9].value = formatISO(pagedSearchParams.filters[9].value);
                }

                if(pagedSearchParams.filters[10] && pagedSearchParams.filters[10].value.length > 0){
                    pagedSearchParams.filters[10].value = formatISO(pagedSearchParams.filters[10].value);
                }
                pagedSearchParams.filters.push(...checkGroupingType(alarm))
                dispatch(searchEntitiesGroup({ params:pagedSearchParams}));
            }
        }else {
            paramsRef.current = true;
        }
    },[pagedSearchParams]);

    const handleNavigationPowerPlant = (event: MouseEvent<HTMLElement>, powerPlantId: number): void => {
        event.preventDefault();
        navigationToPowerStation(powerPlantId);
    }

    const handleNavigationSkid = (event: MouseEvent<HTMLElement>, powerStationId: number, skidId: number): void => {
        event.preventDefault();
        navigationToSkid(powerStationId, skidId);
    }

    const structureRow = (entitiesValues: Array<Alarm>) => {
        let entity: Array<AlarmRow> = []
        entitiesValues.forEach( alarm => {
            entity.push({
                id: alarm.id.toString(),
                teleObjectId: alarm.teleObjectId,
                severityId: alarm.severityId,
                severityName: alarm.severityName,
                severityColor: alarm.severityColor,
                description: alarm.description,
                macroRegionName: alarm.macroRegionName,
                regionName: alarm.regionName,
                powerStationId: alarm.powerStationId,
                powerStationName: <Link href="#" onClick={(event) => handleNavigationPowerPlant(event, alarm.powerStationId)} underline="hover">{alarm.powerStationName}</Link>,
                skidId: alarm.skidId,
                skidName:<Link href="#" onClick={(event) => handleNavigationSkid(event, alarm.powerStationId, alarm.skidId)} underline="hover">{alarm.skidName}</Link>,
                equipmentId: alarm.equipmentId,
                equipmentName: alarm.equipmentName,
                clearDate: alarm.clearDate ? DateFormat(alarm.clearDate) : "",
                acknowledgementDate: alarm.acknowledgementDate ? DateFormat(alarm.acknowledgementDate) : "",
                dateTime: DateFormat(alarm.dateTime),
                userName: alarm.userName,
                group: false,
                actionsConfig: [
                    {actionName: "note", disabled: false},
                    {actionName: "acknowledge", disabled: alarm.acknowledgementDate != null},
                    {actionName: "finalize", disabled: alarm.clearDate != null}
                ],
            });
        });
        // Remove recent alarm from overview table
        //entity.shift();
        setRows(entity);
    }

    useEffect(() => {
        if (isArray(entities) && entities.length > 1) {
            structureRow(entities);
        }else{
            setRows([])
        }
    }, [entities]);

    const columnsRef = useRef(false);
    useEffect(() => {
        if(!columnsRef.current){
            columnsRef.current = true;
        }
    },[initColumns]);

    const handleOnNotionOpen = useCallback(async (data: any) => {
        if(data) {
            setEntityToModel(data);
            setOpen(true);
        }
    }, []);

    // close modal
    const handleCloseModal = (status: any | undefined) => {
        setOpen(false);
    }

    const finalizeAlarms = useCallback(async () => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmFinalize(params))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(clearGroup());
                dispatch(searchEntitiesGroup({params:pagedSearchParams}));
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmFinalize({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmFinalize.fulfilled.match(actionResult)){
                dispatch(clearGroup());
                dispatch(searchEntitiesGroup({params:pagedSearchParams}));
            }
        }
    },[selectedRows]);


    const alarmAcknowledges = useCallback(async() => {
        handleClose()
        if(isArray(selectedRows)){
            const rowSelected = selectedRows.filter((row) => row.selected)
            const params:AlarmFinalizeArgs = {
                username: username,
                alarmsListId: rowSelected.map((row) => Number(row.data.id))
            }
            const actionResult = await dispatch(alarmAcknowledge(params))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clearGroup());
                dispatch(searchEntitiesGroup({params:pagedSearchParams}));
            }
        }else if(selectedRows){
            const actionResult = await dispatch(alarmAcknowledge({username: username, alarmsListId: [Number(selectedRows.id)]}))
            if(alarmAcknowledge.fulfilled.match(actionResult)){
                dispatch(clearGroup());
                dispatch(searchEntitiesGroup({params:pagedSearchParams}));
            }
        }
    },[selectedRows]);

    const handleClickOpen = (rows:any) => {
        setSelectedRows(rows);
        setDialogOpen(true);
    };

    const handleAcknowledgeClick = (rows:any) => {
        setSelectedRows(rows);
        setAcknowledgeDialog(true)
    }

    const handleClose = () => {
        setDialogOpen(false);
        setAcknowledgeDialog(false);
    };

    return (
        <Box sx={style}>
            <ACard
                title="Agrupamento de alarme"
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVert/>}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </FloatMenuButton>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton disabled={true}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >

                <ADataGrid
                    headerStyle={{ backgroundColor: theme.palette.primary.main, color: '#fff', textTransform: 'capitalize' }}
                    hideFilters={true}
                    showFilterOptions={false}
                    hideSelection={false}
                    size={"small"}
                    columns={initColumns}
                    rows={rows}
                    page={pagedSearchParams.page}
                    loading={false}
                    totalOfRecords={totalOfRecords? totalOfRecords-1 : 0}
                    rowsPerPage={pagedSearchParams.size}
                    onFiltersChange={handleOnFiltersChange}
                    onRowSelect={handleOnRowSelect}
                    onColorSelect={handleOnColor}
                    actionColumnWidth={160}
                    actions={
                        <>
                            <IconButton name="note" aria-label="Border color" onClick={handleOnNotionOpen}>
                                <BorderColor sx={{ fontSize: 26 }} />
                            </IconButton>
                            <IconButton name="acknowledge" size={"small"} aria-label="Fact check" onClick={handleAcknowledgeClick}>
                                <Tooltip title="Reconhecer alarme">
                                    <CheckCircleIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton name="finalize" size={"small"} aria-label="Border color" onClick={handleClickOpen}>
                                <Tooltip title="Finalizar alarme">
                                    <CancelIcon />
                                </Tooltip>
                            </IconButton>
                        </>
                    }
                />
                <Modal
                    open={open}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AlarmNotion alarm={entityToModel} />

                </Modal>
                <Dialog open={dialogOpen || acknowledgeDialog} onClose={handleClose}>
                    <DialogTitle>{`Confirmar ${dialogOpen ? 'finalização' : 'reconhecimento'}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Tem certeza de que deseja {dialogOpen ? 'finalizar' : 'reconhecer'} este alarme?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button onClick={dialogOpen ? finalizeAlarms : alarmAcknowledges} autoFocus>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </ACard>
        </Box>

    )

}

export default AlarmGroup;
