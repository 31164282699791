export const validateEmail = (value:string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
};

export const validateNumber = (value:string) => {
    const isNumber = /^-?\d+(\.\d{1,6})?$/
    return isNumber.test(value) || value === ''
};

export const validateLatitude = (value:string) => {
    const latitudeRegex = /^-?([1-8]?\d(\.\d{1,7})?|90(\.0{1,7})?)$/;
    return latitudeRegex.test(value);
}

export const validateLongitude = (value:string) => {
    const longitudeRegex = /^-?((1[0-7]\d|[1-9]?\d)(\.\d{1,7})?|180(\.0{1,7})?)$/;
    return longitudeRegex.test(value);
}

