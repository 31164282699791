import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../../../Config/Reducers/ReducerUtils";
import {DefaultState} from "../../../../../../Config/Types";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {Alarm} from "../../AlarmSkidReducer";

const initialState: DefaultState = {
    loading: false,
    saving: false,

    errorMessage: undefined,
    successMessage: undefined,

    entities: new Array<Alarm>(),
    entity: undefined,

    totalOfPages: 0,
    totalOfRecords: 0,
    totalOfRequestedRecords: 0,
    pageNumber: 0,
    recordsPerPage: 20,
};

const apiUrl = 'api/alarm/grouping/search';


export const searchEntities = createAsyncThunk<
        any,
        {
            params: PagedSearchParams | undefined;
        }
    >(
    'skid/alarms/grouping/search_entities',
    async ({params}) => {

        return axios.get(
            apiUrl,
            {
                params: params ? params.toURLSearchParams() : null
            }
        );

    },
    { serializeError: serializeAxiosError }
);

// Slices
const GroupingBySkidReducer = createSlice({
    name: 'GroupingBySkidReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                id: 0,
                severityId: 0,
                severityName: "",
                severityColor: "",
                description: "",
                macroRegionName: "",
                regionName: "",
                powerStationId: 0,
                powerStationName: "",
                skidId: 0,
                skidName: "",
                equipmentId: 0,
                equipmentName: "",
                clearDate: "",
                acknowledgementDate: "",
                acknowledgementNote: "",
                dateTime: "",
                userName: ""
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(searchEntities), (state, action) => {
                state.loading = false;
                state.saving = false;

                state.successMessage = undefined;
                state.errorMessage = undefined;

                state.entities = action.payload.data.data;
                state.entity = undefined;

                state.totalOfPages = action.payload.data.totalOfPages;
                state.totalOfRecords = action.payload.data.totalOfRecords;
                state.totalOfRequestedRecords = action.payload.data.totalOfRequestedRecords;
                state.pageNumber = action.payload.data.pageNumber;
                state.recordsPerPage = action.payload.data.recordsPerPage;

            })
            .addMatcher(isPending(searchEntities), state => {
                state.loading = true;
                state.successMessage = undefined;
                state.errorMessage = undefined;
            })
            .addMatcher(isRejected(searchEntities), (state:DefaultState, action) => {
                state.loading = false;
                state.successMessage = undefined;
                state.errorMessage = action.error.message;
            })
    },
})

export const { reset, clear } = GroupingBySkidReducer.actions;
export const selectAlarmGrouping = (state: RootState) => state.groupingBySkid;

// Reducer
export default GroupingBySkidReducer.reducer;