import React, { FunctionComponent, useEffect, useState } from "react";
import { RootState } from "../../../../Config/Store";
import { useAppDispatch, useAppSelector } from "../../../../Config/Hooks";
import {clear, getEntity, WeatherStation} from "./WeatherStationPlantReducer";
import Gauge, {GaugeSeriesOption} from "./Gauge/Gauge";
import { Box, Grid } from "@mui/material";
import {useEquipmentWithoutSkidNavigation} from "../../../../Shared/Hooks/useEquipmentWithoutSkidNavigation";
import {ACard, Skeleton} from "@atiautomacao/ati-ui-library"
import useInterval from "../../../../Shared/Hooks/useInterval";



const WeatherStationPlant: FunctionComponent<{powerPlantId: any}> = ({powerPlantId}) => {

    const entities : WeatherStation = useAppSelector((state: RootState) => state.weatherStationPlant.entity);
    const { loading  } = useAppSelector((state: RootState) => state.weatherStationPlant);
    const dispatch = useAppDispatch();
    const navigateToEquipment = useEquipmentWithoutSkidNavigation();
    const [seriesGauge, setSeriesGauge] = useState<Array<{title: string, seriesGauge: GaugeSeriesOption}>>([]);

    const extractWeather = () => {
        // Rule of intervals at 100%
        const TICK = 3;
        const ONEINTERVAL = ((100 / TICK) / 100).toFixed(2);
        const TWOINTERVAL = (1 - parseFloat(ONEINTERVAL)).toFixed(2);

        // Definindo valores padrão para os componentes
        const defaultWeather: Array<{ title: string, seriesGauge: GaugeSeriesOption }> = [
            {
                title: 'POA',
                seriesGauge: {
                    value: 0,
                    unity: "W/m²",
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    max: 1000
                },
            },
            {
                title: 'GHI',
                seriesGauge: {
                    value: 0,
                    unity: "W/m²",
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    max: 1200
                },
            },
            {
                title: 'Temperatura do módulo (ºC)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'ºC',
                    max: 80
                },
            },
            {
                title: 'Temperatura do Ar (ºC)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'ºC',
                    max: 80
                },
            },
            {
                title: 'Velocidade do Vento (m/s)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [ONEINTERVAL, '#a4f657'],
                        [TWOINTERVAL, '#f0a12b'],
                        [1, '#e02222'],
                    ],
                    unity: 'm/s',
                    max: 14
                },
            },
            {
                title: 'Umidade do Ar (%)',
                seriesGauge: {
                    value: 0,
                    intervalsTicks: [
                        [1, '#f0a12b'],
                    ],
                    unity: '%',
                    max: 100
                },
            },


        ];

        let weather = [...defaultWeather];
        if(entities?.values?.length > 0){
            for (let entity of entities.values) {
                if (entity.name.includes("POA")) {
                    weather[0].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("GHI")) {
                    weather[1].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Temperatura do Módulo")) {
                    weather[2].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Temperatura do Ar")) {
                    weather[3].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Velocidade do Vento")) {
                    weather[4].seriesGauge.value = Math.trunc(entity.value);
                } else if (entity.name.includes("Umidade do Ar")) {
                    weather[5].seriesGauge.value = Math.trunc(entity.value);
                }
            }
        }
        if(weather.length > 0) {
            setSeriesGauge(weather);
        } else {
            setSeriesGauge(defaultWeather);
        }
    }

    useEffect(() => {
        dispatch(clear())
        dispatch(getEntity(powerPlantId));
    }, [powerPlantId]);

    useInterval(() => {
        dispatch(clear())
        dispatch(getEntity(powerPlantId));
    }, 60000) // 5 minutes

    useEffect(() => {
        extractWeather();
    }, [entities]);

    const handleNodeClick = (): void => {
        if(entities){
            navigateToEquipment(entities.id);
        }
    }

    return (
        <ACard
            cardStyle={{ height: 700, width:'100%' }}
            onClick={handleNodeClick}
            cursorType={'pointer'}
            title="Estação Meteorológica"
        >
            {
                loading ?
                    <Skeleton animation="wave" height={400} variant="rounded" width="100%" />
                    :
                    <Box sx={{ flexGrow: 3, cursor: 'pointer' }} onClick={handleNodeClick}>
                        <Grid container spacing={0} justifyContent="center">
                            {Array.isArray(seriesGauge) && seriesGauge.map((element) => (
                                <Grid item key={element.title} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 200, height: 200 }}>
                                    <Box style={{ width: '100%', height: '100%', marginBottom: '100px' }}>
                                        <Gauge series={element.seriesGauge} title={element.title} />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
            }
        </ACard>
    );

}

export default WeatherStationPlant;