import {QueryClient, QueryClientProvider} from "react-query";
import {Box, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {
    ACard,
    ADataGrid,
    ADataGridColumn,
    ADataGridFilter,
    PagedSearchParams
} from "@atiautomacao/ati-ui-library";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmDialog from "../../../Shared/Components/ConfirmDialog";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {extractFiltersFromColumns} from "../../../Utils/DataUitils";
import {HeaderMenu} from "../HeaderMenu";
import {DeleteUnifilarReactQuery, GraphElementListReactQuery} from "./GraphElementReactQuery";

export const initColumns = [
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
] as ADataGridColumn[]

export type ActionConfig = {
    actionName: string;
    disabled: boolean;
};

export interface UnifilarRow {
    powerStationId: number;
    skidId: number;
    name: string;
    type: string;
}

export interface RowUnifilar {
    id: number;
    name: string;
    type: string;
    actionsConfig?: Array<ActionConfig>
}

export const GraphElementSettingsPage = () => {
    const [pagedSearchParams, setPagedSearchParams] = useState(new PagedSearchParams(extractFiltersFromColumns(initColumns), 0, 100));
    const [totalOfRecords, setTotalOfRecords] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedToDelete, setSelectedToDelete] = useState<any>(null);
    const [rows, setRows] = useState<Array<RowUnifilar>>([]);

    const {data, isSuccess} = GraphElementListReactQuery(pagedSearchParams);

    const mutationDelete = DeleteUnifilarReactQuery(pagedSearchParams);

    const navigate = useNavigate();

    useEffect(() => {
        if(isSuccess && data) {
            let resultRows = data?.data.map((row: UnifilarRow) => {
                return {
                    powerStationId: row.powerStationId,
                    skidId: row.skidId ? row.skidId : null,
                    name: row.name,
                    type: row.type,
                    actionsConfig: [
                        {actionName: "edit", disabled: false},
                        {actionName: "delete", disabled: false},
                    ]
                }
            });
            setRows(resultRows);
            setTotalOfRecords(data.data.length);
        }
    }, [data, isSuccess]);

    const handleOnFiltersChange = useCallback((dataGridFilters: Array<ADataGridFilter>, page: number, pageSize: number) => {
        let newPageParams: PagedSearchParams = new PagedSearchParams(dataGridFilters, page, pageSize);
        setPagedSearchParams(
            newPageParams
        );
    }, []);

    const handleEditUnifilar = useCallback((data: any) => {
        navigate(SystemRoutes.SETTINGS_GRAPH_ELEMENT_FORM, { state: { data: data } });
    },[navigate]);

    const handleDeleteUnifilar = useCallback((data: any) => {
        setSelectedToDelete(data);
        setOpenDialog(true);
    },[]);

    const handleDialogConfirm = async () => {
        let idDelete = selectedToDelete.type === "USINA" ? selectedToDelete.powerStationId : selectedToDelete.skidId;
        if(idDelete) {
            mutationDelete.mutate({ id: idDelete, view: selectedToDelete.type, pagedSearchParams });
        }
        setSelectedToDelete(null);
        handleDialogToggle();
    }

    const handleDialogToggle = () => {
        setOpenDialog(!openDialog);
    }

    const handleDialogClose = () => {
        handleDialogToggle();
    }

    return (
        <>
            <HeaderMenu systemRoutes={SystemRoutes.SETTINGS_GRAPH_ELEMENT_FORM}/>
            <Box style={{paddingTop: 64}}>
                <ACard>
                    <ADataGrid
                        columns={initColumns}
                        rows={rows}
                        page={pagedSearchParams.page}
                        rowsPerPage={pagedSearchParams.size}
                        loading={false}
                        totalOfRecords={totalOfRecords}
                        hideSelection={true}
                        onFiltersChange={handleOnFiltersChange}
                        actions={
                            <>
                                <IconButton name="edit" size={"small"} aria-label="Edit" onClick={(value) => handleEditUnifilar(value)}>
                                    <Tooltip title="Editar">
                                        <Edit/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton name="delete" size={"small"} aria-label="Delete" onClick={(value) => handleDeleteUnifilar(value)}>
                                    <Tooltip title="Excluir">
                                        <Delete />
                                    </Tooltip>
                                </IconButton>
                            </>
                        }
                    />
                </ACard>
                <ConfirmDialog
                    title={'Deletar'}
                    description={'Deseja excluir esse mapa Unifilar?'}
                    open={openDialog}
                    handleConfirm={handleDialogConfirm}
                    handleClose={handleDialogClose}
                />
            </Box>
        </>
    )
}

export default function GraphElementSettingsPageHome() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <GraphElementSettingsPage/>
        </QueryClientProvider>
    )
}
