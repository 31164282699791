import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, isRejectedWithValue} from '@reduxjs/toolkit';
import axios, {AxiosRequestConfig} from 'axios';
import {DashboardPowerPlantChartsState} from "../DashboardPowerPlantCharts";
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {getEntities, getEntity} from "../PowerPUForInvertersChart/PowerPUForInvertersReducer";

const initialState: DashboardPowerPlantChartsState = {
    generatedEnergy: [],
    loading: false,
    errorMessage: null
};

const apiUrl = 'api/generation-energy';

// Actions
export const getGeneratedEnergyDay = createAsyncThunk<any, any, {rejectValue: any }>(
    'dashboard_power_plant_charts/generated_energy',
    async ({id, startDate, endDate}) => {
        const config: AxiosRequestConfig = {
            url: `${apiUrl}/power-station/${id}/day`,
            params: {
                startDateTime: startDate.toJSON().split('.')[0],
                endDateTime: endDate.toJSON().split('.')[0],
            },
            method: 'GET'
        };

        return axios(config);
    },
    { serializeError: serializeAxiosError }
);
export const getGeneratedEnergyMonth = createAsyncThunk<any, any, {rejectValue: any }>(
    'dashboard_power_plant_charts/generated_energy',
    async ({id, startDate, endDate}) => {
        const config: AxiosRequestConfig = {
            url: `${apiUrl}/power-station/${id}/month`,
            params: {
                startDateTime: startDate.toJSON().split('.')[0],
                endDateTime: endDate.toJSON().split('.')[0],
            },
            method: 'GET'
        };

        return axios(config);
    },
    { serializeError: serializeAxiosError }
);
export const getGeneratedEnergyYear = createAsyncThunk<any, any, {rejectValue: any }>(
    'dashboard_power_plant_charts/generated_energy',
    async ({id, startDate, endDate}) => {
        const config: AxiosRequestConfig = {
            url: `${apiUrl}/power-station/${id}/year`,
            params: {
                startDateTime: startDate.toJSON().split('.')[0],
                endDateTime: endDate.toJSON().split('.')[0],
            },
            method: 'GET'
        };

        return axios(config);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const dashboardPowerPlantChartsReducer = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(isFulfilled(getGeneratedEnergyDay), (state, action) => {
                state.generatedEnergy = action.payload.data;
                state.loading = false;
            })
            .addMatcher(isFulfilled(getGeneratedEnergyMonth), (state, action) => {
                state.generatedEnergy = action.payload.data;
                state.loading = false;
            })
            .addMatcher(isFulfilled(getGeneratedEnergyYear), (state, action) => {
                state.generatedEnergy = action.payload.data;
                state.loading = false;
            })
            .addMatcher(isPending(getGeneratedEnergyDay, getGeneratedEnergyMonth, getGeneratedEnergyYear), state => {
                state.loading = true;
            })
            .addMatcher(isRejectedWithValue(getGeneratedEnergyDay, getGeneratedEnergyMonth, getGeneratedEnergyYear), state => {
                state.loading = false;
                state.errorMessage = "Error ao carregar os dados de Energia Gerada";
            })
    },
})

export const { reset } = dashboardPowerPlantChartsReducer.actions;

// Reducer
export default dashboardPowerPlantChartsReducer.reducer;
