import {QueryClient, QueryClientProvider} from "react-query";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    FindTeleObjectTypeByIdReactQuery,
    SaveTeleObjectTypeReactQuery
} from "./TeleObjectTypeReactQuery";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import {AppBar, Button, Container, FormHelperText, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {ACard} from "@atiautomacao/ati-ui-library";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import Toolbar from "@mui/material/Toolbar";
import {CancelOutlined} from "@mui/icons-material";

export const TeleObjectTypeFormPage = () => {


    const location = useLocation();
    const id = location.state?.id as string;
    console.log("id: ", id)
    const [teleObjectName, setTeleObjectName] = useState<string>("");



    const mutationSave = SaveTeleObjectTypeReactQuery();
    const mutationFindById = FindTeleObjectTypeByIdReactQuery();
    const navigate = useNavigate();

    function handleSave() {
        if(teleObjectName === ""){
            return;
        }
        const objectToSave = id ? {id: Number(id), name: teleObjectName} : {name: teleObjectName};
        mutationSave.mutate(objectToSave, {
            onSuccess: () => {
                navigate(SystemRoutes.SETTINGS_TELE_OBJECT_TYPE);
            }
        });
        console.log(teleObjectName);
    }

    const handleBack = () => {
        navigate(SystemRoutes.SETTINGS_TELE_OBJECT_TYPE);

    }

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    setTeleObjectName(data?.data.name);
                }
            });
        }
    }, []);

    return (
        <>
            <AppBar position="fixed" style={{top: 64}}>
                <Toolbar style={{backgroundColor: 'white', boxShadow: '0px 3px 6px #00000029'}}>
                    <Button
                        variant="contained"
                        size={"small"}
                        color={"primary"}
                        endIcon={<SaveIcon/>}
                        style={{marginLeft: '22%'}}
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                    <Button
                        variant="outlined"
                        size={"small"}
                        color={"primary"} // Use "secondary" para cor vermelha
                        endIcon={<CancelOutlined />} // Ícone de voltar
                        style={{marginLeft: '1%'}} // Ajuste a margem conforme necessário
                        onClick={handleBack} // Função para lidar com a ação de voltar
                    >
                        Cancelar
                    </Button>
                </Toolbar>
            </AppBar>

            <Box marginTop={10}>
                <ACard>
                    <Container style={{marginLeft: -10, display: 'flex', flexDirection: "row", alignItems: 'left', justifyContent: 'left', columnGap: 30}}>

                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="object-name">Nome</InputLabel>
                            <TextField
                                error={teleObjectName === ""}
                                fullWidth
                                id="object-name"
                                variant="standard"
                                placeholder={"Nome do tipo do teleobjeto"}
                                onChange={(value) => setTeleObjectName(value.target.value)}
                                value={teleObjectName}
                            />
                            {
                                teleObjectName === "" &&
                                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
                            }
                        </Box>
                    </Container>
                </ACard>
            </Box>

        </>

    )
}



export default function TeleObjectTypePageForm(){
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <TeleObjectTypeFormPage />
        </QueryClientProvider>
    )
}