import React, {useEffect, useState} from "react"
import {Box, Button, Card, CardActions, CardContent, Container, FormGroup, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {clearCountryMessages, createEntity, getEntity, updateEntity} from './CountryReducer';
import {useSnackbar} from "notistack";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import CountryHeaderMenu from "./CountryHeaderMenu";

const CountrySave = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [isNew] = useState(params.id === undefined);
    const [entity, setEntity] = useState({id: 0, name: ""});

    const countryEntity = useAppSelector((state : RootState) => state.country.entity);
    const isLoading = useAppSelector((state : RootState) => state.country.loading);
    const isSaving = useAppSelector((state : RootState) => state.country.saving);
    const errorMessage = useAppSelector((state : RootState) => state.country.errorMessage);

    const handleChange = (event:any) => {
        let name = event.target.name;
        let value = event.target.value;
        setEntity((oldValue:any) => {
            return {
                ...oldValue,
                [name]: value
            }
        })
    }

    const saveEntity = () => {
        if (isNew) {
            dispatch(createEntity(entity)).then(success => {
                return navigate("/settings/country");
            }).catch(error => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
                dispatch(clearCountryMessages());
            });
        } else {
            dispatch(updateEntity(entity)).then(success => {
                return navigate("/settings/country");
            }).catch(error => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error',
                });
                dispatch(clearCountryMessages());
            });
        }
    };

    const goBack = () => {
        dispatch(clearCountryMessages());
        return navigate("/settings/country");
    }

    // On Mount
    useEffect(()=> {
        if (params.id === undefined || params.id === "new") {
            console.log("RESET");
            setEntity({id: 0, name: ""});
            // setEntity(new CountryEntity(0, ""));
            // dispatch(reset());
        } else {
            console.log("get entity");
            dispatch(getEntity(params.id));
        }
    },[dispatch, isNew, params]);

    // On countryEntity change
    useEffect(()=> {
        setEntity(prevEntity => {
            return {...prevEntity, ...countryEntity}
        });
    },[countryEntity]);

    return (
        <>
            <CountryHeaderMenu />
            <Container maxWidth={'lg'}>
                <Card sx={{ minWidth: 275 }}>

                    <CardContent>
                        { isLoading ? ( <p>Loading...</p>) : (
                            <React.Fragment>
                                <FormGroup row >
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="standard"
                                        value={entity.name}
                                        size={"small"}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </React.Fragment>
                        )}

                    </CardContent>
                    <CardActions>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Button variant="outlined" startIcon={<ArrowBack />} onClick={goBack}>Back</Button>
                        <Button variant="outlined" startIcon={<SaveIcon />} disabled={isSaving} onClick={saveEntity}>Save</Button>
                    </CardActions>
                </Card>
            </Container>
        </>
    );

}

export default CountrySave;
