import {createSlice, PayloadAction} from '@reduxjs/toolkit';

enum DashboardView {
    Overview,
    PowerPlant,
    Skid,
    Equipment
}

interface DashboardState {
    view: DashboardView;
    selectedPowerPlant?: any;
    selectedSkid?: any;
    selectedEquipment?: any;
}

const initialState: DashboardState = {
    view: DashboardView.Overview,
    selectedPowerPlant : undefined,
    selectedSkid: undefined,
    selectedEquipment: undefined
};

// Slice / Reducer
const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setPowerPlant: (state, action: PayloadAction<any>) => {
            state.selectedPowerPlant = action.payload
        },
        resetPowerPlant: (state) => {
            state.selectedPowerPlant = undefined;
        },
        setSkid: (state, action: PayloadAction<any>) => {
            state.selectedSkid = action.payload
        },
        resetSkid: (state) => {
            state.selectedSkid = undefined
        },
        setEquipment: (state, action: PayloadAction<any>) => {
            state.selectedEquipment = action.payload
        },
        resetEquipment: (state, action: PayloadAction<any>) => {
            state.selectedEquipment = undefined
        },
        reset() {
            return initialState;
        },
    }
})

export const {
    reset,
    resetSkid,
    resetPowerPlant,
    setPowerPlant,
    setSkid,
    setEquipment
} = DashboardSlice.actions;

// Reducer
export default DashboardSlice.reducer;
