import {
    faBan,
    faAsterisk,
    faArrowLeft,
    faBell,
    faBook,
    faCalendarDays,
    faCaretRight,
    faChartBar,
    faChartLine,
    faCircleExclamation,
    faCircleQuestion,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFileLines,
    faFire,
    faFlag,
    faHardDrive,
    faHeart,
    faHome,
    faHouseChimney,
    faList,
    faListCheck,
    faLock,
    faMagnifyingGlass,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSolarPanel,
    faSort,
    faSquarePollHorizontal,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
    library.add(
        faCircleExclamation,
        faCalendarDays,
        faSolarPanel,
        faMagnifyingGlass,
        faCaretRight,
        faAngleRight,
        faCircleQuestion,
        faFileLines,
        faHardDrive,
        faChartLine,
        faListCheck,
        faChartBar,
        faSquarePollHorizontal,
        faFire,
        faHouseChimney,
        faArrowLeft,
        faAsterisk,
        faBan,
        faBell,
        faBook,
        faCloud,
        faCogs,
        faDatabase,
        faEye,
        faFlag,
        faHeart,
        faHome,
        faList,
        faLock,
        faPencilAlt,
        faPlus,
        faRoad,
        faSave,
        faSignInAlt,
        faSignOutAlt,
        faSearch,
        faSort,
        faSync,
        faTachometerAlt,
        faTasks,
        faThList,
        faTimesCircle,
        faTrash,
        faUser,
        faUserPlus,
        faUsers,
        faUsersCog,
        faWrench,
    );
};
