import React, {useEffect, useState} from "react";
import {RootState} from "../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../Config/Hooks";
import {Generation, getEntities} from "./GenerationEnergyChartReducer.reducer";
import {format, isValid, parse} from 'date-fns';
import {ptBR} from 'date-fns/locale';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import {downloadFile} from "../../../Utils/DownloadUtils";
import {truncateNumber} from "../../../Utils/NumberUtil";
import {GATEWAY_URL} from "../../../Config/Constants";
import {
    ACard,
    AChartX,
    FloatMenuItem,
    APopoverIcon,
    Period,
    PeriodSelector,
    Skeleton,
    XAxis,
    YAxis
} from "@atiautomacao/ati-ui-library";
import {checkDiffDays} from "../../../Utils/DataUitils";
import DataNotFound from "../../../Shared/Components/DataNotFoundMessage";
import useInterval from "../../../Shared/Hooks/useInterval";

export function GenerationEnergy() {

    const generationEntity: Array<Generation> = useAppSelector((state: RootState) => state.generationEnergyChart.entities);
    const { loading } = useAppSelector((state: RootState) => state.generationEnergyChart);
    const dispatch = useAppDispatch();

    // Setting range start period
    const initialPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const [period, setPeriod] = useState<Period>(initialPeriod);

    // returns the greatest max of the axes
    const max = () => {
        const maxGeneratedEnergy = Array.isArray(generationEntity) ? Math.max(...generationEntity.map(item => item.generatedEnergy ? truncateNumber(item.generatedEnergy) : item.generatedEnergy)) : 0
        const maxExpectedInjectedEnergy = Array.isArray(generationEntity) ? Math.max(...generationEntity.map(item => item.expectedInjectedEnergy ? truncateNumber(item.expectedInjectedEnergy) : item.expectedInjectedEnergy)) : 0
        return maxGeneratedEnergy > maxExpectedInjectedEnergy ? truncateNumber(maxGeneratedEnergy) : truncateNumber(maxExpectedInjectedEnergy);
    }

    // return the group by
    const handleOnGroupByTypeOfPeriod = (valueTypePeriod: string) => {
        if (valueTypePeriod.includes('week') || valueTypePeriod.includes('month')) {
            return 'day';
        } else if (valueTypePeriod.includes('year')) {
            return 'month';
        }
        return 'year';
    }

    const [xAxis, setXAxis] = useState<XAxis>();
    const [yAxis, setYAxis] = useState<YAxis>();
    const [generatedEnergy, setGeneratedEnergy] = useState<Array<number>>([])
    const [expectedInjectedEnergy, setExpectedInjectedEnergy] = useState<Array<number>>([])

    function findGeneratedEnergyDataByPeriod() {

        let datePeriod: any[] = [];
        if (period.groupBy.valueOf() === "week" || period.groupBy.valueOf() === "month" || period.groupBy.valueOf() === "none") {
            datePeriod = Array.isArray(generationEntity) ? generationEntity
                .map(item => format(new Date(item.dateTime+"T00:00:00"), "dd/MM")) : [];
        } else if (period.groupBy.valueOf() === "year") {
            datePeriod = Array.isArray(generationEntity) ? generationEntity
                .map(item => format(parse(item.dateTime, 'yyyy-MM', new Date()), "MMM", {locale: ptBR})) : []
        } else if (period.groupBy.valueOf() === "general" ) {
            datePeriod = generationEntity.map(item => isValid(new Date(item.dateTime)) &&
                format(new Date(item.dateTime+"T00:00:00"), 'yyyy')
            );
        }

        const newNameGap = () => {
            if (period.groupBy) {
                switch (period.groupBy) {
                    case "week":
                        return 46;
                    case "month":
                        return 45;
                    case "year":
                        return 46;
                    case "general":
                        return 65;
                    default:
                        return 40;
                }
            }
            return 40;
        };


        setXAxis({
            type: 'category',
            name: handleOnGroupByTypeOfPeriod(period.groupBy),
            data: datePeriod,
            nameGap: 30,
            nameLocation: 'middle'
        });
        setExpectedInjectedEnergy(generationEntity ? generationEntity.map(item => item.expectedInjectedEnergy ? truncateNumber(item.expectedInjectedEnergy, 2) : 0) : [])
        setGeneratedEnergy(generationEntity ? generationEntity.map(item => item.generatedEnergy ? truncateNumber(item.generatedEnergy, 2) : 0) : [])
        setYAxis({
            type: 'value',
            name: 'Energia Gerada',
            max: max(),
            min: 0,
            interval: max() / 2,
            nameGap: newNameGap(),
            nameLocation: "middle"
        })
    }

    useEffect(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(getEntities(period));
        }
    }, [period]);

    useInterval(() => {
        if(checkDiffDays(period.toDateTime, period.fromDateTime) > 0){
            dispatch(getEntities(period));
        }
    }, 60000); //5 minutes

    useEffect(() => {
        if(generationEntity){
            findGeneratedEnergyDataByPeriod();
        }

    }, [generationEntity]);

    const handleDownloadCSV = () => {
        const valueTypePeriod = handleOnGroupByTypeOfPeriod(period.groupBy);

        const params = {
            startDateTime: format(period.fromDateTime ? period.fromDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
            endDateTime: format(period.toDateTime ? period.toDateTime : new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss')
        }
        const apiUrl = GATEWAY_URL+`/api/generation-energy/csv/${valueTypePeriod}?`
        downloadFile(apiUrl, params, 'energia-gerada.csv');
    };

    // @ts-ignore
    const option: AChartXProps['option'] = {
        color: ['rgb(25, 118, 210)', 'red'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        legend: {
            show: true,
            orient: 'vertical',
            right: 45,
            top: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            textStyle: {
                opacity: 1
            },
            itemStyle: {
                opacity: 1
            },
            data: ['Energia Gerada', 'Energia Esperada']
        },
        grid: {
            containLabel: true,
            top: 10,
            bottom: 10
        },
        label: {
            precision: 1,
            position: 'right',
            valueAnimation: true,
            fontFamily: 'monospace'
        },
        animationDuration: 2000,
        series: [
            {
                name: 'Energia Gerada',
                data: generatedEnergy,
                type: 'bar',
                unity: 'MWh'
            },
            {
                name: 'Energia Esperada',
                data: expectedInjectedEnergy,
                type: 'line',
                unity: 'MWh',
                smooth: true,
            }
        ],
    };

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);

    return (
        <>
            <ACard
                title="Energia Gerada"
                headerControlsPosition={"header"}
                headerControls={
                    <>
                        <PeriodSelector
                            styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                            mode="hybrid"
                            hideDatetime={false}
                            hideGroup={false}
                            disableHourSelector={true}
                            inputFormat={'dd/MM/yyyy'}
                            periodInterval={{
                                week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
                                month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
                                year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
                                general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
                            }}
                            disabled={loading}
                            period={period}
                            onChange={setPeriod}
                            groupByOptions={["week", "month", "year", "general"]}
                        />
                    </>
                }
                // headerActions={
                //     <>
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </>
                // }
                footerActions={
                    <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                        <IconButton onClick={handleDownloadCSV}>
                            <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                        </IconButton>
                    </APopoverIcon>
                }
            >
                <Grid item display={"flex"} justifyContent="center" alignItems="center" xs={12}>
                    {
                        loading ?
                            <Skeleton animation="wave" height={280} variant="rounded" width={"100%"} />
                            :
                            generationEntity && generationEntity.length > 0 && xAxis && yAxis ?
                                <AChartX
                                    key={`card-overview-energy-${openSubMenu}`}
                                    option={option}
                                    height={280}
                                    loading={false} />
                                :
                                <DataNotFound boxStyle={{height: 280, width: '100%'}}/>
                    }
                </Grid>
            </ACard>
        </>
    );
}
