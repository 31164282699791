import {ChartState} from "../../../../../Config/Types";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import axios from "axios";
import {LocalDateTimeFormatISO} from "../../../../../Utils/DateFormatPatternUtils";
import {serializeAxiosError} from "../../../../../Config/Reducers/ReducerUtils";
import {RootState} from "../../../../../Config/Store";

export class GenerationRateInverter {
    equipmentName: string;
    generationRate: number;

    constructor(equipmentName: string, generationRate: number) {
        this.equipmentName = equipmentName;
        this.generationRate = generationRate;
    }

}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<GenerationRateInverter>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/skid';

export const getEntities = createAsyncThunk<any, any>(
    'generation-skid/fetch_entities_list',
    async ({period, skidId}) => {
        const url = `${apiUrl}/generation-rate-of-equipments/${skidId}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`;
        return axios.get<GenerationRateInverter>(url);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-skid/fetch_entity',
    async () => {
        return axios.get<GenerationRateInverter>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const GenerationRateOfInvertersReducer = createSlice({
    name: 'GenerationRateOfInvertersReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                skidId: 0,
                equipmentName: "",
                generationRate: 0
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        // @ts-ignore
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities), (state, action) => {
                state.loading = false;
                // @ts-ignore
                state.errorMessage = action.error;
            })
            .addMatcher(isFulfilled(getEntity, getEntities), (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data;
                state.errorMessage = null;
            })
            .addMatcher(isRejected(getEntities, getEntity), state => {
                state.errorMessage = "Erro ao carregar dados de Taxa de Geração";
                state.loading = false;
            })
    },
});

export const { reset,clear } = GenerationRateOfInvertersReducer.actions;

export const selectedEquipmentGenerationChart = (state: RootState) => state.generationRateByInverter;

export default GenerationRateOfInvertersReducer.reducer

