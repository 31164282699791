import React, {FunctionComponent, useEffect, useState} from 'react';

import {
   Autocomplete, Box, Select
} from "@mui/material";
import { SkidProps } from '../InverterPage';
import TextField from "@mui/material/TextField";

const SelectInverter: FunctionComponent<{ onSelectedElement: any, data: Array<SkidProps> }> = ({onSelectedElement, data}) => {

    const options = () => {
        let dataStructure: {firstLetter: string, name: string, id: number }[] = [];
        data.map((option) => {
            let firstLetter = option.name.toUpperCase();
            option.equipmentList.map(equip => {
                dataStructure.push(
                    {
                        firstLetter: firstLetter,
                        id: equip.id,
                        name: equip.name,
                    });
            })

        })

        return dataStructure;
    };

    const [checked, setChecked] = useState<any>(options()[0]);

    useEffect(() => {
        if(data && data.length > 0) {
            let option = options()[0]
            option = {
                ...option,
                // name: option.firstLetter,
            }
            setChecked(option);
        }
    }, [data]);

    const handleChange = (event: any, newValue: any) => {
        if(newValue) {
            setChecked( {
                ...newValue,
            })
        }
        onSelectedElement(newValue?.id, `${newValue?.firstLetter + " - " + newValue?.name}`);
    };
    const handleClose = (reason: any) => {
        if(!reason) {
            setChecked( null)
            onSelectedElement();
        }
    };

    useEffect(() => {
        if(checked !== null) {
            onSelectedElement(checked?.id, `${checked?.firstLetter + " - " + checked?.name}`);
        }
    }, [checked]);

    return (
        <Box sx={{marginBottom: 2}}>
            <Autocomplete
                id="grouped-demo"
                value={checked}
                options={options().sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                fullWidth
                inputValue={checked}
                onClose={(event, reason) => handleClose(event)}
                renderInput={(params) => <TextField {...params}  label={"Inversores"} variant="standard"/>}
                onChange={(event: any, newValue: any) => handleChange(event, newValue)}
            />
        </Box>

    )
}

export default SelectInverter;
