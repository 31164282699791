import {ChartState} from "../../../../Config/Types";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import axios from "axios";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {RootState} from "../../../../Config/Store";

export class GenerationRateSkid {
    skidName: string;
    generationRate: number;

    constructor(skidName: string, generationRate: number) {
        this.skidName = skidName;
        this.generationRate = generationRate;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<GenerationRateSkid>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-station';

export const getEntities = createAsyncThunk<any, any>(
    'generation-skid/fetch_entities_list',
    async ({period, idPowerPlant}) => {
        const url = `${apiUrl}/generation-Rate-of-Skids/${idPowerPlant}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`;
        return axios.get<GenerationRateSkid>(url);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-skid/fetch_entity',
    async () => {
        return axios.get<GenerationRateSkid>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const GenerationRateChartReducer = createSlice({
    name: 'GenerationRateChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                skidName: "",
                generationRate: 0
            };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), (state: ChartState, action) => {
                state.loading = false;
                state.entities = [];
                state.errorMessage = "Erro ao carregar dados de Taxa de Geração";
            })
    },
});

export const { reset } = GenerationRateChartReducer.actions;

export const selectedSkidGenerationChart = (state: RootState) => state.generationRateBySkid;

// Reducer
export default GenerationRateChartReducer.reducer;

