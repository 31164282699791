import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs} from "@mui/material";
import GenerationYield from "../GenerationYieldChart/GenerationYield";
import GenerationYieldComparison from "../GenerationYieldComparisonChart/GenerationYieldComparison";
import React, {useEffect, useState} from "react";
import {
    ACard,
    FloatMenuItem,
    APopoverIcon,
    Period,
    PeriodSelector,
    PeriodSelectorGroupBy, ACardSplitFooterActions
} from "@atiautomacao/ati-ui-library";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faExpand,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faGear,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";

import {addDays} from 'date-fns';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, m: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default function YeldChartComparison() {

    let [yieldValue, setYieldValue] = useState<number>(1);
    const initialYieldPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };
    const initialYieldComparisonPeriod: Period = {
        groupBy: "week",
        fromDateTime: new Date(),
        toDateTime: new Date()
    };

    const yieldGroupsInterval = {
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
        general: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 7}
    };
    const yieldGroups : PeriodSelectorGroupBy[] = ["week", "month", "year", "general"];

    const yieldComparisonGroupsInterval={
        day: {date: new Date(), startTime: "00:00:00", endTime: "23:59:59"},
        week: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 6},
        month: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 30},
        year: {startTime: "00:00:00", endTime: "23:59:59", startInterval: 12},
    };

    const yieldComparisonGroups: PeriodSelectorGroupBy[] = ["day", "week", "month", "year"];

    const [period, setPeriod] = useState<Period>(yieldValue === 0 ? initialYieldPeriod : initialYieldComparisonPeriod);

    const handleTabChange = (period: Period) => {
        // if(yieldValue === 0){
        //     period.groupBy = initialYieldPeriod.groupBy;
        // }else {
        //     period.groupBy = initialYieldComparisonPeriod.groupBy;
        // }
        setPeriod(period);
    }
    useEffect(() => {
    }, [period]);

    const handleChangeYield = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setYieldValue(parseInt(value));
    };

    return (
        <ACard
            title="Produtividade"
            headerControlsPosition={"header"}
            headerControls={
            <>
                <PeriodSelector
                    styleProps={{base: {flexDirection: "row", display: "flex", alignItems: "center"}}}
                    mode="hybrid"
                    hideDatetime={false}
                    disableHourSelector={true}
                    inputFormat={'dd/MM/yyyy'}
                    hideGroup={false}
                    periodInterval={yieldValue === 0 ? yieldGroupsInterval : yieldComparisonGroupsInterval}
                    period={period}
                    onChange={handleTabChange}
                    groupByOptions={yieldValue === 0 ? yieldGroups : yieldComparisonGroups}
                />
            </>
        }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //             disable={true}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //             disable={true}
            //         />
            //     </>
            // }
            contentStyle={{paddingTop: 0}}
            footerActions={
                <ACardSplitFooterActions
                    leftActions={
                        <FormControl>
                            <RadioGroup
                                style={{marginLeft: 10}}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="controlled-radio-buttons-group"
                                value={yieldValue}
                                onChange={handleChangeYield}
                            >
                                <FormControlLabel value={0} control={<Radio />} label="Comparativo" />
                                <FormControlLabel value={1} control={<Radio />} label="Absoluto" />
                            </RadioGroup>
                        </FormControl>
                    }
                    leftGridWidth={9}
                    rightActions={
                        <FormControl size={"small"}>
                            <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                                <IconButton disabled={true}>
                                    <FontAwesomeIcon icon={faFilePdf} fontSize={20}/>
                                </IconButton>
                                <IconButton disabled={true}>
                                    <FontAwesomeIcon icon={faFileCsv} fontSize={20}/>
                                </IconButton>
                                <IconButton disabled={true}>
                                    <FontAwesomeIcon icon={faFileExcel} fontSize={20}/>
                                </IconButton>
                            </APopoverIcon>
                        </FormControl>

                    }
                    rightGridWidth={3}
                />
            }
        >
            { yieldValue === 0 ?
                <GenerationYield period={period}/>
                :
                <GenerationYieldComparison period={period}/>
            }
        </ACard>
    )
}