import { useEffect, useRef } from 'react';


//@author Francisco Rubens
const useInterval = (callback: () => void, delay: number, extraDelay?: number) => {
    const savedCallback = useRef<() => void | null>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                if (extraDelay) {
                    setTimeout(() => {
                        if (savedCallback.current) {
                            savedCallback.current();
                        }
                    }, extraDelay);
                } else {
                    savedCallback.current();
                }
            }
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay, extraDelay]);
};

export default useInterval;
