import * as React from 'react';
import {useEffect, useState} from 'react';
import {DashboardNavigationAnalysis} from "../../../../Modules/Analysis/types/DashboardNavigationAnalysis";
import DashboardMenuSkeleton from "./components/DashboardMenuSkeleton/DashboardMenuSkeleton";
import {
    reset,
    updateSelectedStates
} from "../../../../Modules/Analysis/types/reducer/DashboardNavigationAnalysisReducer";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {RootState} from "../../../../Config/Store";
import {
    DashBoardNavigationAnalysisSelectedId
} from "../../../../Modules/Analysis/types/reducer/DashboardNavigationAnalysisMenuState";
import {NavigationMenu} from "@atiautomacao/ati-ui-library";

export default function SGDAnalysisMenu() {

    const [dashboardNavigationAnalysisMenu, setDashboardNavigationAnalysisMenu] = useState<DashboardNavigationAnalysis[] | undefined>();
    const [selectedId, setSelectedId] = useState<DashBoardNavigationAnalysisSelectedId | undefined>();
    const dispatch = useAppDispatch();
    const { entities, isLoading , selectedIds} = useAppSelector(
        (state: RootState) => state.dashboardNavigationMenuAnalysis
    );

    useEffect(() => {
        dispatch(reset())
    }, [dispatch]);

    useEffect(() => {
        if(entities){
            setDashboardNavigationAnalysisMenu(entities)
        }else{
            setDashboardNavigationAnalysisMenu([])
        }
    }, [entities]);

    useEffect(() => {
        dispatch(updateSelectedStates(selectedId))
    }, [selectedId]);

    const handleNodeClick = (nodes:any) =>{
        setSelectedId(nodes)
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <DashboardMenuSkeleton />
            ) : <NavigationMenu data={dashboardNavigationAnalysisMenu ?? []} onNodeClick={handleNodeClick}/>}
        </React.Fragment>
    );

}