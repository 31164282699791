import React, {useLayoutEffect} from 'react';
import {logout} from "../../Config/Reducers/AuthenticationReducer";
import {useSnackbar} from "notistack";
import {Navigate} from "react-router-dom";
import {useAppDispatch} from "../../Config/Hooks";
import {hideSubMenu} from "../../Config/Reducers/LayoutReducer";

export const Logout = (props) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
      dispatch(logout());
      dispatch(hideSubMenu())

      return () => enqueueSnackbar("Logged out successfully!", {variant: 'success'});
  });

  return (
      <Navigate
          to="/"
      />
  );
};

export default Logout;
