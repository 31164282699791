import {Box, Paper} from "@mui/material";
import {useAppSelector} from "../../../Config/Hooks";
import TotalizerAlarmsPlantSkid from "./TotalizerAlarmsPlantSkid/TotalizerAlarmsPlantSkid";
import AlarmPage from "./Alarms/AlarmPage";
import React from "react";

export default function DashboardPowerPlantSkidAlarmsPage(){

    const {selectedPowerPlant, selectedSkid} = useAppSelector((state) => state.dashboard);
    return(
        <>
            {selectedPowerPlant && selectedSkid &&
                <TotalizerAlarmsPlantSkid skidId={selectedSkid.id}/>}
            <Paper elevation={3}>
                <Box p={2}>
                    {selectedSkid ? <AlarmPage skidId={selectedSkid.id}/> : null}
                </Box>
            </Paper>
        </>

    );
}
