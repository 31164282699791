import React, {FunctionComponent, useEffect} from "react";

import {ACard, FloatMenuButton, FloatMenuItem, Skeleton, Totalizer} from '@atiautomacao/ati-ui-library';
import {Construction, NotificationImportant, PriorityHigh, QuestionMark, SolarPower} from '@mui/icons-material';


import {getEntity} from "./TotalizerAlarmsPlantSkidReducer";
import {RootState} from "../../../../Config/Store";
import {useAppDispatch, useAppSelector} from "../../../../Config/Hooks";
import {Container} from "../../../../Shared/Components/AlarmTotalizers/AlarmTotalizers.theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import {Box, Grid} from "@mui/material";
import useInterval from "../../../../Shared/Hooks/useInterval";

const TotalizerAlarmsPlantSkid: FunctionComponent<{skidId: any}> = ({skidId}) => {

    const totalizers  = useAppSelector((state: RootState) => state.totalizerAlarmsPlantSkid.totalizers);
    const isLoading  = useAppSelector((state: RootState) => state.totalizerAlarmsPlantSkid.isLoading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getEntity({skidId: skidId}));
    }, []);

    useInterval(() => {
        dispatch(getEntity({skidId: skidId}));
    }, 60000) // 5 minutes

    if (isLoading) {
        return (
            <Box style={{paddingBottom: 20}}>
                <ACard
                    title={"Performance"}
                    // headerActions={
                    //     <FloatMenuButton
                    //         icon={<MoreVertIcon/>}
                    //         tooltip={"Floating Menu"}
                    //     >
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                    //             text="Settings"
                    //             disable={true}
                    //             link={"/"}
                    //         />
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                    //             text="Expand"
                    //             disable={true}
                    //         />
                    //         <FloatMenuItem
                    //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                    //             text="Print"
                    //             disable={true}
                    //         />
                    //     </FloatMenuButton>
                    // }
                >
                    <Container data-testid="loading-skeleton">
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                        <Skeleton animation="wave" height={100} variant="rectangular" width={200} />
                    </Container>
                </ACard>
            </Box>
        );
    }

    return (
        <Box style={{paddingBottom: 20}}>
            <ACard
                title={"Performance"}
                // headerActions={
                //     <FloatMenuButton
                //         icon={<MoreVertIcon/>}
                //         tooltip={"Floating Menu"}
                //     >
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
                //             text="Settings"
                //             disable={true}
                //             link={"/"}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
                //             text="Expand"
                //             disable={true}
                //         />
                //         <FloatMenuItem
                //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
                //             text="Print"
                //             disable={true}
                //         />
                //     </FloatMenuButton>
                // }
            >
                <Grid container spacing={2}>
                    {totalizers && (
                        <>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.powerStationOff}
                                    label="Usinas Fora do Ar"
                                    cardIcon={<SolarPower style={{fontSize:38}}/>}
                                    background="#0288d1"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.equipmentsOff}
                                    label="Equipamentos Fora do Ar"
                                    cardIcon={<Construction style={{fontSize:38}}/>}
                                    background="#00bcd4"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.equipmentsAlarmed}
                                    label="Equipamentos Alarmados"
                                    cardIcon={<Construction style={{fontSize:38}}/>}
                                    background="#ffc107"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.active}
                                    label="Ativos"
                                    cardIcon={<NotificationImportant style={{fontSize:38}}/>}
                                    background="#616161"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.activeCritical}
                                    label="Ativos Críticos"
                                    cardIcon={<PriorityHigh style={{fontSize:38}}/>}
                                    background="#ff0019"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} xs={6} sm={4} md={4} lg={4} xl={2}>
                                <Totalizer
                                    value={totalizers.notAcknowledged}
                                    label="Não Reconhecidos"
                                    cardIcon={<QuestionMark style={{fontSize:38}}/>}
                                    background="#6B8897"
                                    textColor="#ffffff"
                                    width={200}
                                    boldLabel={true}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </ACard>
        </Box>
    );
}

export default TotalizerAlarmsPlantSkid;
