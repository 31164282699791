import { ADataGridColumn } from "@atiautomacao/ati-ui-library";


const commonBreakpoints = {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true
};

const idBreakpoints = {
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true
};

export const columnsDataGrid: ADataGridColumn[] = [
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'name',
        label: 'Nome',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    }
];

export const columnsCommandHistoryDataGrid: ADataGridColumn[] = [
    {
        name: 'dateTime',
        label: 'Data',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'powerStationName',
        label: 'Usina',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'equipmentName',
        label: 'Equipamento',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'alarmedName',
        label: 'Telecomando',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'value',
        label: 'Valor',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
    {
        name: 'id',
        label: 'ID',
        align: 'center',
        visibility: false,
        minWidth: 10,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        }
    },
    {
        name: 'userName',
        label: 'Usuário',
        align: 'left',
        minWidth: 10,
        visibility: true,
        breakpoints: {
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true
        },
    },
];




