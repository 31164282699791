import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

export class GenerationEntity {

    injectedEnergy: number;
    dateTime: string;
    generatedEnergy: number;

    constructor(injectedEnergy: number, dateTime: string, generatedEnergy: number, ) {
        this.injectedEnergy = injectedEnergy;
        this.dateTime = dateTime;
        this.generatedEnergy = generatedEnergy;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<GenerationEntity>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/skid/generation-skid';

// api/generation-enregy/nome-parametro/${powestationId}/${skidId}

// TODO change the any type to the proper payload type
export const getEntities = createAsyncThunk<any, any, any>(
    'generation-energy-skid/entity_list',
    async ({period, powerPlant, skid}) => {
        let groupBy:string = 'day';
       if( period.groupBy.includes('year')) {
            groupBy = 'month';
        } else if( period.groupBy.includes('general')) {
            groupBy = 'year';
        }
        return axios.get<GenerationEntity>(`${apiUrl}/${groupBy}?skidId=${skid}&startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-energy-skid/entity',
    async () => {
        return axios.get<GenerationEntity>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const generationEnergySkidChartReducer = createSlice({
    name: 'generationEnergySkidChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = { injectedEnergy:0, dateTime:"", generatedEnergy:0};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
                state.entities = [];
            })
            .addMatcher(isRejected(getEntities, getEntity), state => {
                state.errorMessage = "Erro ao carregar dados de Energia Gerada vs Injetada";
                state.loading = false;
            })
    },
})

export const { reset } = generationEnergySkidChartReducer.actions;
export const selectGenerationEnergySkidChartReducer = (state: RootState) => state.generationEnergySkid;
// Reducer
export default generationEnergySkidChartReducer.reducer;