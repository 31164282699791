
export const truncateNumber = (value: number, decimalPlaces: number = 0): number => {
    return Number(value.toFixed(decimalPlaces));
};

/**
 * 824 -> 900
 * 1130 -> 1200
 * @param value
 * @return
 */
export const roundNextHundred = (value: number) => {
    if (value === null) {
        return 0.0
    }
    return truncateNumber(Math.ceil((value / 100)) * 100);
}

/**
 * 1650 -> 2000
 * @param value
 * @return
 */
export const roundNextThousand = (value: number) => {
    if (value === null) {
        return 0.0
    }
    return truncateNumber(Math.ceil((value / 1000)) * 1000);
}
/**
 *  input 23 -> Output = 30
 *  input 6 -> Output = 10
 *  input 1953 - > Output 1960
 * @param value
 * @return
 */
export const roundNextTen = (value: number) => {
    if (value === null) return 0.0;
    const round = Math.ceil(value);
    return truncateNumber(Math.ceil(((round + 9) / 10) * 10));
}