import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from "@reduxjs/toolkit";
import axios from "axios";
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {Tracker} from "../../../../Shared/Types/Tracker";
import {ChartNcu} from "../../../../Shared/Types/ChartNcu";
import {format} from "date-fns";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

interface State {
    loading: boolean;
    errorMessage: string | null;
    entities: NcuEquipment[];
    entity: any;
    updating: false;
    updateSuccess: false;
    idsNcu: number[];
    periodTrackers: any | null
}
export class NcuEquipment {
    id: number;
    name: string;
    windSpeed: number;
    windDirection: number;
    trackers: Tracker[];
    chart: ChartNcu;
    ncuName: string;

    constructor(id: number, name: string, windSpeed: number, windDirection: number, trackers: Tracker[], chart: ChartNcu, ncuName: string) {
        this.id = id;
        this.name = name;
        this.windSpeed = windSpeed;
        this.windDirection = windDirection;
        this.trackers = trackers;
        this.chart = chart;
        this.ncuName = ncuName;
    }
}

const initialState: State = {
    loading: false,
    errorMessage: null,
    entities: new Array<NcuEquipment>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
    idsNcu: [],
    periodTrackers: null
}

const apiUrl = 'api/power-station'

export const getEntities = createAsyncThunk<any, any>(
    'power Plant/fetch_entity_list',
    async ({powerPlantId, id, periodTrackers}) => {
        return axios.get<NcuEquipment>(`${apiUrl}/trackers/${powerPlantId}?ncuId=${id}&startDateTime=${LocalDateTimeFormatISO(periodTrackers.fromDateTime ? periodTrackers.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(periodTrackers.toDateTime ? periodTrackers.toDateTime : new Date())}`, {
            timeout: 30000
        });
    },
    { serializeError: serializeAxiosError }
);

export const getNcus = createAsyncThunk<any, any>(
    'power plant/fetch_ncu_list',
    async (id) => {
        return axios.get<number[]>(`${apiUrl}/listNcu/${id}`);
    }
)

const TrackerReducer = createSlice({
    name: 'TrackerReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        clearEntities: (state) => {
            state.entity = undefined
        },
        clearIds: (state) => {
            state.idsNcu = []
        },
        setPeriodTrackers: (state, action) => {
            state.loading = false;
            state.periodTrackers = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                let dados = action.payload.data.data;
                let chart = dados.chart;
                let chartSeries = chart && chart.series.length > 0 ? chart.series : [];
                let eixoX = chart.xAxis[0];

                let windowSize = window.innerWidth;

                eixoX.minInterval = windowSize < 771 ? 10800000 : 3600000;
                eixoX.maxInterval = windowSize < 771 ? 10800000 : 3600000;

                eixoX.axisLabel = {
                    formatter: function (value:any) {
                        return format(new Date(value), 'HH:mm')
                    },
                }
                let eixoY = chart.yAxis[0];
                eixoY.axisLabel = {
                    formatter: '{value} °'
                }
                eixoY.min = -180;
                eixoY.max = 180;

                if(chartSeries && chartSeries.length > 0){
                    let data: any[] = [];
                    let dataMarkArea: any[][] = [];
                    let alarms = chartSeries[0].markArea;
                    if(alarms.length > 0){
                        for (let i = 0; i < alarms.length; i = i + 2){
                            let alarme1 = alarms[i];
                            let alarme2 = alarms[i + 1];
                            data.push(alarme1);
                            data.push(alarme2);
                            dataMarkArea.push(data);
                            data = [];
                        }
                    }

                    chartSeries.forEach((serie: any) => {
                        serie.data = serie.dataByTime;
                        serie.markArea = {
                            itemStyle: {
                                color: 'rgba(255, 173, 177, 0.4)'
                            },
                            data: dataMarkArea
                        }
                    });
                }

                return {
                    ...state,
                    loading: false,
                    entity: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(getNcus), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    idsNcu: action.payload.data.data
                }
            })
            .addMatcher(isRejected(getEntities, getNcus), (state, action) => {
                let ncu: any = action.error;
                state.entity = ncu.data;
                state.loading = false;
                state.errorMessage = "Dados de Trackers não encontrados.";
            })
    },
});
export const {clearEntities, clearIds, setPeriodTrackers} = TrackerReducer.actions;

export default TrackerReducer.reducer;