import React, {FunctionComponent, useState} from 'react';
import {ACard, APopoverIcon, FloatMenuItem,} from "@atiautomacao/ati-ui-library";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faFileCsv, faGear, faPrint} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import {TextField} from "@mui/material";

import ChartHeatmap from './ChartHeatmap';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {endOfDay, isWithinInterval, startOfDay} from 'date-fns';
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {useAppSelector} from "../../../../Config/Hooks";

const PowerPUForInvertersChart: FunctionComponent<{skid: any;}> = ({skid}) => {

    const openSubMenu = useAppSelector((state: any) => state.layout.openSubMenu);
    const [date, setDate] = useState<Date | null>(startOfDay(new Date()));

    return (
        <ACard
            key={`chart-skid-pu-${openSubMenu}`}
            cardStyle={{ height: 445 }}
            contentStyle={{ padding: 0 }}
            title="Potência PU"
            headerControls={
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Selecione o dia"
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        value={date}
                        inputFormat={"DD/MM/YYYY"}
                        onChange={(newValue) => {
                            if (newValue && isWithinInterval(new Date(newValue), { start: new Date('1980-01-01'), end: endOfDay(new Date()) })) {
                                setDate(startOfDay(new Date(LocalDateTimeFormatISO(newValue))));
                            }
                            return;
                        }}
                    />
                </LocalizationProvider>
            }
            // headerActions={
            //     <>
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faGear} fontSize={20}/>}
            //             text="Settings"
            //             disable={true}
            //             link={"/"}
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faExpand} fontSize={20}/>}
            //             text="Expand"
            //         />
            //         <FloatMenuItem
            //             icon={<FontAwesomeIcon icon={faPrint} fontSize={20}/>}
            //             text="Print"
            //         />
            //     </>
            // }
            footerActions={
                <APopoverIcon icon={<FontAwesomeIcon icon={faDownload} fontSize={20}/>}>
                    <IconButton disabled={true}>
                        <FontAwesomeIcon icon={faFileCsv} fontSize={20} />
                    </IconButton>
                </APopoverIcon>
            }
        >
            {skid && date &&
                <ChartHeatmap
                    skid={skid}
                    date={date}
                />
            }
        </ACard>
    )
}

export default PowerPUForInvertersChart;
