import axios from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";

const apiUrl = "api/graph-element";
const apiUrlNodes = "api/nodes";

const saveUnifilar = async (nodes: any[]) => {
    try {
        return await axios.post(apiUrlNodes, nodes, {timeout: 30000}).then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const SaveUnifilarReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveUnifilar(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Dados da Unifilar salvos com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar dados da unifilar: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchPrepareNewUnifilar = async () => {
    try {
        return await axios
            .get(apiUrl + "/prepare-new-unifilar").then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const PrepareNewUnifilarReactQuery = () => {
    return useQuery({
        queryKey: ["dataNewUnifilar"],
        queryFn: () => {
            return fetchPrepareNewUnifilar();
        }
    });
};

const fetchTeleObjectsByEquipmentId = async(equipmentId: number) => {
    try {
        return await axios.get(`${apiUrl}/teleobjects?equipmentId=${equipmentId}`).then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const FindTeleObjectsByEquipmentReactQuery = () => {
    return useMutation({
        mutationFn: (equipmentId: number) => {
            return fetchTeleObjectsByEquipmentId(equipmentId);
        }
    })
};

const fetchGraphElements = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrlNodes}/list`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const GraphElementListReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchGraphElements(pagedSearchParams);
        }
    });
};

const fetchUnifilarById = async (unifilar: any) => {
    let powerStationId = Number(unifilar?.powerStationId);
    try {
        if(unifilar.type === "SKID") {
            let skidId = unifilar.skidId;
            return await axios.get(`${apiUrlNodes}/skid?skidId=${skidId}&powerStationId=${powerStationId}&isCreating=${true}`, {timeout:30000}).then((res) => res.data);
        } else {
            return await axios.get(`${apiUrlNodes}/power-station/${powerStationId}?isCreating=${true}`, {timeout:30000}).then((res) => res.data);
        }
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const FindUnifilarByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (unifilar: any) => {
            return fetchUnifilarById(unifilar);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar os elementos gráficos: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchAllPowerStation = async () => {
    try {
        return await axios
            .get(`${apiUrl}/get-power-stations`)
            .then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const GetAllPowerStationsReactQuery = () => {
    return useQuery({
        queryKey: ["data"],
        queryFn: () => {
            return fetchAllPowerStation();
        }
    });
};

const deleteUnifilarById = async (id: number, view: string) => {
    try {
        return await axios.delete(`${apiUrlNodes}/${Number(id)}?view=${view}`).then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const DeleteUnifilarReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteUnifilarById(data.id, data.view);
        },
        onSuccess: () => {
            enqueueSnackbar("Unifilar excluída com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir unifilar: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteNodeById = async (idFromNode: number, parentIds: number[]) => {
    try {
        const queryParams = new URLSearchParams({ parentIds: parentIds.join(',') }).toString();
        return await axios.delete(`${apiUrlNodes}/delete-node/${idFromNode}?${queryParams}`).then((res) => res.data);
    } catch (error: any) {
        console.error("Error fetching data:", error);
        return Promise.reject(new Error(error.message, error.code));
    }
};

export const DeleteNodeReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteNodeById(data.idFromNode, data.parentIds);
        },
        onSuccess: () => {
            enqueueSnackbar("Classe de equipamento excluída com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir classe de equipamento: ${error.message}`, { variant: "error" });
        }
    })
}