import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import axios from 'axios';
import {serializeAxiosError} from '../../../../Config/Reducers/ReducerUtils';
import {DashboardNavigationMenuState} from './types/DashboardNavigationMenuState';
import {RootState} from '../../../../Config/Store';
import {DashboardNavigationMenu} from './types/DashboardNavigationMenu';
import {Response} from '../../../../Config/Types';
import { produce } from 'immer';
import { getRandomStatus } from './helpers/getRandomStatus';

const initialState: DashboardNavigationMenuState = {
    isLoading: false,
    errorMessage: null,
    entities: [],
};

const apiUrl = 'api/power-station/navigation-menu';

export const getEntities = createAsyncThunk(
'power-station/navigation-menu/entity_list',
async () => {
    return axios.get<Response<DashboardNavigationMenu>>(apiUrl, {timeout:30000});
},
{ serializeError: serializeAxiosError },
);
const dashboardNavigationMenuReducer = createSlice({
    name: 'dashboardNavigationMenuReducer',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const entities = action.payload.data.data.map(item => {
                    const equipmentListWithPowerStation = item?.equipmentList.map(equipment => ({
                        ...equipment,
                    }));
                    // Mapper equipment list to each skid
                    const skidListWithEquipments = item.skidList.map(skid => {
                        return {
                            ...skid,
                            equipmentList: skid.equipmentList.map(equipment => ({
                                ...equipment,
                            })),
                        };
                    });
                    return {
                        ...item,
                        skidList: skidListWithEquipments,
                        equipmentList: equipmentListWithPowerStation,
                    };
                });

                return {
                    ...state,
                    isLoading: false,
                    entities: entities,
                };
            })
            .addMatcher(isPending(getEntities), state => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
            .addMatcher(isRejected(getEntities), state => {
                return {
                    ...state,
                    isLoading: false,
                    entities: [],
                };
            });
    },
});

export const { reset } = dashboardNavigationMenuReducer.actions;
export const selectGDashboardNavigationMenu = (state: RootState) => state.dashboardNavigationMenu
export default dashboardNavigationMenuReducer.reducer;