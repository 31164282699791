import React, {useEffect, useState} from "react";
import {Box, FormControlLabel, FormHelperText, Grid, InputLabel, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {PowerStationParams} from "../../../../Shared/Types/PowerStation";
import {isValid, startOfDay} from "date-fns";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {validateNumber} from "../../../../Utils/RegexUtils";
import {CustomTextField} from "../../../../Shared/Components/CustomTextField";
import {NumericFormat} from "react-number-format";
interface Props {
    onSaveParams: any,
    data: PowerStationParams;
}
export const PowerStationFormParamsPage = ({onSaveParams, data}: Props) => {
    const [powerCapacityNominal, setPowerCapacityNominal] = useState<any>(data?.powerCapacityNominal);
    const [maxPower, setMaxPower] = useState<any>(data?.maxPower );
    const [expectedGenerateEnergy, setExpectedGenerateEnergy] = useState<any>(data?.expectedGenerateEnergy );
    const [expectedYield, setExpectedYield] = useState<any>(data?.expectedYield );
    const [expectedPerformanceRatio, setExpectedPerformanceRatio] = useState<any>(data?.expectedPerformanceRatio );
    const [expectedIrradiance, setExpectedIrradiance] = useState<any>(data?.expectedIrradiance );
    const [operationStartedAt, setOperationStartedAt] = useState<any>(data?.operationStartedAt );
    const [operationMode, setOperationMode] = useState<any>(data?.operationMode);
    const [meterInterval, setMeterInterval] = useState<any>(data?.meterInterval);
    const [inverterInterval, setInverterInterval] = useState<any>(data?.inverterInterval );
    const [meterConst, setMeterConst] = useState<any>(data?.meterConst );
    const [moduleTempCoef, setModuleTempCoef] = useState<any>(data?.moduleTempCoef );
    const [powerStationParamsData, setPowerStationParamsData] = useState<PowerStationParams>(data);

    useEffect(() => {
        onSaveParams(powerStationParamsData)
    }, [powerStationParamsData]);

    useEffect(() => {
        setPowerStationParamsData({
            ...powerStationParamsData,
            operationStartedAt: operationStartedAt
        })
    }, [operationStartedAt]);

    const handleOperationModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOperationMode((event.target as HTMLInputElement).value);
        setPowerStationParamsData({
            ...powerStationParamsData,
            operationMode: (event.target as HTMLInputElement).value
        })
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { id, value } = e.target;
        console.log("EEE", e)
        setPowerStationParamsData({
            ...powerStationParamsData,
            [id]: validateNumber(value) ? Number(value) : 0
        });
    };

    const validateField = (data:any) => {
        if(data === ""){
            return (
                <FormHelperText id="my-helper-text">Campo Obrigatório.</FormHelperText>
            )
        }
        if(!validateNumber(data)){
            return (
                <FormHelperText id="my-helper-text">Valor inválido.</FormHelperText>
            )
        }
    }



    return (
        <Grid container spacing={2} sx={{paddingLeft: 2}}>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20, paddingTop: 20}}>
                    <InputLabel id="moduleTempCoef">Coeficiente de temperatura do módulo</InputLabel>
                    <NumericFormat
                        id="moduleTempCoef"
                        value={moduleTempCoef}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setModuleTempCoef(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite o coeficiente de temperatura do modulo"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(moduleTempCoef)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20, paddingTop: 20}}>
                    <InputLabel id="powerCapacityNominal">Capacidade Instalada</InputLabel>
                    <NumericFormat
                        id="powerCapacityNominal"
                        value={powerCapacityNominal}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setPowerCapacityNominal(e.target.value);
                            handleInputChange(e)
                        }}
                        placeholder={"Digite a capacidade instalada"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(powerCapacityNominal)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20, paddingTop: 20}}>
                    <InputLabel id="numberOfUG"  style={{marginBottom: 5}}>Numero de skids</InputLabel>
                    <InputLabel id="numberOfUG">{data?.numberOfUG}</InputLabel>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="maxPower">Potência nominal máxima</InputLabel>
                    <NumericFormat
                        id="maxPower"
                        value={maxPower}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setMaxPower(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a potencia máxima"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(maxPower)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="expectedGenerateEnergy">Produção de energia estimada</InputLabel>
                    <NumericFormat
                        id="expectedGenerateEnergy"
                        value={expectedGenerateEnergy}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setExpectedGenerateEnergy(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a Produção de energia estimada"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(expectedGenerateEnergy)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="expectedYield">Produtividade esperada</InputLabel>
                    <NumericFormat
                        id="expectedYield"
                        value={expectedYield}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setExpectedYield(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a Produção de energia estimada"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(expectedYield)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="expectedPerformanceRatio">Taxa de desempenho esperada</InputLabel>
                    <NumericFormat
                        id="expectedPerformanceRatio"
                        value={expectedPerformanceRatio}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setExpectedPerformanceRatio(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a taxa de desempenho esperada"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(expectedPerformanceRatio)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="expectedIrradiance">Taxa de irradiação esperada</InputLabel>
                    <NumericFormat
                        id="expectedIrradiance"
                        value={expectedIrradiance}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setExpectedIrradiance(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a irradiancia esperada"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(expectedIrradiance)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="operationStartedAt">Início de operação</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            renderInput={(params) => <TextField variant="standard" {...params} />}
                            value={operationStartedAt}
                            inputFormat={"DD/MM/YYYY"}
                            onChange={(newValue) => {
                                if (newValue && isValid(new Date(newValue))) {
                                    setOperationStartedAt(startOfDay(new Date(LocalDateTimeFormatISO(newValue))));
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={{ color: 'text.secondary' }}>Modo de Operação</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <RadioGroup
                    row
                    name="operationMode"
                    value={operationMode}
                    onChange={handleOperationModeChange}
                >
                    <FormControlLabel value="L" control={<Radio />} label="Local" />
                    <FormControlLabel value="R" control={<Radio />} label="Remota" />
                </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="meterInterval">Intervalo para coleta de dados do medidor</InputLabel>
                    <NumericFormat
                        id="meterInterval"
                        value={meterInterval}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setMeterInterval(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite o intervalo"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(meterInterval)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="inverterInterval">Intervalo para coleta de dados do inversor</InputLabel>
                    <NumericFormat
                        id="inverterInterval"
                        value={inverterInterval}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setInverterInterval(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite o intervalo"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(inverterInterval)}
                </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box style={{marginBottom: 20}}>
                    <InputLabel id="meterConst">Constante do medidor</InputLabel>
                    <NumericFormat
                        id="meterConst"
                        value={meterConst}
                        customInput={CustomTextField}
                        onChange={(e) => {
                            setMeterConst(e.target.value);
                            handleInputChange(e);
                        }}
                        placeholder={"Digite a constante do medidor"}
                        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                        allowNegative={true}
                    />
                    {validateField(meterConst)}
                </Box>
            </Grid>
        </Grid>
    )
}