import {useMediaQuery, useTheme} from "@mui/material";

export default function useAppBackgroundSetup() {
    const theme = useTheme();
    const extraSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
    const smallScreen = useMediaQuery(theme.breakpoints.only("sm"));
    const mediumScreen = useMediaQuery(theme.breakpoints.only("md"));
    const largeUpScreen = useMediaQuery(theme.breakpoints.only("lg"));
    const extraLargeUpScreen = useMediaQuery(theme.breakpoints.only("xl"));

    const backgroundSetup = () => {
        let htmlElement = document.getElementById("body");
        if (window.location.pathname === "/") {
            if (htmlElement !== null) {
                if (extraLargeUpScreen) {
                    htmlElement.style.backgroundImage = "url('solar-panel-xl.jpeg')";
                    htmlElement.style.backgroundPosition = "top right";
                    htmlElement.style.backgroundRepeat = "no-repeat";
                    htmlElement.style.backgroundSize = "100% 250%";
                } else if (largeUpScreen) {
                    htmlElement.style.backgroundImage = "url('solar-panel-xl.jpeg')";
                    htmlElement.style.backgroundPosition = "top right";
                    htmlElement.style.backgroundRepeat = "no-repeat";
                    htmlElement.style.backgroundSize = "100% 200%";
                } else if (mediumScreen) {
                    htmlElement.style.backgroundImage = "url('solar-panel-xl.jpeg')";
                    htmlElement.style.backgroundPosition = "top right";
                    htmlElement.style.backgroundRepeat = "no-repeat";
                    htmlElement.style.backgroundSize = "160% 190%";
                } else if (smallScreen) {
                    htmlElement.style.backgroundImage = "url('solar-panel-xl.jpeg')";
                    htmlElement.style.backgroundPosition = "top right";
                    htmlElement.style.backgroundRepeat = "no-repeat";
                    htmlElement.style.backgroundSize = "250% 180%";
                } else if (extraSmallScreen) {
                    htmlElement.style.backgroundImage = "url('solar-panel-xl.jpeg')";
                    htmlElement.style.backgroundPosition = "top right";
                    htmlElement.style.backgroundRepeat = "no-repeat";
                    htmlElement.style.backgroundSize = "300% 190%";
                }
            }
        } else {
            if (htmlElement !== null) {
                htmlElement.style.backgroundImage = "none";
                htmlElement.style.backgroundColor = "rgba(213,218,222,0.17)";
                // htmlElement.style.backgroundColor = "#9c0416";
            }
        }
    }
    return backgroundSetup;
}


