import {FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const errorMessage = <FormHelperText id="my-helper-text" style={{color: '#ff3f21'}}>Campo Obrigatório.</FormHelperText>;

interface Normalization {
    value: string;
    index: number;
    id: number | null;
}

export default function AlarmConditionTab({index,alarmSeveritiesList, normalization,alarmedName, alarmSeverity, setNormalizationCondition, setAlarmSeverityCondition, setAlarmedNameCondition, setDeleteItem}: any) {
    const [normalizationValue, setNormalizationValue] = useState(normalization?.value);
    const [alarmedNameValue, setAlarmedNameValue] = useState(alarmedName?.value);
    const [alarmValue, setAlarmValue] = useState(alarmSeverity?.value);

    function handleNormalization(value: any){
        const newNormalization: Normalization = { index: index, value, id: normalization && normalization?.id !== null ? normalization.id : null };
        setNormalizationCondition(newNormalization);
        setNormalizationValue(value);
    }

    function handleAlarm(value: any) {
        const newAlarm = {index: index, value, id: alarmSeverity && alarmSeverity?.id !== null ? normalization.id : null};
        setAlarmSeverityCondition(newAlarm);
        setAlarmValue(value);
    }
    function handleAlarmedName(value: any) {
        const newAlarm = {index: index, value, id: alarmedName && alarmedName?.id !== null ? alarmedName.id : null};
        setAlarmedNameCondition(newAlarm);
        setAlarmedNameValue(value);
    }

    function handleDeleteItem() {
        setDeleteItem(index);
    }

    return (

        <Grid container style={{paddingTop: 27, justifyContent: "space-around"}}>
             <Grid item xs={12} md={6} xl={4}>
                <InputLabel id="class-name">Nome</InputLabel>
                <TextField
                    fullWidth
                    id="class-name"
                    variant="standard"
                    placeholder={"Nome do Estado Alarmado"}
                    onChange={(value) => handleAlarmedName(value.target.value)}
                    value={alarmedNameValue}
                />
            </Grid>
             <Grid item xs={12} md={6} xl={4}>
                <InputLabel id="selectTipoDado">Severidade</InputLabel>
                <Select
                    fullWidth
                    labelId="selectTipoDado"
                    id="selectTeleObjectType"
                    value={alarmValue}
                    label="Tipo de Dados"
                    onChange={(value) => handleAlarm(value.target.value as number)}
                    variant={"standard"}
                >
                    {
                        alarmSeveritiesList.map((type: any) => {
                            return (
                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                            )
                        })
                    }
                </Select>
                {
                    alarmSeverity === -1 && errorMessage
                }
            </Grid>
             <Grid item xs={12} md={6} xl={2}>
                <InputLabel id="class-normalization">Normalização</InputLabel>
                <TextField
                    error={normalizationValue === ""}
                    id="class-normalization"
                    variant="standard"
                    placeholder={"Normalização"}
                    onChange={(value) => handleNormalization(value.target.value)}
                    value={normalizationValue}
                />
                 <IconButton name="delete" aria-label="Delete"
                             onClick={handleDeleteItem}>
                     <Tooltip title="Excluir">
                         <Delete/>
                     </Tooltip>
                 </IconButton>
            </Grid>
        </Grid>
    )
}