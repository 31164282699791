import React, {useEffect, useState} from "react";
import {ACard} from "@atiautomacao/ati-ui-library";
import {Box, Button, Grid, InputLabel, Modal, TextField} from "@mui/material";
import {isValid, startOfDay} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalDateTimeFormatISO} from "../../../../../Utils/DateFormatPatternUtils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {validateNumber} from "../../../../../Utils/RegexUtils";
import {CustomTextField} from "../../../../../Shared/Components/CustomTextField"
import {NumericFormat} from "react-number-format";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Ajuste a largura para 90%
    maxWidth: '600px', // Defina uma largura máxima para grandes telas
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
};

interface ParamsModalProps {
    open: boolean;
    onCloseModal: any;
    data?: any;
    onSave?:any;
    dataForm?:any;
}
export const ParamsModal = ({open, onCloseModal, data, onSave}: ParamsModalProps) => {
    const [expectedEnergy, setExpectedEnergy] = useState<any>();
    const [expectedIrradiance, setExpectedIrradiance] = useState<any>();
    const [expectedPr, setExpectedPr] = useState<any>();
    const [date, setDate] = useState<Date | null>();

    useEffect(() => {
        if(data == null){
            setDate(null);
            setExpectedPr(null)
            setExpectedEnergy(null)
            setExpectedIrradiance(null)
        }else{
            setDate(data.dateTime);
            setExpectedPr(data.prExpected)
            setExpectedEnergy(data.energy)
            setExpectedIrradiance(data.irradiance)
        }
    }, [open]);

    const onSubmit = () => {
        onSave({
            id: data != null ? data.id : null,
            date: date,
            expectedEnergy:expectedEnergy,
            expectedIrradiance:expectedIrradiance,
            expectedPr:expectedPr,
            index: data != null ? data.index : null
        })
    }



    return(
        <Modal open={open} onClose={onCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Grid sx={style}>
                <ACard
                    title="Dados esperados"
                    footerActions={
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="contained" color="primary" onClick={onCloseModal}>
                                Cancelar
                            </Button>
                            <Button variant="contained" color="primary" onClick={onSubmit}>
                                Gravar
                            </Button>
                        </Box>
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel id="expectedEnergy">Energia esperada</InputLabel>
                            <NumericFormat
                                value={expectedEnergy}
                                customInput={CustomTextField}
                                onChange={(e) => validateNumber(e.target.value) ? setExpectedEnergy(e.target.value) : console.log("Digite um número")}
                                placeholder="Digite a energia"
                                onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                                allowNegative={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="expectedIrradiance" sx={{ paddingTop: 1 }}>Irradiação</InputLabel>
                            <NumericFormat
                                value={expectedIrradiance}
                                customInput={CustomTextField}
                                onChange={(e) => validateNumber(e.target.value) ? setExpectedIrradiance(e.target.value) : console.log("Digite um número")}
                                placeholder="Digite a irradiação"
                                onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                                allowNegative={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="expectedPr" sx={{ paddingTop: 1 }}>Performance ratio</InputLabel>
                            <NumericFormat
                                value={expectedPr}
                                customInput={CustomTextField}
                                onChange={(e) => validateNumber(e.target.value) ? setExpectedPr(e.target.value) : console.log("Digite um número")}
                                placeholder="Digite a performance ratio"
                                onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                                allowNegative={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="date" sx={{ paddingTop: 1 }}>Data</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
                                    value={date}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        if (newValue && isValid(new Date(newValue))) {
                                            setDate(startOfDay(new Date(LocalDateTimeFormatISO(newValue))));
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </ACard>
            </Grid>
        </Modal>
    )
}