import {useEffect, useState} from "react";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";

import {ACard} from "@atiautomacao/ati-ui-library";
import {
    Select,
    TextField,
    MenuItem,
    InputLabel,
    InputAdornment, Grid
} from "@mui/material";
import Box from "@mui/material/Box";

import {
    FindEquipmentClassByIdReactQuery,
    PrepareNewEquipmentClassFormReactQuery,
    SaveEquipmentClassReactQuery
} from "./EquipmentClassReactQuery";
import {EquipmentClassType} from "../../../Shared/Types/EquipmentClass";
import {SystemRoutes} from "../../../Utils/RouteUtils";
import PointClassDataGridHome from "./components/PointClassDataGrid";
import {TeleObjectType} from "../../../Shared/Types/TeleObjectType";
import {ModelDataGrid} from "./components/ModelDataGrid";
import {useSnackbar} from "notistack";
import {HeaderMenu} from "../HeaderMenu";
import {MenuProps} from "../../../Utils/PropsStyles";
import {TextRequired} from "../../../Utils/ElementsHtmlUtil";

export interface EquipmentType {
    id: number;
    name: string;
}



export const EquipmentClassFormPage = () => {
    const location = useLocation();
    const id = location.state?.id as string;

    const [equipmentClassName, setEquipmentClassName] = useState<string>("");
    const [equipmentClassDescription, setEquipmentClassDescription] = useState<string>("");
    const [equipmentTypes, setEquipmentTypes] = useState<Array<EquipmentType>>([]);
    const [equipmentType, setEquipmentType] = useState<number>(-1);
    const [potency, setPotency] = useState<number>(0);
    const [equipmentConfig, setEquipmentConfig] = useState<any>(null);
    const [teleObjects, setTeleObjects] = useState<Array<TeleObjectType>>([]);
    const [isPointMap, setIsPointMap] = useState(true);
    const [equipmentClass, setEquipmentClass] = useState<EquipmentClassType | undefined>(undefined);
    const [newRowsToSave, setNewRowsToSave] = useState<Array<TeleObjectType>>([]);

    const {data, isSuccess} = PrepareNewEquipmentClassFormReactQuery();
    const mutationSave = SaveEquipmentClassReactQuery();
    const mutationFindById = FindEquipmentClassByIdReactQuery();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(id){
            mutationFindById.mutate(Number(id), {
                onSuccess: (data) => {
                    let equipmentClass = data?.data as EquipmentClassType;
                    setEquipmentClass(equipmentClass);
                    setEquipmentClassName(equipmentClass.name);
                    setEquipmentClassDescription(equipmentClass.description);
                    if(equipmentClass.equipmentType && equipmentClass.equipmentType.id){
                        setEquipmentType(equipmentClass.equipmentType.id);
                        if(equipmentClass.equipmentType.id === 2){
                            setPotency(equipmentClass.equipmentClassConfig?.nominalPowerValue as number);
                            setEquipmentConfig(equipmentClass.equipmentClassConfig);
                        }
                    }
                    setTeleObjects(equipmentClass.teleObjectConfigEntityList);
                }
            });
        }
    }, []);

    useEffect(() => {
        if(isSuccess){
            let types = data?.data.equipmentTypes as Array<EquipmentType>;
            types.push({id: -1, name: "Selecione o Tipo"});
            setEquipmentTypes(types.sort((a, b) => a.id - b.id));
        }
    }, [data]);

    function handleSave() {
        let equipmentTypeSelected = equipmentTypes.find((type) => type.id === equipmentType);
        let newEquipmentClass: EquipmentClassType = {
            id: id ? Number(id) : null,
            name: equipmentClassName,
            description: equipmentClassDescription,
            equipmentType: equipmentTypeSelected,
            equipmentClassConfig: undefined,
            teleObjectConfigEntityList: !id ? newRowsToSave : []
        }

        if(equipmentType === 2){
            newEquipmentClass.equipmentClassConfig = {
                id: equipmentConfig ? equipmentConfig.id : null,
                description: "Potência Nominal",
                nominalPowerValue: potency
            }
        }

        if(checkNullToSave(newEquipmentClass)){
            mutationSave.mutate(newEquipmentClass, {
                onSuccess: () => {
                    navigate(SystemRoutes.SETTINGS_EQUIPMENT_CLASS);
                }
            });
        } else {
            enqueueSnackbar("Verifique os campos obrigatórios dos pontos de classe.", {variant: 'error'});
        }

    }

    function checkNullToSave(data: any){
        let canSave = true;

        let requiredKeysLogic = ["inputType", "name", "teleObjectConvertExpression", "teleObjectType", "modBusDataType"];
        let requiredKeysFisico = ["inputType", "name", "point", "teleObjectSpecificationType", "teleObjectType", "modBusDataType"];

        data.teleObjectConfigEntityList.forEach((toc: any) => {
            if(toc.inputType === "FISICO"){
                requiredKeysFisico.forEach(key => {
                    if(toc[key] === null || toc[key] === undefined || toc[key] === ""){
                        canSave = false;
                    }
                })
            } else if(toc.inputType === "LOGICO") {
                requiredKeysLogic.forEach(key => {
                    if(toc[key] === null || toc[key] === undefined || toc[key] === ""){
                        canSave = false;
                    }
                })
            } else if(toc.inputType === "") {
                canSave = false;
            }
        })

        return canSave;
    }

    function handlePointsToSave(dataToSave: any) {
        setNewRowsToSave([...newRowsToSave, dataToSave]);
    }

    function handleNewRowsToSave(newDataToSave: any[]){
        setNewRowsToSave(newDataToSave);
    }

    return (
        <>
            <Box style={{paddingBottom: 64}}>
                <HeaderMenu isOnSave={true} handleSave={handleSave} systemRoutes={SystemRoutes.SETTINGS_EQUIPMENT_CLASS}/>
            </Box>
            <ACard>
                <Grid container >
                    <Grid item xs={12} md={6} xl={4}>
                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="class-name">Nome {TextRequired()}</InputLabel>
                            <TextField
                                error={equipmentClassName === ""}
                                fullWidth
                                id="class-name"
                                variant="standard"
                                placeholder={"Nome da classe"}
                                onChange={(value) => setEquipmentClassName(value.target.value)}
                                value={equipmentClassName}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Box style={{marginBottom: 20}}>
                            <InputLabel id="selectTipo">Tipo</InputLabel>
                            <Select
                                fullWidth
                                labelId="selectTipo"
                                id="selectEquipmentType"
                                placeholder={"Selecione o tipo"}
                                value={equipmentType}
                                label="Tipo"
                                onChange={(value) => setEquipmentType(value.target.value as number)}
                                variant={"standard"}
                                MenuProps={MenuProps}
                            >
                                {
                                    equipmentTypes.map((type) => {
                                        return (
                                            <MenuItem value={type.id}>{type.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Box>
                            <InputLabel id="potency">Potência Nominal[kW] {TextRequired()}</InputLabel>
                            <TextField
                                disabled={equipmentType != 2}
                                fullWidth
                                id="potency"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                                    inputMode: 'numeric',
                                }}
                                variant="standard"
                                value={potency}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    const regex = /^[0-9]*$/;
                                    if (regex.test(value)) {
                                        setPotency(Number(value));
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <InputLabel id="class-description">Descrição</InputLabel>
                            <TextField
                                fullWidth
                                id="class-description"
                                variant="filled"
                                multiline
                                rows={3}
                                placeholder={"Descrição da classe"}
                                onChange={(value) => setEquipmentClassDescription(value.target.value)}
                                value={equipmentClassDescription}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} marginLeft={-2}>
                        {
                            isPointMap ?
                                <PointClassDataGridHome teleObjectList={teleObjects} dataForm={data?.data} equipmentClass={equipmentClass} toSaveObjects={handlePointsToSave} idEquipmentClass={id ? Number(id) : null} refreshNewRowsToSave={handleNewRowsToSave}/>
                                :
                                <ModelDataGrid/>
                        }
                    </Grid>
                </Grid>

            </ACard>
        </>
    )
}

export default function EquipmentClassHomePageForm() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <EquipmentClassFormPage />
        </QueryClientProvider>
    )
}