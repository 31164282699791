import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import {EquipmentTypeProps} from "../../../Shared/Types/EquipmentType";

const apiUrl = "api/equipments";

const saveEquipmentType = async (data: EquipmentTypeProps) => {
    try {
        return await axios.post(apiUrl, data).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const SaveEquipmentTypeReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveEquipmentType(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Tipo de equipamento salvo com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao salvar tipo de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchEquipmentType = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const EquipmentTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchEquipmentType(pagedSearchParams);
        }
    });
};

const fetchEquipmentTypeById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindEquipmentTypeByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchEquipmentTypeById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar um tipo de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};

const deleteEquipmentType = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteEquipmentTypeReactQuery = (pagedSearchParams: PagedSearchParams) => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteEquipmentType(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Tipo de equipamento excluído com sucesso!", { variant: "success" });
            if (pagedSearchParams) {
                queryClient.invalidateQueries(["data", pagedSearchParams]);
            }
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir tipo de equipamento: ${error.message}`, { variant: "error" });
        }
    })
};
