import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';
import {RootState} from '../../../../Config/Store';
import axios from 'axios';
import {serializeAxiosError} from "../../../../Config/Reducers/ReducerUtils";
import {ChartState} from "../../../../Config/Types";
import {LocalDateTimeFormatISO} from "../../../../Utils/DateFormatPatternUtils";

export class ComparisonSkid {
    skidName: string;
    value: number;
    valueExpectedEnergy: number;
    constructor(skidName: string,  value: number, valueExpectedEnergy: number) {
        this.skidName = skidName
        this.value = value;
        this.valueExpectedEnergy = valueExpectedEnergy;
    }
}

const initialState: ChartState = {
    loading: false,
    errorMessage: null,
    entities: new Array<ComparisonSkid>(),
    entity: undefined,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/power-station/';
export const getEntities = createAsyncThunk<any, any>(
    'generation-skid/fetch_entity_list',
    async ({period, idPowerPlant}) => {
        return axios.get<ComparisonSkid>(`${apiUrl}/Comparison-of-skids/${idPowerPlant}?startDateTime=${LocalDateTimeFormatISO(period.fromDateTime ? period.fromDateTime : new Date())}&endDateTime=${LocalDateTimeFormatISO(period.toDateTime ? period.toDateTime : new Date())}`);
    },
    { serializeError: serializeAxiosError }
);
export const getEntity = createAsyncThunk<any, any, {rejectValue: any }>(
    'generation-skid/fetch_entity',
    async () => {
        return axios.get<ComparisonSkid>(`${apiUrl}`);
    },
    { serializeError: serializeAxiosError }
);

// Slices
const skidComparisonChartReducer = createSlice({
    name: 'skidComparisonChartReducer',
    initialState,
    reducers: {
        /**
         * Reset the entity state to initial state
         */
        reset() {
            return initialState;
        },
        clear: (state) => {
            state.entity = {
                skidName: "",
                value: 0};
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data.data
            })
            .addMatcher(isFulfilled(getEntities, getEntity), (state, action) => {
                return {
                    ...state,
                    loading: false,
                    entities: action.payload.data.data
                }
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(getEntities, getEntity), (state: ChartState, action) => {
                state.loading = false;
                state.errorMessage = "Erro ao carregar dados de Comparativo de Skids";
            })
    },
})

export const { reset } = skidComparisonChartReducer.actions;
export const selectSkidComparisonChart = (state: RootState) => state.skidComparisonChart;

// Reducer
export default skidComparisonChartReducer.reducer;