import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {PagedSearchParams} from "@atiautomacao/ati-ui-library";
import {useSnackbar} from "notistack";
import {RegionProps} from "../../../Shared/Types/Region";

const apiUrl = "api/regions";

const saveRegion = async (data: RegionProps) => {
    try {
        if(data.id != null) {
            return await axios.put(apiUrl, data,{timeout:30000}).then((res) => res.data);
        }
        return await axios.post(apiUrl, data,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const SaveRegionReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (data: any) => {
            return saveRegion(data);
        },
        onSuccess: () => {
            enqueueSnackbar("Região salva com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            if (error.response.data.title === "Already exists") {
                enqueueSnackbar(`Essa região já existe: ${error.message}`, { variant: "error" });
            } else {
                enqueueSnackbar(`Erro ao salvar região: ${error.message}`, { variant: "error" });
            }
        }
    })
};

const fetchRegion = async (pagedSearchParams: PagedSearchParams) => {
    try {
        return await axios
            .get(`${apiUrl}/search`, {
                params: pagedSearchParams ? pagedSearchParams.toURLSearchParams() : null,
                timeout: 30000
            })
            .then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const RegionReactQuery = (pagedSearchParams: PagedSearchParams) => {
    return useQuery({
        queryKey: ["data", pagedSearchParams],
        queryFn: () => {
            return fetchRegion(pagedSearchParams);
        }
    });
};

const fetchRegionById = async (id: number) => {
    try {
        return await axios.get(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindRegionByIdReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: (id: number) => {
            return fetchRegionById(id);
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao carregar a região: ${error.message}`, { variant: "error" });
        }
    })
};

const fetchMacroRegion = async () => {
    try {
        return await axios.get('api/macroRegions/find-all',{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const FindAllMacroRegionReactQuery = () => {
    return useQuery({
        queryKey: ["find-all-macro"],
        queryFn: () => {
            return fetchMacroRegion();
        }
    });
};

const deleteRegion = async (id: number) => {
    try {
        return await axios.delete(`${apiUrl}/${id}`,{timeout:30000}).then((res) => res.data);
    } catch (error) {
        console.error("Error fetching data:", error);
        return Promise.reject(error);
    }
};

export const DeleteRegionReactQuery = () => {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: any) => {
            return deleteRegion(data.id);
        },
        onSuccess: () => {
            enqueueSnackbar("Região excluída com sucesso!", { variant: "success" });
        },
        onError: (error: any) => {
            enqueueSnackbar(`Erro ao excluir região: ${error.message}`, { variant: "error" });
        }
    })
};
